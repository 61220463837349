var CKQRGen = (function () {

    /**
     * Bank transfer QR template
     */
    const BANK_TRANFER_QR = "00020101021238[CKLength]0010A00000072701[BALength][BankCode][AccountNumber]0208QRIBFTTA5303704[Amount][Content]5802VN6304";

    /**
     * CRC16 CCITT calculate (in/out string)
     * @param {*} str 
     * @returns 
     */
    function CRC16_CCITT(str) {

        var data = new TextEncoder().encode(str);

        var crc = 0xFFFF;
        for (let b of data) {            
            crc = ((crc  >>> 8) | (crc  << 8) )& 0xffff;
	        crc ^= (b & 0xff);//byte to int, trunc sign
	        crc ^= ((crc & 0xff) >> 4);
	        crc ^= (crc << 12) & 0xffff;
	        crc ^= ((crc & 0xFF) << 5) & 0xffff;
        }

        crc &= 0xffff;

        return crc.toString(16).toUpperCase();
    }


    /**
     * Generate CKQR in js
     * @param {*} bankCode 
     * @param {*} accountNumber 
     * @param {*} amount 
     * @param {*} content 
     * @returns 
     */
    const generateCKQR = function (bankCode, accountNumber, amount, message) {

        var transferQR = BANK_TRANFER_QR;

        // add bank code
        var bankCodeBuf = "00";
        var len = bankCode.length;
        if (len < 10) {
            bankCodeBuf = bankCodeBuf + '0';
        }
        bankCodeBuf = bankCodeBuf + len + bankCode;

        transferQR = transferQR.replace('[BankCode]', bankCodeBuf);

        // add account number
        var accountBuf = "01";
        len = accountNumber.length;
        if (len < 10) {
            accountBuf = accountBuf + '0';
        }
        accountBuf = accountBuf + len + accountNumber;

        transferQR = transferQR.replace('[AccountNumber]', accountBuf);

        // add amount
        var amountBuf = "";
        if (amount > 0) {
            amountBuf = "54";
            len = amount.toString().length;
            if (len < 10) {
                amountBuf = amountBuf + '0';
            }
            amountBuf = amountBuf + len + amount.toString();
        }
        transferQR = transferQR.replace('[Amount]', amountBuf);

        // add transfer content
        var contentBuf = "";
        if (message.length > 0) {
            contentBuf = "62";
            len = message.length + 4;
            if (len < 10) {
                contentBuf = contentBuf + '0';
            }

            contentBuf = contentBuf + len + "08";
            len = message.length;
            if (len < 10) {
                contentBuf = contentBuf + '0';
            }
            contentBuf = contentBuf + len + message;
        }
        transferQR = transferQR.replace('[Content]', contentBuf);

        const bankCodeAndAccountLength = bankCodeBuf.length + accountBuf.length;
        transferQR = transferQR.replace('[BALength]', bankCodeAndAccountLength.toString());

        const ckLength = "0010A00000072701".length + 2 + bankCodeAndAccountLength + "0208QRIBFTTA".length;
        transferQR = transferQR.replace("[CKLength]", ckLength.toString());

        // calculate CRC16
        var crc16 = CRC16_CCITT(transferQR);
        if (crc16.length < 4) {
            crc16 = "0" + crc16;
        }

        return transferQR + crc16;

    }


    // Return declare functions
    return {
        generateCKQR: generateCKQR
    };
})();

export default CKQRGen;
