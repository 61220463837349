var OrderData = (function () {
    
    const supportBanks = [
    {
        "website": "https://www.vietcombank.com.vn/IBanking2015",
        "code": 436,
        "name": "Vietcombank"
    },
    {
        "website": "https://www.vietinbank.vn/web/home/vn/product/ebank",
        "code": 415,
        "name": "Vietinbank"
    },
    {
        "website": "http://bidv.com.vn",
        "code": 418,
        "name": "BIDV"
    },
    {
        "website": "https://ibank.agribank.com.vn/ibank",
        "code": 405,
        "name": "Agribank"
    },
    {
        "website": "https://www.techcombank.com.vn/khach-hang-ca-nhan/ngan-hang-dien-tu",
        "code": 407,
        "name": "Techcombank"
    },
    {
        "website": "https://online.vpbank.com.vn",
        "code": 432,
        "name": "VPBank"
    },
    {
        "website": "https://online.mbbank.com.vn",
        "code": 422,
        "name": "MBBank"
    },
    {
        "website": "https://online.acb.com.vn",
        "code": 416,
        "name": "ACB"
    },
    {
        "website": "https://www.isacombank.com.vn",
        "code": 433,
        "name": "Sacombank"
    },
    {
        "website": "https://ebank.msb.com.vn/IBSRetail",
        "code": 426,
        "name": "MSB"
    },
    {
        "website": "https://ebank.tpb.vn/retail",
        "code": 423,
        "name": "TPBank"
    },
    {
        "website": "https://ibanking.shb.com.vn",
        "code": 443,
        "name": "SHB"
    },
    {
        "website": "https://ebanking.eximbank.com.vn/ibcn/faces/login.jspx",
        "code": 431,
        "name": "Eximbank"
    }
];
const getSupportBanks = function () {
    return supportBanks;
}

const bankAccounts = [
    {
        "date": 0,
        "allowUpdateValue": true,
        "upToDate": true,
        "accountName": "CTY CP GIAI PHAP THANH TOAN CHUYEN KHOAN",
        "transparency": false,
        "allowUpdateBalance": true,
        "verified": true,
        "notifier": "0946861101",
        "bankName": "Vietcombank",
        "accountNumber": "0451000285534",
        "accountBalance": 855449,
        "accountBranch": ""
    },
    {
        "date": 0,
        "allowUpdateValue": true,
        "upToDate": true,
        "accountName": "HOANG TRONG HUY",
        "transparency": false,
        "allowUpdateBalance": true,
        "verified": true,
        "notifier": "0946861101",
        "bankName": "VPBank",
        "accountNumber": "5511011983",
        "accountBalance": 193448,
        "accountBranch": ""
    },
    {
        "date": 0,
        "allowUpdateValue": true,
        "accountName": "NGUYEN PHUONG CHAM",
        "transparency": false,
        "verified": true,
        "allowUpdateBalance": true,
        "bankName": "TPBank",
        "accountNumber": "00619239001",
        "accountBalance": 0,
        "accountBranch": ""
    },
    {
        "date": 0,
        "allowUpdateValue": true,
        "upToDate": true,
        "accountName": "HOANG TRONG HUY",
        "transparency": false,
        "allowUpdateBalance": true,
        "verified": true,
        "notifier": "0946861101",
        "bankName": "MBBank",
        "accountNumber": "1101198388888",
        "accountBalance": 328017,
        "accountBranch": ""
    },
    {
        "date": 0,
        "allowUpdateValue": true,
        "accountName": "HOANG TRONG HUY",
        "transparency": false,
        "verified": true,
        "allowUpdateBalance": true,
        "bankName": "BIDV",
        "accountNumber": "21510003828738",
        "accountBalance": 305408,
        "accountBranch": ""
    },
    {
        "date": 0,
        "allowUpdateValue": true,
        "upToDate": true,
        "accountName": "HOANG TRONG HUY",
        "transparency": false,
        "allowUpdateBalance": true,
        "verified": true,
        "notifier": "0946861101",
        "bankName": "Techcombank",
        "accountNumber": "14024841470019",
        "accountBalance": 0,
        "accountBranch": ""
    },
    {
        "date": 0,
        "allowUpdateValue": true,
        "accountName": "HOANG TRONG HUY",
        "transparency": false,
        "verified": true,
        "allowUpdateBalance": true,
        "bankName": "MSB",
        "accountNumber": "03886014110183",
        "accountBalance": 0,
        "accountBranch": ""
    },
    {
        "date": 0,
        "allowUpdateValue": true,
        "accountName": "HOANG TRONG HUY",
        "transparency": false,
        "verified": true,
        "allowUpdateBalance": true,
        "bankName": "Vietinbank",
        "accountNumber": "107867199455",
        "accountBalance": 0,
        "accountBranch": ""
    },
    {
        "date": 0,
        "allowUpdateValue": true,
        "accountName": "HOANG TRONG HUY",
        "transparency": false,
        "verified": true,
        "allowUpdateBalance": true,
        "bankName": "Agribank",
        "accountNumber": "1300206455296",
        "accountBalance": 56000,
        "accountBranch": ""
    },
    {
        "date": 0,
        "allowUpdateValue": true,
        "accountName": "HOANG TRONG HUY",
        "transparency": false,
        "verified": true,
        "allowUpdateBalance": true,
        "bankName": "ACB",
        "accountNumber": "20767387",
        "accountBalance": 0,
        "accountBranch": ""
    }
];

const getBankAccounts = function () {
    return bankAccounts;
}

const getPartnerObj = function () {
    return  {
    "website": "https://chuyenkhoan.com",
    "created": 0,
    "groupId": 0,
    "verified": false,
    "phone": "84906246196",
    "name": "CKPay",
    "tin": "0104103433",
    "logo": "https://dashboard.chuyenkhoan.com/favicon.png",
    "tel": "0961201613",
    "partnerId": 10001,
    "updated": 1627532108447,
    "email": "cskh@chuyenkhoan.com",
    "username": ""
}
}

const getOrderObj = function () {
    return {
        "account_number": "0451000285534",
        "partner_logo": "https://dashboard.chuyenkhoan.com/favicon.png",
        "product": {
            "image": "https://chuyenkhoan.com/images/ck/ck_usb.jpg",
            "code": "ck_bank_verified",
            "quantity": 1,
            "price": 11000,
            "name": "Xác thực tài khoản Vietcombank 0021001131105",
            "available": 11111,
            "discount": 0,
            "description": "Phí xác thực tài khoản HOANG TRONG HUY và đặt hàng CK USB 4G"
        },
        "key_type": "paymentKey",
        "partner_name": "CKPay",
        "customer_phone": "0906246196",
        "order_info": "",
        "partner_id": 10001,
        "account_name": "CTY CP GIAI PHAP THANH TOAN CHUYEN KHOAN",
        "ckqr_content": "CK 195270AB",
        "checksum": "D3B367BE0A7B098E743D9148A9D7B05BCE60D9E8A0DB5CAB6B591BC8687F14D7",
        "bank_name": "Vietcombank",
        "return_url": "https://dashboard.chuyenkhoan.com",
        "order_total": 11000,
        "customer_id": "0021001131105",
        "order_id": "1001952",
        "timestamp": 1627959652104
    }
}

const getBankInfo = function () {
    return {
        bankAccounts: bankAccounts,
        ckQR: "00020101021238570010A00000072701270006970436011304510002855340208QRIBFTTA530370454051100062150811CK 195270AB5802VN63043F9F",
        ckQRContent: "CK 195270AB",
        verified: true,
        upToDate: true,
    }
}


// Return declare functions
return {
    getSupportBanks: getSupportBanks,
    getBankAccounts: getBankAccounts,
    getPartnerObj: getPartnerObj,
    getOrderObj: getOrderObj,
    getBankInfo: getBankInfo,
    
  };
})();

export default OrderData;
