import Colors from "./Colors";
import TxStatus from "./TxStatuses";

const Roles = {
  
  ADMIN: "admin",
  MANAGER: "manager",
  STAFF: "staff",

  PARTNER: "partner",
  VENDOR: "vendor",
  AGENT: "agent",
  NONE: "none",
};

export default Roles;

/**
 * Get role name
 */
function getRoleName(role) {

  switch(role) {
    case Roles.ADMIN:
      return "Admin";
    case Roles.MANAGER:
      return "Quản lý";
    case Roles.STAFF:
      return "Nhân viên";
    default:
      return "";
  }
}

/**
 * Get role name
 */
 function getRoleStatus(role) {

  switch(role) {
    case TxStatus.WAITING:
      return "Chờ xác nhận";
    case TxStatus.SUCCESS:
      return "Đang hoạt động";
    case TxStatus.CANCEL:
      return "Tạm khoá";
    default:
      return "";
  }
}

/**
 * Role color
 */
 function getRoleColor(role) {
  if (role === Roles.AGENT) {
    return Colors.DARK_GREEN;
  } else if (role === Roles.VENDOR) {
    return Colors.DARK_GREEN;
  } else if (role === Roles.PARTNER) {
    return Colors.DARK_BLUE;
  } else {
    return Colors.DARK_GREEN;
  }
}

export { Roles, getRoleName, getRoleStatus };
