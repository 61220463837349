import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';


import Tooltip from '@mui/material/Tooltip';
import { QRCode } from 'react-qrcode-logo';
import Colors from '../../ckcore/core/Colors';
import PaymentStatus from './PaymentStatus';
import { copyTextToClipboard } from '../../ckcore/core/CkUtils';

import ServiceTypes from '../../ckcore/core/ServiceTypes';
import { getCKBankLogo, getBankSvgIcon } from '../../ckcore/core/CkBank'
import napas247 from '../images/napas247.png';
import { Hidden, Typography } from '@mui/material';
import ValUtils from '../../ckcore/core/ValUtils';

import './ckpayment.css';

export default function IntroPayment(props) {

  const classes = useStyles();

  const [supportBankStartIndex, setSupportBankStartIndex] = useState(0);

  const order = props.order;
  const bankInfo = props.bankInfo;
  const paymentStatus = props.paymentStatus;
  const countDown = props.countDown;
  const bankName = order.bank_name;

  /**
   * Get ben bank icon and button
   */
  const getBenBankButton = (bankAccount, index) => {

    // Support only 5 ben banks
    if (index >= 5) {
      return <div key={index}></div>
    }

    return (
      <div key={index} style={{ marginTop: 3, fontSize: 12 }}>
        <Tooltip title={"Chuyển khoản tới " + bankAccount.bankName + ": " + bankAccount.accountNumber} aria-label="Bank"><span>
          <IconButton size="small" className={bankAccount.accountNumber === order.account_number ? classes.bankIconSelected : classes.bankIconButton}
            onClick={() => props.changeBenBankAccount(bankAccount.accountNumber)}>
            <SvgIcon component={getBankSvgIcon(bankAccount.bankName)} className={classes.svgIcon} viewBox="0 0 48 48" />
          </IconButton></span>
        </Tooltip>
      </div>
    );
  }

  const showMoreBanks = () => {
    const totalBank = props.supportBanks.length;
    if (supportBankStartIndex + 10 < totalBank) {
      setSupportBankStartIndex(supportBankStartIndex + 10);
    } else {
      setSupportBankStartIndex(0);
    }
  }

  const getSupportBankButton = (bank, index) => {

    if (index < supportBankStartIndex) {
      return <div key={index}></div>
    }

    return (
      <span key={index} style={{ fontSize: 16 }}>
        <Tooltip title={"Hướng dẫn chuyển khoản từ " + bank.name} aria-label="Bank"><span>
          <IconButton size="small" className={classes.bankIconButton} onClick={() => props.bankTransferGuide(bank)}>
            <SvgIcon component={getBankSvgIcon(bank.name)} className={classes.svgIcon} viewBox="0 0 48 48" />
          </IconButton></span>
        </Tooltip>
      </span>
    );
  }

  return (
    <div className="CheckoutPaymentForm">

      <Hidden smUp>
        <div className="PaymentRequestOrHeader" style={{ height: '48px' }}>
          <div className="ButtonAndDividerContainer" style={{ opacity: 1, display: 'block' }}>
            <div className="PaymentRequestButtonContainer">
              <div style={{
                margin: '0px !important', padding: '0px !important', border: 'none !important', display: 'block !important',
                background: 'transparent !important', position: 'relative !important', opacity: '1 !important', paddingTop: 8
              }}>
                <h1 style={{ textAlign: "center", color: Colors.DARK_BLUE, marginTop: 0, marginBottom: 8, fontSize: 17 }}>Chuyển Khoản <strong style={{ color: Colors.RED }}>VIET</strong>QR</h1>
              </div>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smDown>
        <div className="PaymentRequestOrHeader" style={{ height: '64px', minHeight: '44px' }}>
          <div className="ButtonAndDividerContainer" style={{ opacity: 1, display: 'block' }}>
            <div className="PaymentRequestButtonContainer">
              <div style={{
                margin: '0px !important', padding: '0px !important', border: 'none !important', display: 'block !important',
                background: 'transparent !important', position: 'relative !important', opacity: '1 !important', paddingTop: 16
              }}>
                <h1 style={{ textAlign: "center", color: Colors.DARK_BLUE, marginTop: 0, marginBottom: 8 }}>Chuyển Khoản <strong style={{ color: Colors.RED }}>VIET</strong>QR</h1>
              </div>
            </div>
          </div>
        </div>
      </Hidden>


      <div className="App-Global-Fields flex-container spacing-12 direction-row wrap-wrap">
        <div className="flex-item width-12" style={{ margin: 'auto', textAlign: 'center', fontSize: 12 }}>
          <Grid container>
            <Grid item xs={2}>
              <div className={classes.benSelectText}  style={{ marginLeft: 4 }}>TK NHẬN</div>
              <div key="ben-bank" style={{ maxHeight: 150, height: 150, overflow: 'hidden'}}>
                {
                  bankInfo.bankAccounts.map((bankAccount, index) => (
                    getBenBankButton(bankAccount, index)
                  ))
                }
              </div>
            </Grid>
            <Grid item xs={8}>
              <Hidden smUp>
                <QRCode size={150} eyeRadius={3} value={bankInfo.ckQR} />
              </Hidden>
              <Hidden smDown>
                <QRCode size={180} eyeRadius={3} value={bankInfo.ckQR} />
              </Hidden>
            </Grid>
            <Grid item xs={2}>
              <div key="guide" className={classes.benSelectText}>
                <Hidden smUp>
                  <span>HD CK</span>
                </Hidden>
                <Hidden smDown>
                  <span>HƯỚNG DẪN</span>
                </Hidden>

              </div>
              <div key="support-bank" style={{ maxHeight: 150, height: 150, overflow: 'hidden' }}>
                {
                  props.supportBanks.map((bank, index) => (
                    getSupportBankButton(bank, index)
                  ))
                }
              </div>
              <div key="show-more" style={{ marginTop: -8 }}>
                <Tooltip title={"Xem thêm"} aria-label="Bank"><span>
                  <IconButton size="small" className={classes.showmoreButton} onClick={() => showMoreBanks()}>
                    <ExpandMoreOutlinedIcon />
                  </IconButton></span>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="PaymentForm-paymentMethodFormContainer flex-container spacing-12 direction-row wrap-wrap">

        <Hidden smUp>
          <div className="flex-item width-12" style={{ padding: 0 }}>

            <div className="FormFieldGroup">

              <div className="FormFieldGroup-container" id="cardNumber-fieldset">

                <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childLeft FormFieldGroup-childBottom">
                  <img style={{ height: 48 }} src={napas247} alt="Napas 247" />
                </div>

                <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childRight FormFieldGroup-childBottom">
                  <img style={{ height: 48 }} src={getCKBankLogo(bankName)} alt={bankName} />
                </div>

              </div>

            </div>

          </div>
        </Hidden>
        <Hidden smDown>
          <div className="flex-item width-12" style={{ padding: 0 }}>

            <div className="FormFieldGroup">

              <div className="FormFieldGroup-container" id="cardNumber-fieldset">

                <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childLeft FormFieldGroup-childBottom">
                  <img style={{ height: 64 }} src={napas247} alt="Napas 247" />
                </div>

                <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childRight FormFieldGroup-childBottom">
                  <img style={{ height: 64 }} src={getCKBankLogo(bankName)} alt={bankName} />
                </div>

              </div>

            </div>

          </div>
        </Hidden>
        <Hidden smUp>
          <div className="flex-item width-12" style={{ color: Colors.DARK_BLUE, margin: 'auto', marginTop: -8, textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            <strong>CTCP GIẢI PHÁP THANH TOÁN</strong><br /><strong>CHUYỂN KHOẢN</strong>
          </div>
        </Hidden>
        <Hidden smDown>
          <div className="flex-item width-12" style={{ color: Colors.DARK_BLUE, margin: 'auto', marginTop: 6, textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            <strong>CTCP GIẢI PHÁP THANH TOÁN</strong><br /><strong>CHUYỂN KHOẢN</strong>
          </div>
        </Hidden>

      </div>

    </div>
  );

}


const useStyles = makeStyles(theme => ({

  textField: {
    marginTop: 8,
    marginLeft: 0,
    marginRight: 0,
    background: 'white',
    color: '#36507D',
    width: '100%',
    padingRight: 3,
    cursor: 'pointer',
  },

  inputAccountNumber: {
    color: Colors.DARK_GREEN,
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: 3,
    cursor: 'pointer'
  },

  inputTxValue: {
    color: Colors.DARK_BLUE,
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    paddingTop: 3,
    fontWeight: 'bold',
    cursor: 'pointer'
  },

  inputTxContent: {
    color: Colors.RED,
    width: '100%',
    fontSize: 16,
    paddingTop: 3,
    fontWeight: 'bold',
    cursor: 'pointer'
  },

  inputNormalContent: {
    color: Colors.DARK_BLUE,
    width: '100%',
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer'
  },

  iconCheck: {
    margin: -10,
    width: 24,
    height: 24,
    padding: 0,
    marginRight: -12,
  },

  benSelectText: {
    margin: 0,
    padding: 0,
    color: Colors.DARK_BLUE,
    whiteSpace: 'nowrap'
  },

  bankIconButton: {
  },

  bankIconSelected: {
    borderColor: Colors.DARK_GREEN,
    borderStyle: 'dashed',
    borderWidth: 1,
  },
  svgIcon: {
    fontSize: 24,
  }

}));
