
import { ckTableStyles } from '../../ckcore/ui/CkStyles';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getProvinces } from '../../ckcore/core/LocationUtils';
import Colors from '../../ckcore/core/Colors';
import ValUtils from '../../ckcore/core/ValUtils';


export default function EasyRegisterTable(props) {

  const [regObj, setRegObj] = useState(props.regObj);

  /**
   * Change fields
   */
  const handleChange = name => event => {
    var val = event.target.value;

    if (name === 'phone') {
      val = ValUtils.getNumberOnly(val);
      val = ValUtils.getDisplayPhone(val);
    } else if (name === 'provinceId') {
      val = parseInt(val);
    }

    setRegObj({ ...regObj, [name]: val });
  };

  useEffect(() => {
    props.updateCallback(regObj);
  }, [regObj]);



  return (
    <Box style={{ margin: "auto", paddingTop: 3, paddingBottom: 3 }}>

      <Grid container spacing={1} maxWidth="md" style={{margin: "auto"}}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField type="text" id="name" variant="outlined" size="small" margin="dense" fullWidth
            label={"Họ và Tên"}
            value={regObj.name}
            onChange={handleChange('name')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField type="text" id="phone" variant="outlined" size="small" margin="dense" fullWidth
            label={"Số điện thoại"}
            value={regObj.phone}
            onChange={handleChange('phone')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField type="text" id="email" variant="outlined" size="small" margin="dense" fullWidth
            label={"Địa chỉ email"}
            value={regObj.email}
            onChange={handleChange('email')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField type="text" id="company" variant="outlined" size="small" margin="dense" fullWidth
            label={"Tên công ty / cửa hàng"}
            value={regObj.partner}
            onChange={handleChange('partner')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="ck-province-list" variant="outlined" size="small" margin="dense" fullWidth
            label={"Tỉnh / thành phố"}
            select
            value={(regObj.provinceId) ? regObj.provinceId : 0}
            onChange={handleChange('provinceId')}>
            {getProvinces().map((provinceObj, index) => (
              <MenuItem key={provinceObj.pn} value={provinceObj.pi}>
                <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{provinceObj.pn}</Box>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField type="text" id="website" variant="outlined" size="small" margin="dense" fullWidth
            label={"Website (nếu có)"}
            value={regObj.website}
            onChange={handleChange('website')}
          />
        </Grid>
      </Grid>
    </Box>

  );
}