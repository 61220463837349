const Colors = {
  CK_VIOLET: "#6833FF",
  BG_BLUE: "#354B86",
  BG_WHITE: "#F2F5F8",
  DARK_BLUE: "#2e4369",
  LIGHT_BLUE: "#20C94C",
  LIGHT_BLUE_BK: "#00B7FF",
  DARK_GREEN: "#008700",
  GREEN: "#00D967",
  RED: "#D10614",
  YELLOW: "#FFB800",
  ORANGE: "#FFA100",
  GRAY: "#838383",
  DARK: "#212121",
  CK_BG: "#FAFAFA",
  CK_BUTTON: "#1976D2",
  CK_BG_SUCCESS: "#EDF7EE",
  CK_BG_WARNING: "#FFF4E6",
};

export default Colors;
