import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';
import Autocomplete from '@mui/material/Autocomplete';

import Colors from '../../ckcore/core/Colors';
import { Typography } from '@mui/material';
import { getBankSvgIcon, getCKBankGuideImage } from '../../ckcore/core/CkBank'
import ValUtils from '../../ckcore/core/ValUtils';
import './ckpayment.css';


export default function PaymentGuideDialog(props) {

  const classes = useStyles();

  const order = props.order;
  const bankInfo = props.bankInfo;
  const bankObj = props.bankObj;
  const supportBanks = props.supportBanks;

  const [bankName, setBankName] = useState(bankObj.name);
  const handleSelect = (name, value) => {
    setBankName(value);
  };

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Payment transfer bank guide dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Hướng dẫn thanh toán từ {bankName}</DialogTitle>
        <DialogContent dividers>

          <Box style={{marginBottom: 8}}>
          </Box>

          <Grid container spacing={1}>

            <Grid item xs={5}>
              <Autocomplete id="bank-list" disableClearable 
                options={supportBanks}
                getOptionLabel={(bank) => bank.name}
                isOptionEqualToValue={(option, bank) => option.name.toUpperCase() === bankName.toUpperCase()}
                onChange={(event, bank) => handleSelect('bankName', bank.name)}
                value={{name: bankName}}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" margin="dense" fullWidth
                    label="Chuyển khoản từ"                  
                    className={classes.textfieldBankName}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small" >
                            <SvgIcon component={getBankSvgIcon(bankName)} viewBox="0 0 48 48" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}/>
              )}/>
            </Grid>
            <Grid item xs={7}>
            <TextField
                className={classes.textfieldAccNumberNew}
                fullWidth
                margin="dense"
                label={"tới tài khoản " + order.bank_name}
                type="text"
                variant="outlined"
                value={order.account_number}
                inputProps={{
                  maxLength: 15,
                }}
                InputProps={{
                  readOnly: true,
                  className: classes.inputAccountNumber,
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton size="small" >
                        <SvgIcon component={getBankSvgIcon(order.bank_name)} viewBox="0 0 48 48" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                disabled={true}
              />
            </Grid>

            <Grid item xs={5}>
                <TextField id="ck-bank-amount" variant="outlined" fullWidth margin="normal" size="small"
                    label="SỐ TIỀN"
                    defaultValue={ValUtils.formatMoney(order.order_total) + 'đ'}
                    className={classes.textField}
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    InputProps={{ className: classes.inputTxValue }}
                    disabled = {true}
                  />
            </Grid>
            <Grid item xs={7}>
                <TextField id="ck-bank-content" variant="outlined" fullWidth margin="normal" size="small"
                    label="NỘI DUNG CHUYỂN KHOẢN"
                    value={bankInfo.ckQRContent}
                    className={classes.textField}
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    InputProps={{ readOnly: true, className: classes.inputTxContent }}
                  />            
            </Grid>

            <Grid item xs={12}>            
              <img style={{width: '100%'}} src={getCKBankGuideImage(bankName)} alt={"Hướng dẫn chuyển khoản " + bankName}/>
            </Grid>

            <Grid item xs={12}>            
              <Typography variant="subtitle2" style={{color: Colors.RED}}>Bạn cần kiểm tra đúng tên tài khoản nhận là: {order.account_name}, SỐ TIỀN và NỘI DUNG như trên để giao dịch được xác nhận tự động.</Typography>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Trở lại
          </Button>        
         
        </DialogActions>
      </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme({
  palette: {
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
});

const useStyles = makeStyles(theme => ({
  root: {

  },

  textField: {
  },

  inputAccountNumber: {
    color: Colors.DARK_GREEN,
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputTxValue: {
    color: Colors.DARK_BLUE,
    width: '100%',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold',
  },

  inputTxContent: {
    color: Colors.RED,
    width: '100%',
    fontSize: 15,
    fontWeight: 'bold',
  },  
}));
