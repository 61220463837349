import React from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Hidden } from '@mui/material';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import Colors from '../../ckcore/core/Colors';
import { Link } from "react-router-dom";

export default function Navigation(props) {
    const classes = useStyle();

    const showMenu = (props.showMenu) ? props.showMenu : false;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <nav id="menu" className="navbar navbar-default navbar-fixed-top">
                    <div className="container">

                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">

                            <Grid item >
                                <Hidden smDown>
                                    <a className="navbar-brand" href="https://chuyenkhoan.com">
                                        <img className={classes.logo} alt='' src="https://chuyenkhoan.com/web/images/chuyenkhoan_logo_white.png" />
                                    </a>
                                </Hidden>
                                <Hidden smUp>
                                    <a className="navbar-brand" href="https://chuyenkhoan.com">
                                        <img className={classes.logoSmall} alt='' src="https://chuyenkhoan.com/web/images/chuyenkhoan_logo_white.png" />
                                    </a>
                                </Hidden>
                            </Grid>

                            <Grid item>
                                {showMenu &&

                                    <ul className="nav navbar-nav navbar-right" style={{ justifyContent: 'center' }}>
                                        <Hidden lgDown>
                                            <li>
                                                <a href="/#header" className="page-scroll">
                                                    Trang chủ
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#features" className="page-scroll">
                                                    Tính năng
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#fqa" className="page-scroll">
                                                    Hỏi đáp
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#pricing" className="page-scroll">
                                                    Bảng giá
                                                </a>
                                            </li>                                            
                                            <li>
                                                <a href="/#contact" className="page-scroll">
                                                    Liên hệ
                                                </a>
                                            </li>
                                        </Hidden>

                                        <li>
                                            <Box className={classes.dashboard}>
                                                <Button variant="outlined" color="secondary" href="https://dashboard.chuyenkhoan.com" style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                                                    ĐĂNG NHẬP
                                                </Button>
                                            </Box>
                                        </li>
                                    </ul>
                                }
                            </Grid>

                        </Grid>

                    </div>
                </nav>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}


const theme = createTheme({
    palette: {
        primary: {
            main: Colors.BG_BLUE,
        },
        secondary: {
            main: Colors.CK_VIOLET,
            contrastText: "#fff"
        }
    },
});


const useStyle = makeStyles(theme => ({
    logo: {
        height: '56px',
        marginTop: -3
    },
    logoSmall: {
        height: '40px',
        marginTop: 0,
        marginLeft: -16
    },
    dashboard: {
        paddingLeft: 8,
        paddingBottom: 8,
        maxWidth: 138,
        height: 38,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 0,
            marginRight: 8,
        },
    }

}));