// import { makeStyles, createTheme } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import React from 'react';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import Colors from './Colors';
import TxStatus from './TxStatuses';
import TxTypes from './TxTypes';
import PaymentStatus from '../../payment/components/PaymentStatus';
import { Typography } from '@mui/material';


/**
 * Parse transaction tx bean from tx line seperate by tab
 * @param {} txLine 
 * @returns 
 */
function parseTxBean(txLine) {

  let values = txLine.split("\t");

  return {
    txId: Number(values[0]),
    statusId: parseInt(values[1]),
    txTypeId: parseInt(values[2]),
    serviceId: parseInt(values[3]),
    confirmStatus: parseInt(values[4]),
    paymentStatus: parseInt(values[5]),

    bankCode: parseInt(values[6]),
    amount: parseInt(values[7]),
    bonus: parseInt(values[8]),
    discount: parseInt(values[9]),
    fee: parseInt(values[10]),
    tax: parseInt(values[11]),
    balance: Number(values[12]),

    srcId: parseInt(values[13]),
    destId: parseInt(values[14]),
    srcPhone: values[15],
    destPhone: values[16],    
    created: Number(values[17]),
    updated: Number(values[18]),

    txnRef: values[19],
    customerId: values[20],
    src: values[21],
    ben: values[22],
    address: values[23],
    content: values[24]
  };
}

function compareTx( a, b ) {

  if ( a.created < b.created ){
    return -1;
  }

  if ( a.created > b.created ){
    return 1;
  }
  return 0;
}

function compareTxDesc( a, b ) {

  if ( a.created > b.created ){
    return -1;
  }

  if ( a.created < b.created ){
    return 1;
  }
  return 0;
}

function compareUpdatedDesc( a, b ) {

  if ( a.updated > b.updated ){
    return -1;
  }

  if ( a.updated < b.updated ){
    return 1;
  }
  return 0;
}

function comparePartner( partnerA, partnerB) {

  const banksA = (partnerA.bankConfig.banks) ? (partnerA.bankConfig.banks.length > 0 ? 1 : 0) : 0;
  const banksB = (partnerB.bankConfig.banks) ? (partnerB.bankConfig.banks.length > 0 ? 1 : 0) : 0;

  if( banksA > banksB) {
    return -1;
  }

  if ( partnerA.partnerId > partnerB.partnerId ){
    return 1;
  }

  if ( partnerA.partnerId < partnerB.partnerId ){
    return -1;
  }

  return 0;
}

function compareAccount( accountA, accountB) {

  if ( accountA.userId > accountB.userId ){
    return -1;
  }

  if ( accountA.userId < accountB.userId ){
    return 1;
  }

  return 0;
}

function getStatusColor(txStatus) {
  
  if (txStatus === TxStatus.SUCCESS || txStatus === TxStatus.HTTP_SUCCESS) {
    return Colors.DARK_GREEN;
  } else if (txStatus === TxStatus.WAITING) {
    return Colors.YELLOW;
  } else if (txStatus === TxStatus.PROCESSING) {
    return Colors.ORANGE;    
  } else if (txStatus === TxStatus.CANCEL) {
    return Colors.GRAY;
  } else if (txStatus === TxStatus.TIMEOUT) {
    return Colors.GRAY;
  } else if (txStatus === TxStatus.NOT_FOUND) {
    return Colors.GRAY;
  } else if (txStatus === TxStatus.INVALID) {
    return Colors.RED;
  } else if (txStatus === TxStatus.ERROR) {
    return Colors.RED;
  } else {
    return Colors.RED;
  }
}


// Get tx status text
const getTxStatusText = (statusId) => {
  if (statusId === TxStatus.SUCCESS) {
    return "Thành công";
  } else if (statusId === TxStatus.WAITING) {
    return "Đợi xử lý";
  } else if (statusId === TxStatus.PROCESSING) {
    return "Đang xử lý";
  } else if (statusId === TxStatus.CANCEL) {
    return "Khách hàng huỷ";
  } else if (statusId === TxStatus.TIMEOUT) {
    return "Quá thời gian";
  } else if (statusId === TxStatus.NOT_FOUND) {
    return "Không xử lý";
  } else if (statusId === TxStatus.INVALID) {
    return "Không hợp lệ";
  } else if (statusId === TxStatus.ERROR) {
    return "Giao dịch lỗi";
  } else {
    return "GD lỗi (" + statusId + ")";
  }
};

const getConfirmStatusText = (statusId) => {
  if (statusId === TxStatus.SUCCESS) {
    return "Đã xác nhận";
  } else if (statusId === TxStatus.WAITING) {
    return "Chờ xác nhận";
  } else if (statusId === TxStatus.PROCESSING) {
    return "Đang xác nhận";
  } else if (statusId === TxStatus.TIMEOUT) {
    return "Lỗi xác nhận";
  } else if (statusId === TxStatus.INVALID) {
    return "Lỗi xác nhận";
  } else if (statusId === TxStatus.ERROR) {
    return "Lỗi xác nhận";
  } else {
    return "Lỗi xác nhận (" + statusId + ")";
  }
};

const getHttpStatusText = (statusId) => {

  if (statusId === TxStatus.HTTP_SUCCESS) {
    return "ck.http.success";
  } else if (statusId === TxStatus.HTTP_BAD_REQUEST) {
    return "ck.http.bad.request";
  } else if (statusId === TxStatus.HTTP_UNAUTHORIZED) {
    return "ck.http.unauthorized";
  } else if (statusId === TxStatus.HTTP_NOT_FOUND) {
    return "ck.http.not.found";
  } else if (statusId === TxStatus.HTTP_PAYMENT_REQUIRED) {
    return "ck.http.payment.required";
  } else if (statusId === TxStatus.HTTP_REQUEST_TIMEOUT) {
    return "ck.http.request.timeout";
  } else if (statusId === TxStatus.HTTP_SERVICE_UNAVAILABLE) {
    return "ck.http.service.unavailable";
  } else if (statusId === TxStatus.ERROR) {
    return "Giao dịch lỗi";
  } else {
    return "ck.http.error (" + statusId + ")";
  }
};

const getPaymentStatusColor = (paymentStatusId) => {

  if (paymentStatusId === PaymentStatus.PAYMENT_WAITING) {
    return Colors.ORANGE;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_SUCCESS) {
    return Colors.DARK_GREEN;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_DOUBLE_PAY) {
    return Colors.DARK_BLUE;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_OVER_FUND) {
    return Colors.DARK_BLUE;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_MANUAL_CONFIRM) {
    return Colors.DARK_BLUE;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_INSUFFICIENT_FUND) {
    return Colors.ORANGE;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_CANCEL) {
    return Colors.GRAY;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_IGNORE) {
    return Colors.GRAY;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_MANUAL_REVIEW) {
    return Colors.GRAY;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_INTERNAL) {
    return Colors.GRAY;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_TIMEOUT) {
    return Colors.GRAY;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_DEBIT) {
    return Colors.GRAY;
  } else if (paymentStatusId === PaymentStatus.PAYMENT_BANK_ACCOUNT_NOT_FOUND) {
    return Colors.RED;
  } else {
    return Colors.RED;
  }
};

const getPaymentStatusText = (paymentStatusId) => {
  
  if (paymentStatusId === PaymentStatus.PAYMENT_WAITING) {
    return "Chờ thanh toán";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_SUCCESS) {
    return "Đã thanh toán";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_DOUBLE_PAY) {
    return "Thanh toán hai lần";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_OVER_FUND) {
    return "Chuyển thừa tiền";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_INSUFFICIENT_FUND) {
    return "Chuyển thiếu tiền";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_MANUAL_CONFIRM) {
    return "Xác nhận thủ công";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_CANCEL) {
    return "";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_IGNORE) {
    return "Không xử lý";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_MANUAL_REVIEW) {
    return "Đã xử lý";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_INTERNAL) {
    return "CK nội bộ";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_TIMEOUT) {
    return "Quá thời gian";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_DEBIT) {
    return "Giao dịch chi";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_ORDER_NOT_FOUND) {
    return "Không thấy đơn";
  } else if (paymentStatusId === PaymentStatus.PAYMENT_BANK_ACCOUNT_NOT_FOUND) {
    return "Không thấy TKNH";
  } else {
    return "GD lỗi (" + paymentStatusId + ")";
  }
};


function getTxName(txType) {
  switch (txType) {
    case TxTypes.TX_USER_REGISTER:
      return "Đăng ký";
    case TxTypes.TX_USER_LOGIN:
      return "Đăng nhập";
    case TxTypes.TX_USER_LOGOUT:
      return "Đăng xuất";

    case TxTypes.TX_PAYMENT_REQUEST:
      return "Đơn hàng";    

    case TxTypes.TX_SMS_CK_TRANSACTION:
      return "SMS MT";
    case TxTypes.TX_SMS_MO_TRANSACTION:
      return "Nhận SMS";
    case TxTypes.TX_SMS_MT_TRANSACTION:
      return "Gửi SMS";
      
    case TxTypes.TX_MODEM_UPDATE_CONFIRMED_TX:
      return "Xác nhận GD";
        
  
    

    case TxTypes.TX_CARD_TRANSFER_DIRECT:
    case TxTypes.TX_CARD_TRANSFER_WAITING:
    case TxTypes.TX_CARD_TRANSFER_DEPOSIT:
    case TxTypes.TX_CARD_TRANSFER_CASHOUT:
      return "Chuyển tiền";

    case TxTypes.TX_CARD_TRANSFER_GIFTCARD:
      return "";

    case TxTypes.TX_CARD_BUY:
      return "Mua tài khoản";
    case TxTypes.TX_CARD_CHANGE:
      return "Đổi mệnh giá";
    case TxTypes.TX_CARD_DECRYPT:
      return "";
    case TxTypes.TX_CARD_SCAN:
      return "";
    case TxTypes.TX_CARD_RECHARGE:
    case TxTypes.TX_CARD_TOPUP:
      return "Nạp tiền";
    case TxTypes.TX_CARD_TRANSFER_AIRTIME:
      return "Bắn tiền";
    case TxTypes.TX_CARD_TRANSFER_RECHARGE:
      return "Nạp thẻ";
      
    case TxTypes.TX_CARD_TOPUP_SCHEDULE:
    case TxTypes.TX_CARD_NOTICE:
      return "";
    default:
    // do nothing
  }

  return "";
}

/**
 * Format tx timestamp to yyyy-MM-dd HH:mm:ss
 */
const getTxTimeText = (timestamp) => {
  return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
}
const getTxTimeVN = (timestamp) => {
  return moment(timestamp).format('HH:mm:ss DD/MM/YYYY');
}

const getTxTimeShort = (timestamp) => {
  return moment(timestamp).format('YY-MM-DD HH:mm');
}

const getTxMillisText = (timestamp) => {
  return moment(timestamp).format('YYYY-MM-DD HH:mm:ss.SSS');
}

// Get tx status icon by type
function getTxIcon(txType, txStatus, isMyAction) {
  switch (txType) {
    case TxTypes.TX_CARD_TRANSFER_DIRECT:
    case TxTypes.TX_CARD_TRANSFER_WAITING:
    case TxTypes.TX_CARD_TRANSFER_DEPOSIT:
    case TxTypes.TX_CARD_TRANSFER_CASHOUT:
      if (txStatus === TxStatus.SUCCESS) {
        if (isMyAction) {
          return "ic_transfer_card_green_24dp.png";
        } else {
          return "ic_request_card_green_24dp.png";
        }
      } else if (
        txStatus === TxStatus.WAITING ||
        txStatus === TxStatus.PROCESSING
      ) {
        if (isMyAction) {
          return "ic_transfer_card_yellow_24dp.png";
        } else {
          return "ic_request_card_yellow_24dp.png";
        }
      } else {
        if (isMyAction) {
          return "ic_transfer_card_red_24dp.png";
        } else {
          return "ic_request_card_red_24dp.png";
        }
      }

    case TxTypes.TX_CARD_TRANSFER_GIFTCARD:
      if (txStatus === TxStatus.SUCCESS) {
        return "ic_gift_card_green_24dp.png";
      } else if (
        txStatus === TxStatus.WAITING ||
        txStatus === TxStatus.PROCESSING
      ) {
        return "ic_gift_card_yellow_24dp.png";
      } else {
        return "ic_gift_card_red_24dp.png";
      }

    case TxTypes.TX_CARD_BUY:
      if (txStatus === TxStatus.SUCCESS) {
        return "ic_buy_card_green_24dp.png";
      } else if (
        txStatus === TxStatus.WAITING ||
        txStatus === TxStatus.PROCESSING
      ) {
        return "ic_buy_card_yellow_24dp.png";
      } else {
        return "ic_buy_card_red_24dp.png";
      }
    case TxTypes.TX_CARD_CHANGE:
      if (txStatus === TxStatus.SUCCESS) {
        return "ic_change_card_green_24dp.png";
      } else {
        return "ic_change_card_red_24dp.png";
      }
    case TxTypes.TX_CARD_DECRYPT:
      if (txStatus === TxStatus.SUCCESS) {
        return "ic_scratch_card_green_24dp.png";
      } else {
        return "ic_scratch_card_red_24dp.png";
      }

    case TxTypes.TX_CARD_SCAN:
      if (txStatus === TxStatus.SUCCESS) {
        return "ic_scan_card_green_24dp.png";
      } else {
        return "ic_scan_card_red_24dp.png";
      }

    case TxTypes.TX_CARD_RECHARGE:
    case TxTypes.TX_CARD_TOPUP:
      if (txStatus === TxStatus.SUCCESS) {
        return "ic_phone_recharge_green_24dp.png";
      } else {
        return "ic_phone_recharge_red_24dp.png";
      }

    case TxTypes.TX_CARD_TRANSFER_AIRTIME:
    case TxTypes.TX_CARD_TRANSFER_RECHARGE:
    case TxTypes.TX_CARD_TOPUP_SCHEDULE:
      if (txStatus === TxStatus.SUCCESS) {
        return "ic_auto_recharge_green_24dp.png";
      } else if (
        txStatus === TxStatus.WAITING ||
        txStatus === TxStatus.PROCESSING
      ) {
        return "ic_auto_recharge_yellow_24dp.png";
      } else {
        return "ic_auto_recharge_red_24dp.png";
      }
    case TxTypes.TX_CARD_NOTICE:
      if (txStatus === TxStatus.SUCCESS) {
        return "ic_notifications_notice_green_24dp.png";
      } else {
        return "ic_notifications_notice_red_24dp.png";
      }
    default:
    // do nothing
  }

  return "";
}

const getStatusIcon = (txTypeId, txStatusId) => {
  const txColor = getStatusColor(txStatusId);
  if (txTypeId === TxTypes.TX_CARD_BUY) {
    return (
      <div>
        <IconButton size="small" style={{ fontSize: 12, padding: "0px 8px" }}>
          <AddCircleOutlineIcon style={{ color: txColor }} />
        </IconButton>
      </div>
    );
  } else if (txTypeId === TxTypes.TX_CARD_TRANSFER_AIRTIME) {
    return (
      <div>
        <IconButton size="small" style={{ fontSize: 12, padding: "0px 8px" }}>
          <OfflineBoltOutlinedIcon style={{ color: txColor }} />
        </IconButton>
      </div>
    );
  } else if (txTypeId === TxTypes.TX_CARD_TRANSFER_RECHARGE) {
    return (
      <div>
        <IconButton size="small" style={{ fontSize: 12, padding: "0px 8px" }}>
          <PaymentOutlinedIcon style={{ color: txColor }} />
        </IconButton>
      </div>
    );
  } else if (txTypeId === TxTypes.TX_CARD_TRANSFER_CASHOUT) {
    return (
      <div>
        <IconButton size="small" style={{ fontSize: 12, padding: "0px 8px" }}>
          <MonetizationOnOutlinedIcon style={{ color: txColor }} />
        </IconButton>
      </div>
    );
  } else if (txTypeId === TxTypes.TX_CARD_TRANSFER_DIRECT) {
    return (
      <div>
        <IconButton size="small" style={{ fontSize: 12, padding: "0px 8px" }}>
          <LoopOutlinedIcon style={{ color: txColor }} />
        </IconButton>
      </div>
    );
  } else if (txTypeId === TxTypes.TX_SMS_TRANSACTION) {
    return (
      <div>
        <IconButton size="small" style={{ fontSize: 12, padding: "0px 8px" }}>
          <SmsOutlinedIcon style={{ color: txColor }} />
        </IconButton>
      </div>
    );
  } else {
    return (
      <div>
        <IconButton size="small" style={{ fontSize: 12, padding: "0px 8px" }}>
          <HelpOutlineOutlinedIcon style={{ color: txColor }} />
        </IconButton>
      </div>
    );
  }
};

const SmsCkStatus = {
  SMSORDER_RECEIVE_NEW: 0,
  SMSORDER_SYNC_DONE: 1,
  SMSORDER_ERROR_DUPLICATE: 2,
  SMSORDER_ERROR_IGNORE: 3,
  SMSORDER_ERROR_PARSE: 4,
  SMSORDER_ERROR_NOTFOUND: 5,
  SMSORDER_ERROR_NOTENOUGH: 6,
  SMSORDER_ERROR_REJECT: 7,
  SMSORDER_ERROR_UNKNOWN: 8,
  SMSORDER_CONFIRM_SUCCESS: 9,
};

function getSmsOrderStatusColor(orderStatus) {
  if (orderStatus === SmsCkStatus.SMSORDER_CONFIRM_SUCCESS) {
    return Colors.DARK_GREEN;
  } else {
    return Colors.RED;
  }
}

function getSmsOrderStatus(orderStatus) {
  switch (orderStatus) {
    case SmsCkStatus.SMSORDER_RECEIVE_NEW:
      return "Đơn mới";
    case SmsCkStatus.SMSORDER_SYNC_DONE:
      return "Đã đồng bộ";
    case SmsCkStatus.SMSORDER_ERROR_DUPLICATE:
      return "Lỗi trùng lặp";
    case SmsCkStatus.SMSORDER_ERROR_IGNORE:
      return "Bỏ qua";
    case SmsCkStatus.SMSORDER_ERROR_PARSE:
      return "Lỗi bóc tin";
    case SmsCkStatus.SMSORDER_ERROR_NOTFOUND:
      return "Không thấy đơn";
    case SmsCkStatus.SMSORDER_ERROR_NOTENOUGH:
      return "Không đủ tiền";
    case SmsCkStatus.SMSORDER_ERROR_REJECT:
      return "Bị từ chối";
    case SmsCkStatus.SMSORDER_ERROR_UNKNOWN:
      return "Lỗi giao dịch";
    case SmsCkStatus.SMSORDER_CONFIRM_SUCCESS:
      return "Thành công";
    default:
    // do nothing
  }

  return "" + orderStatus;
}

// Build message box
function getMessageBox(errorMessage, successMessage, guideMessage) {
  return (
    <Box component="div" style={{ margin: "auto", textAlign: "center" }}>
      {errorMessage && <Typography variant="body1" component="span" style={{ color: "red" }}>{errorMessage}</Typography>}
      {successMessage && <Typography variant="body1" component="span" style={{ color: "green" }}>{successMessage}</Typography>}
      {!errorMessage && !successMessage && <Typography variant="body1" component="span" style={{ color: Colors.CK_VIOLET }}>{guideMessage}</Typography>}
    </Box>
  );
}

// export functions
export {
  parseTxBean,
  compareTx,
  compareTxDesc,
  compareUpdatedDesc,
  comparePartner,
  compareAccount,
  getStatusColor,
  getStatusIcon,
  getTxStatusText,
  getConfirmStatusText,
  getTxName,
  getTxIcon,
  getTxTimeText,
  getTxTimeVN,
  getTxTimeShort,
  getTxMillisText,
  getHttpStatusText,
  getPaymentStatusText,
  getPaymentStatusColor,
  getSmsOrderStatusColor,
  getSmsOrderStatus,
  getMessageBox,
};


export function formatAmount(amount) {
  return <NumberFormat value={amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'đ'} />
}

export function formatAmountVND(amount) {
  return <NumberFormat value={amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' VNĐ'} />
}

export function formatCount(count) {
  return <NumberFormat value={count} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} />
}