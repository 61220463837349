import React, { useState } from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import Main from "./Main";
import Features from "./Features";
import SupportBanks from "./SupportBanks";
import Fqa from "./Fqa";
import Pricing from "./Pricing";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import EasyRegisterDialog from "../register/EasyRegisterDialog";
import Config from "../../ckcore/core/Config";
import Colors from "../../ckcore/core/Colors";

const theme = createTheme({
  palette: {
      primary: {
          main: Colors.BG_BLUE,
      },
      secondary: {
          main: Colors.CK_VIOLET,
          contrastText: "#fff"
      }
  },
});

export default function HomePage(props) {


  const [showPreorderDialog, setShowPreorderDialog] = useState(false);
  const openPreorderDialog = () => {
      setShowPreorderDialog(true);
  }
  const closePreorderDialog = () => {
      setShowPreorderDialog(false);
  }

  const [subscriptionPackage, setSubscriptionPackage] = useState('small'); 
  // User change subscription
  const onRequestSubscription = (ckpk) => {
    setSubscriptionPackage(ckpk);
    setTimeout(() => {
      openPreorderDialog();
    }, 100);
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="home">
          <Main onRequestRegister={onRequestSubscription}/>
          <Features />
          <Fqa />
          <Pricing onChangeSubscription={onRequestSubscription}/>
          <SupportBanks />
        </div>

        {
            showPreorderDialog &&
            <GoogleReCaptchaProvider reCaptchaKey={Config.getCaptchaKey()}>
                <EasyRegisterDialog open={showPreorderDialog} closeCallback={closePreorderDialog} subscriptionPackage={subscriptionPackage} />
            </GoogleReCaptchaProvider>
        }
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
