const TxTypes = {

  // User Account Tx
  TX_USER_REGISTER                    : 10,
  TX_USER_REGISTER_EASY               : 11,
  TX_USER_AUTHORIZE                   : 12,
  TX_USER_CHANGE_PASSWORD             : 13,
  TX_USER_FORGOT_PASSWORD             : 14,
  TX_USER_CHECK_PHONE                 : 15,
  TX_USER_RESEND_AUTHCODE             : 16,
  TX_USER_RESEND_PUSHCODE             : 17,
  TX_USER_CHECK_PUSHCODE              : 18,
  TX_USER_CHECK_CAPTCHA               : 19,

  TX_USER_LOGIN                       : 20,
  TX_USER_LOGOUT                      : 21,
  TX_USER_LOGIN_TOKEN                 : 22, // login using cookie
  TX_USER_CHECK                       : 23,
  TX_USER_REFRESH                     : 24,
  TX_USER_REQUEST_ROLE                : 25,
  TX_USER_ACCEPT_ROLE                 : 26, // user accept role request by partner admin
  TX_USER_ACTIVE_ROLE                 : 27,
  TX_USER_LIST_PARTNER                : 28, // user list all his partner obj

  TX_PAYMENT_REQUEST                  : 30,

  TX_USER_DOWNLOAD_LINK               : 37,

  // Partner TX
  PARTNER_ADMIN_CREATE 				        : 50,
	PARTNER_ADMIN_INFO_LOAD 			      : 51,
	PARTNER_ADMIN_INFO_UPDATE 			    : 52,
	PARTNER_ADMIN_DASHBOARD_STATUS 	    : 53,
	// api    
	PARTNER_ADMIN_GENERATE_API_KEY 	    : 55,
	PARTNER_ADMIN_COPY_API_KEY 			    : 56,
	// staff    
	PARTNER_ADMIN_STAFF_CHECK 			    : 60,
	PARTNER_ADMIN_STAFF_REQUEST 		    : 61,
	PARTNER_ADMIN_STAFF_CREATE 			    : 62,
	PARTNER_ADMIN_STAFF_UPDATE 		      : 63,
	PARTNER_ADMIN_STAFF_REMOVE 		      : 64,
	PARTNER_ADMIN_STAFF_LIST 			      : 65,

  // delegate SIMs
  PARTNER_ADMIN_LIST_DELEGATE_SIM 	  : 66,
	PARTNER_ADMIN_REGISTER_DELEGATE_SIM : 67,
	PARTNER_ADMIN_REMOVE_DELEGATE_SIM 	: 68,
	PARTNER_ADMIN_DELEGATE_SIM_STATUS 	: 69,

  // order
  PARTNER_ADMIN_ORDER_DETAILS         : 70,
  PARTNER_ADMIN_ORDER_LIST            : 71,
  PARTNER_ADMIN_ORDER_MATCHING 		    : 72,
  PARTNER_ADMIN_ORDER_MANUAL_CONFIRM  : 73,
  PARTNER_ADMIN_ORDER_MANUAL_CANCEL 	: 74,
	PARTNER_ADMIN_ORDER_MANUAL_UPDATE 	: 75,
	PARTNER_ADMIN_ORDER_PRINT 			    : 76,
	PARTNER_ADMIN_ORDER_CREATE 			    : 77,
	PARTNER_ADMIN_ORDER_LIST_EXPORT 		: 78,
  
	// bank   
  PARTNER_ADMIN_BANK_TX_LIST_EXPORT   : 79,
	PARTNER_ADMIN_BANK_UPDATE 			    : 80,
	PARTNER_ADMIN_BANK_VERIFY 			    : 81,
	PARTNER_ADMIN_BANK_SET_PRIMARY 	    : 82,
  PARTNER_ADMIN_BANK_TX_LIST          : 83,
  PARTNER_ADMIN_BANK_TX_UPDATE        : 84,
  
	// sms    
	PARTNER_ADMIN_LOAD_SMS_LIST 		    : 85,
	PARTNER_ADMIN_SEND_SMS_MT 			    : 86,

  PARTNER_ADMIN_TOPUP_CKCOIN          : 88,
  PARTNER_ADMIN_EXTEND_SUBSCRIPTION   : 89,

  	// product
	PARTNER_ADMIN_LOAD_PRODUCTS 		    : 90,
	PARTNER_ADMIN_UPDATE_PRODUCT 		    : 91,
	PARTNER_ADMIN_SYNC_PRODUCTS 		    : 92,

	// customer
	PARTNER_ADMIN_LOAD_CUSTOMER_DETAILS : 93,
	PARTNER_ADMIN_LOAD_CUSTOMERS 		    : 94,
	PARTNER_ADMIN_UPDATE_CUSTOMER 		  : 95,
	PARTNER_ADMIN_SYNC_CUSTOMERS 		    : 96,


	// SMS MO/MT TX
	TX_SMS_CK_TRANSACTION 				      : 97,
	TX_SMS_MO_TRANSACTION 				      : 98,
	TX_SMS_MT_TRANSACTION 				      : 99,

  TX_MODEM_UPDATE_CONFIRMED_TX        : 113,

  // SUPER ADMIN TX
	ORIGIN_ADMIN_SYSTEM_REPORT 			    : 900,
	ORIGIN_ADMIN_USER_REPORT 		        : 903,
	ORIGIN_ADMIN_ORDER_REPORT			      : 905,
  ORIGIN_ADMIN_SIM_REPORT             : 906,
	ORIGIN_ADMIN_PARTNER_REPORT 		    : 910,
	ORIGIN_ADMIN_BANK_ACCOUNT_REPORT 		: 911,
	ORIGIN_ADMIN_BANK_ACCOUNT_UPDATE 		: 912,

  ORIGIN_ADMIN_PARTNER_UPDATE         : 913,
  ORIGIN_ADMIN_PARTNER_REJECT         : 914,
  ORIGIN_ADMIN_PARTNER_SUSPEND        : 915,
  ORIGIN_ADMIN_PARTNER_RELEASE        : 916,
  ORIGIN_ADMIN_PARTNER_INFO           : 917,
  ORIGIN_ADMIN_PARTNER_EXTEND_SUB     : 918,
  ORIGIN_ADMIN_SERVICE_UPDATE         : 919,
  ORIGIN_ADMIN_TRANSACTION_REPORT     : 920,
  ORIGIN_ADMIN_CASHOUT_REPORT         : 921,
  ORIGIN_ADMIN_CASHOUT_CONFIRM        : 922,
  ORIGIN_ADMIN_CASHOUT_REJECT         : 923,
  ORIGIN_ADMIN_CASHOUT_EXPORT         : 924,
  ORIGIN_ADMIN_TRANSFER_REQUEST       : 925,
  ORIGIN_ADMIN_TRANSFER_CONFIRM       : 926,
  ORIGIN_ADMIN_RESET_PASSWORD         : 929,

  ORIGIN_ADMIN_SUPPORT_SYNC_BANK_TX 	: 930,

};

export default TxTypes;
