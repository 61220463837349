import React from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import Navigation from "./components/Navigation";
import Contacts from "./components/Contacts";
import { Routes, Route, Link } from "react-router-dom";
import TermsOfUse from "./components/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy";
import HomePage from "./components/HomePage";
import Colors from "../ckcore/core/Colors";
import ContactBox from "./components/ContactBox";
import { purple } from "@mui/material/colors";
import { Hidden } from "@mui/material";

export default function App(props) {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="root">

          <Navigation showMenu={true} />

          <Routes>
            <Route path="/terms" element={<TermsOfUse />} />
            <Route path="/dieu-khoan-su-dung" element={<TermsOfUse />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/chinh-sach-bao-mat" element={<PrivacyPolicy />} />
            <Route path="/" element={<HomePage />}/>
            <Route path="*" element={<HomePage />}/>
          </Routes>
          
          <Contacts showContactForm={true} />

          <Hidden mdDown>
            <ContactBox />
          </Hidden>

        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const theme = createTheme({
  palette: {
    
    primary: {
      main: Colors.BG_BLUE,
    },

    secondary: {
      main: Colors.CK_VIOLET,
      contrastText: "#fff" //button text white instead of black
    },

    action: {
      main: "#FFFFFF",
    },

    gray: {
      main: Colors.GRAY
    },
    
    text: {
    }
  },
});