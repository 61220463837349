import React from 'react';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SettingsEthernetOutlinedIcon from '@mui/icons-material/SettingsEthernetOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import FoodBankOutlinedIcon from '@mui/icons-material/FoodBankOutlined';
import Colors from '../../ckcore/core/Colors';

export default function Features(props) {
    // const classes = useStyle();
    return (
        <div id="features" className="text-center">
            <div className="container">
                <div className="col-md-10 col-md-offset-1 section-title">
                    <h3 style={{ fontSize: 26, color: "#37517e" }}>TÍNH NĂNG CHÍNH</h3>
                </div>
                <div className="row">
                    <div key="0" className="col-xs-0 col-md-2"></div>
                    <div key="1" className="col-xs-12 col-md-4">
                        <SettingsEthernetOutlinedIcon style={{ color: Colors.DARK_BLUE, fontSize: 48 }} />
                        <h3 style={{ color: Colors.DARK_BLUE }}>Thanh toán tự động</h3>
                        <p style={{ color: "#001f3e" }}>Khách hàng của bạn chỉ cần quét mã QR để thanh toán, và giao dịch được xác nhận tự động ngay khi chuyển khoản thành công.</p>
                    </div>
                    <div key="2" className="col-xs-12 col-md-4">
                        <AccountBalanceOutlinedIcon style={{ color: Colors.DARK_BLUE, fontSize: 48 }} />
                        <h3 style={{ color: Colors.DARK_BLUE }}>Tổng hợp nhiều tài khoản</h3>
                        <p style={{ color: "#001f3e" }}>Chuyenkhoan.com hỗ trợ cả tài khoản công ty và tài khoản cá nhân của hầu hết các ngân hàng Việt Nam. CKPay có công cụ giúp bạn xây các báo cáo thu chi dễ dàng.</p>
                    </div>
                    <div key="3" className="col-xs-0 col-md-2"></div>
                </div>
                <div className="row">
                    <div key="4" className="col-xs-0 col-md-2"></div>
                    <div key="5" className="col-xs-12 col-md-4">
                        <QrCodeOutlinedIcon style={{ color: Colors.DARK_BLUE, fontSize: 48 }} />
                        <h3 style={{ color: Colors.DARK_BLUE }}>CK VietQR cho Web/App</h3>
                        <p style={{ color: "#001f3e" }}>Plugin nhúng mã chuyển khoản nhanh QRCode cho website, app, email giúp chuyển khoản thanh toán đơn giản và chính xác.</p>
                    </div>
                    <div key="6" className="col-xs-12 col-md-4">
                        <FoodBankOutlinedIcon style={{ color: Colors.DARK_BLUE, fontSize: 48 }} />
                        <h3 style={{ color: Colors.DARK_BLUE }}>Minh bạch thu chi</h3>
                        <p style={{ color: "#001f3e" }}>Chuyenkhoan.com hỗ trợ đắc lực các quỹ từ thiện minh bạch thông tin tài chính, giúp hoạt động từ thiện hiệu quả hơn.</p>
                    </div>
                    <div key="7" className="col-xs-0 col-md-2"></div>
                </div>
            </div>
        </div>
    );
}
