var ValUtils = (function () {
  // Opera 8.0+
  var isOpera = !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== "undefined";

  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"]);

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 79
  var isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Edge (based on chromium) detection
  // var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);

  // Blink engine detection
  // var isBlink = (isChrome || isOpera) && !!window.CSS;

  // Get number from string
  var getNumber = function (string) {
    if (!string) {
      return "";
    }

    var numbers = string.match(/\d/g);
    if(!(numbers)) {
      return "";
    }
    
    return parseInt(numbers.join(""));
  };

  // Get operating system name
  var getOS = function () {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  };

  // Get browser name
  var getBrowser = function () {
    // Chrome 1 - 71
    if (isChrome) {
      return "Chrome";
    } else if (isFirefox) {
      return "Firefox";
    } else if (isEdge) {
      return "Edge";
    } else if (isSafari) {
      return "Safari";
    } else if (isOpera) {
      return "Opera";
    } else if (isIE) {
      return "IE";
    }

    return "";
  };

  /**
   * Extract domain from URL
   */
  const getDomain = function (url, subdomain) {
    subdomain = subdomain || false;
  
    url = url.replace(/(https?:\/\/)?(www.)?/i, '');
  
    if (!subdomain) {
        url = url.split('.');
  
        url = url.slice(url.length - 2).join('.');
    }
  
    if (url.indexOf('/') !== -1) {
        return url.split('/')[0];
    }
  
    return url;
  }
  

  /**
   * Format money, default using . for VND
   */
  const formatMoney = function (value, separator) {

    // avoid undefine
    if(!(value)) {
      return "";
    }

    if(!(separator)) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  }

  /**
   * Format bank account number, separate by space
   */
  const formatBankAccountNumber = function (accountNumber) {

    // avoid un define
    if(!accountNumber) {
      return "";
    }

    var cleaned = ('' + accountNumber).replace(/\D/g, '');
    var match;
    if(cleaned.length > 9) {
      match = cleaned.match(/^(\d{4})(\d{4})(\d{1,6})$/);
      if (match) {
        return match[1] + ' ' + match[2] + ' ' + match[3];
      }
    } else if(cleaned.length === 9) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
        if (match) {
          return match[1] + ' ' + match[2] + ' ' + match[3];
        }
    } else if(cleaned.length >= 7) {
      match = cleaned.match(/^(\d{4})(\d{4,5})$/);
      if (match) {
        return match[1] + ' ' + match[2];
      }
    }

    return accountNumber;
  }

  /**
   * Get only number from string
   */
  const getNumberOnly = function (valStr) {
    if(!(valStr)) {
      return "";
    } else {
      return valStr.replace(/\D/g, "");
    }

  }

  const getDisplayPhone = function (phone) {
    if (phone.startsWith("84")) {
      return "0" + phone.substring(2);
    }

    return phone;
  };

  const getFormatedPhone = function (phone) {
    if (phone.startsWith("0")) {
      return "84" + phone.substring(1);
    }

    return phone;
  };

  // Return declare functions
  return {
    getNumber: getNumber,
    getOS: getOS,
    getBrowser: getBrowser,
    getDomain: getDomain,
    formatMoney: formatMoney,
    formatBankAccountNumber: formatBankAccountNumber,
    getNumberOnly: getNumberOnly,
    getDisplayPhone: getDisplayPhone,
    getFormatedPhone: getFormatedPhone
  };
})();

export default ValUtils;
