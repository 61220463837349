import React from 'react';
import '../../payment/components/ckpayment.css';
import { Container } from '@mui/material';
import Colors from '../../ckcore/core/Colors';


export default function TermsOfUse(props) {

    return (
        <Container >
            <div style={{ textAlign: 'left', marginTop: 64, padding: 20, paddingTop: 40, paddingBottom: 40, fontSize: 11 }}>
                <h3 style={{color: Colors.DARK_BLUE, textAlign: 'center', margin: 'auto'}}>ĐIỀU KHOẢN SỬ DỤNG DỊCH VỤ THANH TOÁN CHUYỂN KHOẢN</h3>
                <p style={{color: Colors.DARK, textAlign: 'center', margin: 'auto', padding: 16}}>Áp dụng từ Thứ Bảy, 01/01/2022</p>
                <div style={{color: Colors.DARK_BLUE}}>
                    <p style={{}}><strong>Điều 1: Giải thích từ ngữ</strong></p>
                    <p style={{}}>1.1. <i>Thỏa thuận sử dụng dịch vụ Thanh Toán Chuyển Khoản (gọi tắt là "Bản thỏa thuận")</i>: là Bản Hợp đồng giữa Bạn/Người sử dụng dịch vụ Thanh Toán Chuyển Khoản (sau đây gọi là "Khách hàng) và Công ty Cổ phần Giải pháp Thanh toán Chuyển Khoản (sau đây gọi tắt là "CKPay"), áp dụng khi Khách hàng sử dụng dịch vụ Thanh Toán Chuyển Khoản của CKPay.</p>
                    <p style={{}}>1.2. <i>Tài khoản Thanh Toán Chuyển Khoản</i>: là tài khoản định danh do CKPay phát hành trên https://chuyenkhoan.com.</p>
                    <p style={{}}>1.3. <i>Dịch vụ Chuyển nhanh Napas247 (gọi tắt là Napas247)</i>: Là dịch vụ cho phép khách hàng chuyển tiền từ tài khoản của mình tới một tài khoản tại một ngân hàng khác trong mạng lưới của Napas theo phương thức chuyển tiền nhanh 24/7 (thực hiện theo thời gian thực, 24 giờ/ngày, 7 ngày/tuần). Hạn mức tối đa 1 giao dịch là dưới 500 triệu đồng; </p>
                    <p style={{}}>1.4. <i>Khách hàng</i>: Là cá nhân có tài khoản thanh toán mở tại các ngân hàng cung cấp dịch vụ Napas247; </p>
                    <p style={{}}>1.5. <i>Đối tác</i>: Là tổ chức có tài khoản thanh toán mở tại các ngân hàng cung cấp dịch vụ Napas247; </p>
                    <p style={{}}>1.6. <i>Mã QR/QR Code (Mã phản hồi nhanh) thanh toán</i>: là thông tin thanh toán được mã hóa gồm: Số tài khoản, Mã hiệu ngân hàng, Số tiền, Nội dung thanh toán, Thông tin đơn hàng và các thông tin liên quan khác (nếu có) giúp việc khởi tạo lệnh thanh toán được thực hiện Chính xác, Nhanh chóng và An toàn. Mã QR trong thanh toán tuân thủ theo tiêu chuẩn thanh toán QR của EMV Co. và Tiêu chuẩn cơ sở cho mã QR do Ngân hàng Nhà nước Việt Nam ban hành; </p>
                    <p style={{}}>1.7. <i>Mã QR cá nhân</i>: là mã QR do Khách hàng khởi tạo tại VietQR.net phục vụ cho việc chuyển khoản giữa các ngân hàng trong mạng lưới Napas247; </p>
                    <p style={{}}>1.8. <i>Chuyển khoản bằng mã QR</i>: là phương thức chuyển tiền giữa các tài khoản ngân hàng thông qua hình thức quét mã QR cá nhân; </p>
                    <p style={{}}>1.9. <i>VietQR</i>: là nhận diện thương hiệu dành cho các dịch vụ thanh toán, chuyển khoản bằng mã QR được xử lý qua mạng lưới Napas, các Ngân hàng thành viên, Trung gian thanh toán, các đối tác thanh toán tại Việt Nam và Quốc tế của Napas. </p>
                    <p style={{}}>1.10. <i>Dịch vụ Thanh Toán Chuyển Khoản (gọi tắt là "Dịch vụ")</i>: là các dịch vụ do CKPay cung cấp trên https://chuyenkhoan.com.</p>
                    <p style={{}}>1.11. <i>Giao dịch Thanh Toán Chuyển Khoản (gọi tắt là “Giao dịch”)</i>: là các giao dịch có thể được thực hiện thông qua tài khoản Thanh Toán Chuyển Khoản vào từng thời điểm, bao gồm (nhưng không giới hạn) các giao dịch sau đây:</p>
                    <p style={{}}>1.11.1. Tạo mã chuyển khoản QR cho đơn hàng</p>
                    <p style={{}}>1.11.2. Xác nhận giao dịch qua BĐSD gửi về từ SMS</p>
                    <p style={{}}>1.11.3. Xuất báo cáo tài chính từ bảng BĐSD.</p>
                    <p style={{}}>1.12. <i>Giấy tờ hợp lệ</i>: là (a) Chứng minh nhân dân (CMND), Căn cước công dân, Hộ chiếu do Chính phủ Việt Nam cấp còn hiệu lực, (b) Giấy phép Đăng ký kinh doanh hợp lệ.</p>
                    <p style={{}}>1.13. <i>Ngày làm việc</i>: Là các ngày từ thứ hai đến thứ sáu mà CKPay làm việc bình thường; trừ các ngày nghỉ, lễ, tết theo quy định pháp luật.</p>
                    <p style={{}}><strong>Điều 2: Đăng ký và sử dụng dịch vụ</strong></p>
                    <p style={{}}>2.1. Trước khi đăng ký sử dụng Dịch vụ, Khách hàng cần đọc, đồng ý và chấp nhận tất cả các quy định trong Bản thỏa thuận này và/hoặc các quy định, chính sách, và các điều khoản liên quan khác trong quá trình sử dụng Dịch vụ.</p>
                    <p style={{}}>2.2. Khi đã sử dụng Dịch vụ thì có nghĩa là Khách hàng đã hoàn toàn tự nguyện đồng ý, chấp nhận cũng như tuân thủ tất cả các quy định trong Bản thỏa thuận này và/hoặc các quy định, chính sách, và các điều khoản liên quan khác trong quá trình sử dụng Dịch vụ.</p>
                    <p style={{}}>2.3. Trong quá trình sử dụng Dịch vụ, Khách hàng đồng ý cung cấp đầy đủ, chính xác thông tin về họ tên, địa chỉ liên lạc, giấy tờ tùy thân hợp lệ, email… và chịu trách nhiệm về các thông tin mà Khách hàng cung cấp.</p>
                    <p style={{}}>2.4. Nếu phát sinh rủi ro trong quá trình sử dụng Dịch vụ, CKPay căn cứ vào những thông tin mà Khách hàng đã cung cấp để tiếp nhận và giải quyết. CKPay chỉ tiếp nhận và giải quyết những trường hợp khai báo đúng, chính xác và đầy đủ những thông tin quy định tại Khoản 2.3. Những trường hợp khai báo không đầy đủ và chính xác, Khách hàng sẽ không nhận được bất kỳ sự hỗ trợ nào từ CKPay, và CKPay sẽ không chịu trách nhiệm giải quyết những tranh chấp, rủi ro phát sinh đối với việc sử dụng Dịch vụ của Khách hàng.</p>
                    <p style={{}}><strong>Điều 3: Thay đổi nội dung các điều kiện và điều khoản</strong></p>
                    <p style={{}}>3.1. CKPay có thể sửa đổi, bổ sung nội dung Bản thỏa thuận này vào bất cứ thời gian nào bằng cách gửi thông báo thay đổi cho Khách hàng.</p>
                    <p style={{}}>3.2. Khách hàng được xem là đã nhận thông báo nếu:</p>
                    <p style={{}}>3.2.1. Các thông báo này đã được đưa lên trang website của Dịch vụ tại địa chỉ: https://chuyenkhoan.com; hoặc</p>
                    <p style={{}}>3.2.2. Các thông báo này đã được gửi tới địa chỉ thư điện tử (email) mà Khách hàng đăng ký trong quá trình sử dụng Dịch vụ; hoặc</p>
                    <p style={{}}>3.2.3. Được gửi tới số điện thoại Khách hàng đăng ký sử dụng Dịch vụ; hoặc</p>
                    <p style={{}}>3.3. Trường hợp đối với những thay đổi như: (a) tăng trách nhiệm và nghĩa vụ của Khách hàng, (b) hạn chế bớt các dịch vụ đang cung cấp trên tài khoản Thanh Toán Chuyển Khoản, hoặc (c) giảm hạn mức giao dịch, trừ khi những thay đổi này phải thực hiện ngay vì yêu cầu an ninh hoặc theo quy định của pháp luật, CKPay sẽ thông báo cho Khách hàng ít nhất 10 (mười) ngày làm việc kể từ ngày có hiệu lực của những thay đổi này.</p>
                    <p style={{}}>3.4. Nếu Khách hàng không đồng ý với nội dung thay đổi thì có thể chấm dứt việc tiếp tục sử dụng Dịch vụ. Trường hợp Khách hàng tiếp tục sử dụng Dịch vụ thì có nghĩa là Khách hàng đã hoàn toàn tự nguyện đồng ý, chấp nhận cũng như tuân thủ các nội dung thay đổi đó.</p>
                    <p style={{}}><strong>Điều 4: Sử dụng dịch vụ và Phí dịch vụ</strong></p>
                    <p style={{}}>4.1. Khi Khách hàng chủ động thực hiện Giao dịch thông qua Thanh Toán Chuyển Khoản thì các hàng hoá, dịch vụ của Khách hàng được xác định là bàn giao ngay khi Giao dịch được thực hiện thành công. Chúng tôi sẽ không hoàn trả và/hoặc đổi lại các hàng hoá, dịch vụ này trừ trường hợp phát sinh lỗi kỹ thuật từ phía Thanh Toán Chuyển Khoản và/hoặc hàng hoá, dịch vụ bị lỗi từ phía Nhà cung cấp sản phẩm cho Thanh Toán Chuyển Khoản dẫn đến việc hànghoá, dịch vụ này là không sử dụng được. Nhân viên Thanh Toán Chuyển Khoản sẽ tiếp nhận, xác minh và thông tin đến Khách hàng theo từng trường hợp cụ thể.</p>
                    <p style={{}}>4.2. Phí sử dụng dịch vụ được CKPay công bố cho từng thời kỳ, tùy theo đặc điểm của từng loại dịch vụ.</p>
                    <p style={{}}>4.3. Khách hàng ủy quyền cho CKPay được quyền tự động trích nợ phí sử dụng dịch vụ trên tài khoản Thanh Toán Chuyển Khoản và/hoặc trong các phương tiện thanh toán khác có kết nối với tài khoản Thanh Toán Chuyển Khoản mà Khách hàng lựa chọn (như thẻ tín dụng, tài khoản ngân hàng) để thanh toán tiền phí sử dụng dịch vụ này.</p>
                    <p style={{}}>4.4. Phí dịch vụ có thể được thu trước, sau hay ngay khi phát sinh giao dịch tùy vào từng loại hình dịch vụ mà CKPay cung cấp.</p>
                    <p style={{}}><strong>Điều 5: Quyền và trách nhiệm của Khách hàng</strong></p>
                    <p style={{}}>5.1. Quyền của Khách hàng</p>
                    <p style={{}}>5.1.1. Khách hàng được quyền thay đổi, bổ sung thông tin tài khoản, mật khẩu đặt lệnh, giấy tờ tùy thân, email đã đăng ký, số điện thoại gắn với tài khoản.</p>
                    <p style={{}}>5.1.2. Khách hàng có thể chấm dứt sử dụng Dịch vụ vào bất kỳ lúc nào sau khi đã gửi cho CKPay văn bản yêu cầu ngừng sử dụng dịch vụ theo mẫu mà CKPay cung cấp. Sau khi chấm dứt sử dụng Dịch vụ, Khách hàng không được tiếp tục sử dụng Dịch vụ nữa.</p>
                    <p style={{}}>5.2. Trách nhiệm của Khách hàng.</p>
                    <p style={{}}>5.2.1. Cung cấp thông tin đúng và đầy đủ, tuân thủ các thủ tục đăng ký, trình tự giao dịch và các hướng dẫn khác của CKPay, sử dụng đúng mục đích những thông tin mà Dịch vụ cung cấp.</p>
                    <p style={{}}>5.2.2. Khi việc thực hiện giao dịch thông qua một đơn vị trung gian (cụ thể ở đây là ngân hàng, tổ chức tín dụng, đơn vị phát hành thẻ), Khách hàng có trách nhiệm tuân thủ các quy định của đơn vị trung gian này. Mọi vấn đề phát sinh về nghĩa vụ và quyền lợi của Khách hàng và Đơn vị trung gian này nằm ngoài sự kiểm soát và trách nhiệm của Thanh Toán Chuyển Khoản nên chúng tôi có khả năng từ chối nếu như hai Bên có mâu thuẫn xảy ra.</p>
                    <p style={{}}>5.2.3. Nếu Khách hàng cho rằng có một sự nhầm lẫn, lỗi hoặc sai sót trong việc sử dụng Dịch vụ và/hoặc xử lý chỉ dẫn thanh toán của CKPay, Khách hàng có thể liên lạc trực tiếp với bộ phận hỗ trợ dịch vụ để được giải quyết. Những vấn đề có thể phát sinh là:</p>
                    <p style={{}}>a) Bất kỳ sự chậm chễ hoặc sai sót nào trong việc xử lý chỉ dẫn thanh toán của Khách hàng; hoặc
                    </p><p style={{}}>b) Nếu phát hiện tài khoản của Khách hàng có phát sinh giao dịch mà không do Khách hàng thực hiện; hoặc
                    </p><p style={{}}>c) Mật khẩu đặt lệnh và/hoặc thông tin của Khách hàng đã đăng ký trên hệ thống CKPay bị tiết lộ cho Bên thứ ba; hoặc
                    </p><p style={{}}>d) Có sự truy cập không hợp lệ vào tài khoản của Khách hàng; hoặc
                    </p><p style={{}}>e) Các lỗi khác mà Khách hàng phát hiện được xuất phát từ hệ thống của CKPay.
                    </p><p style={{}}>5.2.4. Khách hàng chịu trách nhiệm áp dụng mọi biện pháp hợp lý nhằm đảm bảo an toàn, đảm bảo tính tương thích cho các loại máy móc, thiết bị kết nối, phần mềm hệ thống, phần mềm ứng dụng… do Khách hàng sử dụng khi kết nối, truy cập vào dịch vụ nhằm kiểm soát, phòng ngừa và ngăn chặn việc sử dụng hoặc truy cập trái phép Dịch vụ.</p>
                    <p style={{}}>5.2.5. Khách hàng phải đảm bảo an toàn và bí mật đối với bất kỳ thông tin cá nhân, mật khẩu truy cập, mật khẩu đặt lệnh và các yếu tố định danh khác mà CKPay cung cấp. Khách hàng hoàn toàn chịu trách nhiệm trong trường hợp các thông tin này bị mất, lợi dụng, tiết lộ cho một người thứ ba và sẽ chịu mọi rủi ro, thiệt hại gây ra do việc sử dụng trái phép của người thứ ba đó.</p>
                    <p style={{}}><strong>Điều 6: Quyền và trách nhiệm của CKPay</strong></p>
                    <p style={{}}>6.1. Quyền của CKPay</p>
                    <p style={{}}>6.1.1. Nếu CKPay có lý do cho rằng Khách hàng vi phạm các hành vi cấm quy định tại Điều 9, CKPay sẽ có những biện pháp để bảo vệ CKPay và/hoặc Khách hàng khác hoặc Bên thứ ba có liên quan. Những hàng động CKPay có thể thực hiện được bao gồm nhưng không giới hạn ở những hàng động này:</p>
                    <p style={{}}>a) CKPay có thể đóng, tạm ngừng hoặc giới hạn truy nhập của Khách hàng vào tài khoản và dịch vụ mà CKPay cung cấp;</p>
                    <p style={{}}>b) CKPay có thể liên hệ với người Bán/người Mua mà Khách hàng giao dịch, liên hệ với ngân hàng của Khách hàng, với </p><p style={{}}>Khách hàng khác hoặc liên hệ tới Bên thứ ba để cảnh báo hoặc lưu ý tới hành động của Khách hàng;</p>
                    <p style={{}}>6.1.2. CKPay không chịu trách nhiệm đối với những thiệt hại, tổn thất của Khách hàng phát sinh trong quá trình sử dụng Dịch vụ, trừ phi những thiệt hại, tổn thất này là do lỗi cố ý của CKPay.</p>
                    <p style={{}}>6.1.3. CKPay không chịu trách nhiệm đối với những thiệt hại trực tiếp hoặc gián tiếp mà khách hàng phải chịu phát sinh từ hoặc do:</p>
                    <p style={{}}>a) Việc sử dụng dịch vụ hoặc tiếp cận các thông tin mà dịch vụ cung ứng của những người được Khách hàng ủy quyền; hoặc</p>
                    <p style={{}}>b) Việc Khách hàng để mất, mất cắp thiết bị di động dùng để đăng nhập dịch vụ, lộ mật khẩu đặt lệnh và/hoặc các yếu tố định danh khác mà CKPay cung cấp dẫn đến người khác dùng những thông tin này để sử dụng dịch vụ hoặc tiếp cận những thông tin mà Dịch vụ cung ứng; hoặc</p>
                    <p style={{}}>c) Sự ngắt quãng, trì hoãn, chậm chễ, tình trạng không sẵn sàng sử dụng hoặc bất kỳ sự cố nào xảy ra trong quá trình cung cấp Dịch vụ do các nguyên nhân ngoài khả năng kiểm soát hợp lý của CKPay, bao gồm nhưng không giới hạn ở tình trạng gián đoạn do dịch vụ cần được nâng cấp, sửa chữa, lỗi đường truyền của nhà cung cấp dịch vụ Internet, lỗi gián đoạn do nhà cung cấp dịch vụ; hoặc</p>
                    <p style={{}}>d) Bất cứ hành động bất khả kháng nào bao gồm nhưng không giới hạn bởi thiên tai, đình công, hoặc các yêu cầu hay chỉ thị của Chính phủ và các cơ quan nhà nước, người có thẩm quyền khác.</p>
                    <p style={{}}>6.1.4. CKPay có thể tạm giữ và/hoặc phong tỏa thẻ của Khách hàng trong thời gian không giới hạn nếu thấy cần thiết để phòng ngừa các rủi ro có thể xảy ra và/hoặc nếu Khách hàng bị khiếu nại, tranh chấp, đòi hoàn lại thẻ.</p>
                    <p style={{}}>6.1.5. CKPay có quyền thay đổi, ngừng hoặc chấm dứt cung cấp Dịch vụ vào bất kỳ thời điểm nào mà không cần có sự đồng ý của Khách hàng. Tuy nhiên, CKPay có trách nhiệm thông báo cho Khách hàng về việc thay đổi, ngừng hoặc chấm dứt cung cấp Dịch vụ.</p>
                    <p style={{}}>6.1.6. CKPay có thể từ chối không thực hiện chỉ dẫn thanh toán của Khách hàng trong trường hợp trong tài khoản Thanh Toán Chuyển Khoản và/hoặc trong các phương tiện thanh toán khác có kết nối với tài khoản Thanh Toán Chuyển Khoản mà Khách hàng lựa chọn (như thẻ tín dụng, tài khoản ngân hàng) không đủ số dư; hoặc do tài khoản Thanh Toán Chuyển Khoản của Khách hàng đã đóng hoặc không hợp lệ; hoặc do nhà cung cấp dịch vụ không cho phép thực hiện thanh toán bằng phương tiện điện tử, và trong trường hợp như vậy, CKPay sẽ gửi thông báo cho Khách hàng.</p>
                    <p style={{}}>6.1.7. CKPay không chấp nhận việc hủy bỏ chỉ dẫn thanh toán mà Khách hàng đã thực hiện thành công. Tất cả các chỉ dẫn thanh toán do Khách hàng đưa ra khi sử dụng Dịch vụ được coi là không hủy ngang, cho dù chỉ dẫn thanh toán này do Khách hàng hay một người khác được hiểu là Khách hàng thực hiện. Việc hủy bỏ phải từ phía nhà cung cấp dịch vụ và phải được sự đồng ý của CKPay.</p>
                    <p style={{}}>6.1.8. CKPay được quyền tự động trích nợ tài khoản Thanh Toán Chuyển Khoản và/hoặc các phương tiện thanh toán khác có kết nối với tài khoản Thanh Toán Chuyển Khoản mà Khách hàng sử dụng để thanh toán cho các giao dịch mà Khách hàng mua hàng hóa hoặc sử dụng dịch với các nhà cung cấp mà Khách hàng tham gia. Việc thanh toán này có thể được thực hiện theo yêu cầu của Khách hàng hoặc theo yêu cầu của chính nhà cung cấp dịch vụ.</p>
                    <p style={{}}>6.1.9. CKPay được quyền sử dụng thông tin cá nhân và dữ liệu về giao dịch của Khách hàng để tùy biến và cải tiến nhằm nâng cao chất lượng Dịch vụ. CKPay không sử dụng những dữ liệu này vào mục đích bất hợp pháp. CKPay được quyền cung cấp dữ liệu của Khách hàng cho bên thứ ba trong các trường hợp nhưng không giới hạn:</p>
                    <p style={{}}>a) CKPay được Khách hàng chấp thuận; hoặc</p>
                    <p style={{}}>b) Dịch vụ yêu cầu sự tương tác với một bên thứ ba; hoặc</p>
                    <p style={{}}>c) Theo quy định của pháp luật. </p>
                    <p style={{}}>6.1.10. Nếu Khách hàng vi phạm các cam kết về sử dụng Dịch vụ hoặc sử dụng Dịch vụ vào mục đích vi phạm pháp luật (ví dụ: dùng thẻ tín dụng hoặc tài khoản ngân hàng ăn cắp để giao dịch trên hệ thống CKPay, gian lận trong đặt cọc hàng hóa, đặt cọc hàng hóa cấm tiêu thụ tại Việt Nam…), CKPay có quyền chấm dứt sử dụng Dịch vụ của Khách hàng và tạm giữ toàn bộ số thẻ có trong tài khoản Thanh Toán Chuyển Khoản, bao gồm: thẻ hiện có và thẻ chờ nhận trên hệ thống CKPay.</p>
                    <p style={{}}>6.2. Trách nhiệm của CKPay</p>
                    <p style={{}}>6.2.1. CKPay đảm bảo rằng hệ thống Dịch vụ chạy ổn định, an toàn và tuân thủ các quy định của pháp luật liên quan đến việc cung ứng các dịch vụ trên mạng internet.</p>
                    <p style={{}}>6.2.2. Hỗ trợ Khách hàng, tiếp nhận và giải quyết khiếu nại của Khách hàng trong quá trình sử dụng Dịch vụ.</p>
                    <p style={{}}>6.2.3. Bảo mật thông tin cá nhân và dữ liệu về giao dịch của Khách hàng, CKPay không được bán hoặc trao đổi những thông tin này với bên thứ ba, trừ trường hợp theo quy định của pháp luật hoặc được Khách hàng cho phép.</p>
                    <p style={{}}><strong>Điều 8: Các hành vi cấm</strong></p>
                    <p>Khi sử dụng Dịch vụ, Khách hàng không được:</p>
                    <p style={{}}>7.1. Vi phạm Bản thỏa thuận này và/hoặc tất cả các chính sách, quy định khác mà Khách hàng đã đồng ý với CKPay.</p>
                    <p style={{}}>7.2. Vi phạm bản quyền, bằng sáng chế, bí mật kinh doanh hoặc quyền sở hữu trí tuệ khác của CKPay và của các Bên thứ ba có liên quan.</p>
                    <p style={{}}>7.3. Bán hàng giả, hàng bị cấm kinh doanh, hàng có nguồn gốc bất hợp pháp.</p>
                    <p style={{}}>7.4. Hành động có tính chất làm mất uy tín, phỉ báng, bôi nhọ, đe dọa hoặc quấy rối.</p>
                    <p style={{}}>7.5. Hành động có tính chất bạo lực, hành hung và/hoặc có sử dụng ngôn từ tục tĩu, bôi nhọ, phỉ báng đối với nhân viên CKPay.</p>
                    <p style={{}}>7.6. Cung cấp thông tin sai sự thật, gây nhầm lẫn, sử dụng từ ngữ tục tĩu không phù hợp với thuần phong mỹ tục Việt Nam.</p>
                    <p style={{}}>7.7. Từ chối hợp tác điều tra hoặc không cung cấp thông tin theo yêu cầu của CKPay.</p>
                    <p style={{}}>7.8. Quản lý một tài khoản kết nối tới một tài khoản khác vi phạm một trong những hành động bị giới hạn ở mục này.</p>
                    <p style={{}}>7.9. Sử dụng Dịch vụ theo cách để cố ý tạo ra các tranh chấp, khiếu nại, đòi bồi thường.</p>
                    <p style={{}}>7.10. Tiết lộ thông tin của Khách hàng khác cho bên thứ ba hoặc sử dụng thông tin vào những mục đích tiếp thị / quảng cáo mà Khách hàng chưa được cho phép.</p>
                    <p style={{}}>7.11. Có những hành động vượt quá giới hạn hoặc chuyển các lệnh không hợp lệ lên hệ thống của CKPay.</p>
                    <p style={{}}>7.12. Phát tán virus, trojan, worms hoặc các chương trình máy tính có hại gây trở ngại, gian lận hoặc ảnh hưởng tới hệ thống, dữ liệu hoặc thông tin của Khách hàng khác và/hoặc của CKPay.</p>
                    <p style={{}}>7.13. Sử dụng bất kỳ các loại robot, spider, các thiết bị tự động khác hoặc thực hiện thủ công để giám sát hoặc sao chép trang website, các ứng dụng, giải pháp của CKPay mà không được CKPay cho phép.</p>
                    <p style={{}}>7.14. Sử dụng các thiết bị, phần mềm để vượt qua (hoặc phá) các biện pháp mà CKPay thiết lập nhằm đảm bảo an toàn hệ thống của CKPay.</p>
                    <p style={{}}>7.15. Hành động làm mất kết nối giữa CKPay đến nhà cung cấp dịch vụ và/hoặc đối tác, đại lý… hoặc các nhà cung cấp khác và ngược lại.</p>
                    <p style={{}}><strong>Điều 8: Bản quyền</strong></p>
                    <p style={{}}>Các trang website (bao gồm và không giới hạn các tên miền), ứng dụng, giải pháp, các sản phẩm/dịch vụ, logo và những nội dung khác liên quan đến Dịch vụ đều thuộc quyền quản lý, sở hữu và sử dụng hợp pháp, toàn vẹn và không chuyển giao của CKPay. Mọi sự sao chép, thay đổi, sửa chữa mà không được sự cho phép bằng văn bản của CKPay đều coi là vi phạm pháp luật.</p>
                    <p style={{}}><strong>Điều 9: Các quy định khác</strong></p>
                    <p style={{}}>9.1. Liên lạc với CKPay</p>
                    <p style={{}}>Khách hàng có thể liên lạc với bộ phận hỗ trợ của CKPay theo một trong các phương thức sau:</p>
                    <p style={{}}>Gọi tới số điện thoại: 0946861101</p>
                    <p style={{}}>Gửi email tới địa chỉ: cskh@chuyenkhoan.com</p>
                    <p style={{}}>9.2. Cam kết thực hiện và giải quyết tranh chấp</p>
                    <p style={{}}>Hai bên cam kết nghiêm chỉnh thực hiện các điều khoản của Bản thỏa thuận này và các bản sửa đổi, bổ sung, phụ lục đính kèm (nếu có). Nếu phát sinh tranh chấp trong quá trình thực hiện, hai bên sẽ cùng nhau giải quyết bằng thương lượng hoặc hòa giải. Trường hợp thương lượng hoặc hòa giải không thành, thì một trong các bên có quyền đưa vụ tranh chấp ra Tòa án nhân dân Thành phố Hà Nội để giải quyết.</p>
                    <p style={{}}>9.3. Tất cả các điều khoản và điều kiện của Bản thỏa thuận này vẫn có hiệu lực sau khi Khách hàng ngừng hoặc chấm dứt dịch vụ. Ngay cả khi ngừng, chấm dứt sử dụng Dịch vụ, Khách hàng vẫn bị ràng buộc bởi các điều khoản và điều kiện này ở chừng mực liên quan đến quyền và trách nhiệm của Khách hàng trong thời gian sử dụng Dịch vụ.</p>
            </div>
        </div>
        </Container>
    );
}
