
import { ckTableStyles } from '../../ckcore/ui/CkStyles';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';

export const pricingData = {
  packages: [
    { id: 'community', name: 'Cộng đồng (*)', price: 0, fee: 0, priceName: 'Miễn phí', priceYearly: '', desc: '' },
    { id: 'startup', name: 'Startup', price: 123000, fee: 1000, priceName: '123.000đ / tháng', priceYearly: 'Thanh toán 1 năm tặng 2 tháng', desc: '' },
    { id: 'small', name: 'DN Nhỏ', price: 234000, fee: 1000, priceName: '234.000đ / tháng', priceYearly: 'Thanh toán 1 năm tặng 2 tháng', desc: '' },
    { id: 'medium', name: 'DN Vừa', price: 456000, fee: 800, priceName: '456.000đ / tháng', priceYearly: 'Thanh toán 1 năm tặng 2 tháng', desc: '' },
    { id: 'large', name: 'DN Lớn', price: -1, priceName: 'LIÊN HỆ', priceYearly: '', desc: '' },
  ],
  subtimes: [
    {value: 1, bonus: 0, name: '01 tháng'},
    {value: 6, bonus: 0, name: '06 tháng'},
    {value: 12, bonus: 2, name: '12 tháng'}
  ],
  ckcoins: [
    {value: 100, bonus: 10, name: 'Mua 100 CKCoin'},
    {value: 500, bonus: 60, name: 'Mua 500 CKCoin'},
    {value: 1000, bonus: 150, name: 'Mua 1.000 CKCoin'},
    {value: 5000, bonus: 1000, name: 'Mua 5.000 CKCoin'},
    {value: 10000, bonus: 2500, name: 'Mua 10.000 CKCoin'},
    {value: 20000, bonus: 5500, name: 'Mua 20.000 CKCoin'},
  ],
  pricingFeatures: [
    { name: 'Quản lý thu chi tài khoản ngân hàng, cập nhật BĐSD', type: 'support', community: true, startup: true, small: true, medium: true, large: true },
    { name: 'Tạo và quản lý đơn hàng', type: 'support', community: false, startup: true, small: true, medium: true, large: true },
    { name: 'Xác nhận giao dịch tự động', type: 'support', community: false, startup: true, small: true, medium: true, large: true },
    { name: 'Báo cáo giao dịch, quản lý quỹ', type: 'support', community: true, startup: true, small: true, medium: true, large: true },
    { name: 'Tích hợp webhook, website bán hàng', type: 'support', community: false, startup: true, small: true, medium: true, large: true },
    { name: 'Cập nhật GoogleSheets, Telegram (**)', type: 'support', community: true, startup: true, small: true, medium: true, large: true },
    { name: 'Số lượng tài khoản ngân hàng', type: 'number', community: 1, startup: 2, small: 5, medium: 10, large: 20 },
    { name: 'Số lượng nhân viên', type: 'number', community: 2, startup: 2, small: 5, medium: 10, large: -1 },
    { name: 'Số lượt xử lý giao dịch miễn phí / tháng', type: 'number', community: '', startup: 60, small: 150, medium: 300, large: -1 },
    { name: 'Phí mua thêm lượt xử lý giao dịch (giá 1 CKCoin)', type: 'number', community: 'Miễn phí', startup: '1.000đ', small: '1.000đ', medium: '800đ', large: -1 },
  ]
}

export const getSubscriptionPackageName = (pkId) => {
  let ckpk = pricingData.packages.find((ckpk) => ckpk.id === pkId);

  if (ckpk) {
    return ckpk.name;
  }
  return '';
}

export default function PricingTable(props) {
  const tableStyles = ckTableStyles();
  const cellBorder = { borderLeft: 1, borderColor: 'grey.300' };

  const supportYes = <CheckOutlinedIcon color='success' />
  const supportNo = <ClearOutlinedIcon color='warning' />

  const getActionLabel = () => {
    if(props.action === 'register') {
      return 'Đăng ký';
    }

    return 'Chọn gói';
  }

  return (
    <TableContainer className={tableStyles.tableContainer}>

      <Table className={tableStyles.table} size="small" aria-label="pricing table" sx={{ borderTop: 1, borderRight: 1, borderColor: 'grey.300' }}>
        <TableBody>

          <TableRow key={"packages_title"} className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="left" width={'25%'} className={tableStyles.colHeader} sx={cellBorder}>
              Chức năng / Gói cước
            </TableCell>
            {
              pricingData.packages.map((ckpk) => (
                <TableCell key={ckpk.id} 
                  onClick={() => props.onChangeSubscription(ckpk.id)}
                  className={props.subscriptionPackage === ckpk.id ? tableStyles.colTxIdSelected : tableStyles.colTxId}
                  align="center" width={'12.5%'}  sx={cellBorder}>
                  <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <Box style={{ color: Colors.DARK_BLUE, fontWeight: '600' }}>{ckpk.name}</Box>
                    <Box style={{ color: Colors.RED, fontWeight: '500' }}>{ckpk.priceName}</Box>
                    <Box style={{ color: Colors.CK_VIOLET, padding: 5 }}>{ckpk.priceYearly}</Box>
                    <Box style={{ color: Colors.GRAY }}>{ckpk.desc}</Box>
                  </Box>
                </TableCell>
              ))
            }
          </TableRow>

          {
            pricingData.pricingFeatures.map((feature) => (
              (feature.type === 'support') ? (
                <TableRow key={feature.name} className={tableStyles.row} style={{ height: 32 }}>
                  <TableCell align="left" width={'25%'} className={tableStyles.colHeader} sx={cellBorder}>
                    <Box style={{ color: Colors.DARK_BLUE, fontWeight: '400' }}>{feature.name}</Box>
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.community ? supportYes : supportNo}
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.startup ? supportYes : supportNo}
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.small ? supportYes : supportNo}
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.medium ? supportYes : supportNo}
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.large ? supportYes : supportNo}
                  </TableCell>

                </TableRow>
              ) : (
                <TableRow key={feature.name} className={tableStyles.row} style={{ height: 32 }}>
                  <TableCell align="left" width={'25%'} className={tableStyles.colHeader} sx={cellBorder}>
                    <Box style={{ color: Colors.DARK_BLUE, fontWeight: '400' }}>{feature.name}</Box>
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.community}
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.startup}
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.small}
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.medium}
                  </TableCell>
                  <TableCell align="left" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>
                    {feature.large === -1 ? 'Liên hệ' : feature.large}
                  </TableCell>

                </TableRow>
              )

            ))
          }

          <TableRow key={"packages_select"} className={tableStyles.row} style={{ height: 48 }}>
            <TableCell key="select-pk" align="left" width={'25%'} className={tableStyles.colHeader} sx={cellBorder}>

            </TableCell>
            {
              pricingData.packages.map((ckpk) => (
                <TableCell key={ckpk.id} align="center" width={'12.5%'} className={tableStyles.colTxId} sx={cellBorder}>

                  {
                    !Config.isSuperAdmin(props.userObj.phone) && (ckpk.id === 'large' || ckpk.id === 'community') ? (
                      <Button variant='outlined' size='small' margin="dense"
                        disabled={(props.subscriptionPackage !== ckpk.id)}
                        color={props.subscriptionPackage === ckpk.id ? 'secondary' : 'primary'}
                        onClick={() => { }}>Liên hệ</Button>
                    ) : (
                      <Button variant='outlined' size='small' margin="dense"
                        disabled={!Config.isSuperAdmin(props.userObj.phone) && (props.subscriptionPackage === 'large' || props.subscriptionPackage === 'community')}
                        color={props.subscriptionPackage === ckpk.id ? 'secondary' : 'primary'}
                        onClick={() => props.onChangeSubscription(ckpk.id)}>{getActionLabel()}</Button>
                    )
                  }
                </TableCell>
              ))
            }
          </TableRow>

          <TableRow className={tableStyles.row} style={{ minHeight: 56}}>
            <TableCell colSpan={6} align="left" className={tableStyles.colHeader} sx={cellBorder}>
              <Box style={{ marginTop: 10, color: Colors.RED }}><strong>CHƯƠNG TRÌNH KHUYẾN MẠI TRONG NĂM 2023:</strong></Box>
              <Box>{'-->'} Tặng thêm 02 tháng sử dụng dịch vụ khi thanh toán 1 năm</Box>
              <Box>{'-->'} Tặng thêm 300 CKCoin cho khách hàng đăng ký mới</Box>
              <Box>{'-->'} Hỗ trợ tích hợp miễn phí cho khách hàng đăng ký mới trong Tháng 10/2023</Box>
              <Box style={{ marginTop: 10 }}><i>* Gói Cộng đồng phục vụ cho việc quản lý Quỹ từ thiện, Quỹ lớp, hoặc Quỹ của cộng đồng dân cư</i></Box>
              <Box style={{ marginBottom: 10 }}><i>** Gói Cộng đồng khi cập nhật lên GoogleSheets, Telegram luôn hiện số dư tài khoản ngân hàng</i></Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
}