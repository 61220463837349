import { Hidden } from "@mui/material";
import { Box } from "@mui/system";
import Colors from "../../ckcore/core/Colors";
import { animated, useSpring } from '@react-spring/web'


export default function IntroCkForWebsite(props) {

  const styles = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 500},
  });

  let wh = 96;
  let spacing = 16;
  return (
    <animated.div style={styles}>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 320 }}>

        <Hidden smDown>
          <Box style={{ fontSize: 20, fontWeight: '600', color: Colors.DARK_BLUE, marginBottom: spacing, marginTop: 20 }}>CHUYỂN KHOẢN THUẬN TIỆN</Box>
        </Hidden>
        <Hidden mdUp>
          <Box style={{ fontSize: 20, fontWeight: '600', color: Colors.DARK_BLUE, marginBottom: 8 }}>CK THUẬN TIỆN</Box>
        </Hidden>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }} style={{ marginBottom: spacing }}>
          <img width={wh} height={wh} style={{ borderColor: Colors.DARK_BLUE, borderWidth: 1, borderRadius: wh / 2 }}
            src="https://contuhoc.com/Themes/PowerHub/Content/images/logo-small.png" alt="CTH EDU" />
          <Box sx={{ border: 1, borderStyle: "dashed", borderColor: "green", borderRadius: 3 }} style={{ padding: 8, marginLeft: 8, minHeight: 72, textAlign: 'left' }}>
            <span><strong>CTH EDU: </strong><i>Giảm xác nhận đơn thủ công tới hơn 90%.</i></span>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <Box sx={{ border: 1, borderStyle: "dashed", borderColor: "green", borderRadius: 3 }} style={{ padding: 8, marginRight: 8, minHeight: 72, textAlign: 'left' }}>
            <span><strong>AAA VIETNAM: </strong><i>Thu chi qua Chuyenkhoan.com rất tiện lợi, chính xác.</i></span>
          </Box>
          <img width={wh} height={wh} style={{ backgroundColor: Colors.DARK_BLUE, padding: 12, borderRadius: wh / 2 }}
            src="https://w.ladicdn.com/s500x500/5b7f702780c18044b11e8122/aaa-int2000p-chu-trang-20220714093413.png" alt="AAA VIETNAM" />
        </Box>

        <Hidden smDown>
          <Box style={{ fontSize: 20, fontWeight: '600', color: Colors.DARK_BLUE, marginTop: spacing }}></Box>
        </Hidden>
        <Box style={{ fontSize: 20, fontWeight: '600', color: Colors.DARK_BLUE, marginTop: spacing }}>XÁC NHẬN TỰ ĐỘNG</Box>
      </Box>
    </animated.div>
  );
}