import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "../../ckcore/core/Colors";


export default function Contacts(props) {

  const classes = useStyle();

  const showContactForm = (props.showContactForm) ? props.showContactForm : false;

  // set contact phone by hours
  const currentHour = (new Date()).getHours();
  const contactPhone = (currentHour >= 4 && currentHour <= 18) ? "0906246196" : "0969781318";

  return (
    <div>

      {showContactForm &&
        <div id="contact">
          <div className="row feedback">
            <div className="container padding20">

              {/*
              <div className="col-md-12">
              <h2 className="titgth section-title" style={{fontWeight : 'bold'}}>HỎI ĐÁP</h2>
              </div>
            */}
              <div className="col-md-12">
                <div className="row bxtitgth">
                  <h2 className="titgth section-title" style={{ fontWeight: 'bold' }}>LIÊN HỆ</h2>
                  <div className="address clearfix infoContact">

                    <span>
                      <span>
                        <i className="fa fa-map-marker"></i> Địa chỉ:&nbsp;
                      </span>

                      P806 C5, Nguyễn Cơ Thạch, Nam Từ Liêm, Hà Nội

                    </span>
                  </div>

                  <div className="hotline infoContact" >
                    <span className="col-md-4 col-xs-12" style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      <span>
                        <i className="fa fa-phone"></i> Hotline:&nbsp;
                      </span>
                      <span style={{ color: Colors.CK_VIOLET }}>
                        {contactPhone}
                      </span>
                    </span>

                    <span className="col-md-4 col-xs-12" style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      <span>
                        <i className="fa fa-envelope-o"></i> Telegram:&nbsp;
                      </span>
                      <span style={{ color: Colors.CK_VIOLET }}>
                        <a target="_blank" rel="noreferrer" href="https://telegram.me/chuyenkhoan"> @chuyenkhoan</a>
                      </span>
                    </span>


                    <span className="col-md-4 col-xs-12" style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      <span>
                        <i className="fa fa-envelope-o"></i> Email:&nbsp;
                      </span>
                      <span style={{ color: Colors.CK_VIOLET }}>
                        cskh@chuyenkhoan.com
                      </span>
                    </span>

                  </div>
                </div>
              </div>

              {/*
              <div className="col-md-12">
                <a href="https://www.dmca.com/Protection/Status.aspx?id=6891a4d3-96d3-4b2f-a353-d60c8facae17&refurl=https%3a%2f%2fchuyenkhoan.com%2f&rlo=true"
                  title="DMCA.com Protection Status" class="dmca-badge">
                  <img src="https://images.dmca.com/Badges/DMCA_logo-std-btn120w.png?ID=6891a4d3-96d3-4b2f-a353-d60c8facae17" alt="DMCA.com Protection Status" />
                </a>                
              </div>                
              */}

            </div>
          </div>
        </div>
      }

      <div id="footer">
        <div className="container text-center">
          <p><strong>CTCP GIẢI PHÁP THANH TOÁN CHUYỂN KHOẢN</strong></p>
          <p>
            ĐKKD: 0104103433 do Sở Kế hoạch &amp; Đầu tư TP Hà Nội cấp lần đầu
            ngày 13/08/2009
          </p>
          <p>{'©CKPay 2009-'}{new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  phone: {
    marginRight: "100px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  errShow: {
    display: "block",
    backgroundColor: "#eb5967",
    color: "#fff",
    padding: "5px",
    marginTop: "9px",
    borderRadius: "2px",
  },
  errHide: {
    display: "none",
    backgroundColor: "#eb5967",
    color: "#fff",
    padding: "5px",
    marginTop: "9px",
    borderRadius: "2px",
  },
}));
