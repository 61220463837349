import React from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import PricingTable from "../../dashboard/subscription/PricingTable";
import { Container } from "@mui/material";

const theme = createTheme();

export default function Pricing(props) {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Container id="pricing" style={{ paddingBottom: 40, paddingTop: 8 }}>
          
          <h3 style={{ fontSize: 26, color: "#37517e", textAlign: "center", marginBottom: 20 }}>BẢNG GIÁ</h3>
          
          <PricingTable
            userObj={{}}
            subscriptionPackage={''}
            action={'register'}
            onChangeSubscription={props.onChangeSubscription} />

        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
