import React from 'react';
import Colors from '../../ckcore/core/Colors';

export default function Fqa(props) {
    /*
    var(--dark)
    */
    // const classes = useStyle();
    return (
        <div id="fqa">
            <div className="container" style={{ paddingBottom: 20, paddingTop: 8 }}>
                <h3 style={{ fontSize: 26, color: "#37517e", textAlign: "center" }}>HỎI ĐÁP</h3>
                <div className="row">
                    <div key="1" className="col-xs-12">
                        <h3 style={{ color: Colors.DARK_BLUE, fontSize: 22, fontWeight: 700 }}>
                            Chuyenkhoan.com để làm gì?
                        </h3>
                        <p style={{ color: "#001f3e" }}>
                            Chuyenkhoan.com giúp người mua thanh toán chuyển khoản thuận tiện, chính xác, và tự động xác nhận giao dịch cho người bán.
                        </p>
                    </div>
                    <div key="2" className="col-xs-12">
                        <h3 style={{ color: Colors.DARK_BLUE, fontSize: 22, fontWeight: 700 }}>
                            Chuyenkhoan.com có phải là trung gian thanh toán không?
                        </h3>
                        <p style={{ color: "#001f3e" }}>
                            <span>Chuyenkhoan.com có đầy đủ chức năng của một Cổng thanh toán nhưng không phải là Trung gian thanh toán </span>
                            <span>vì tiền được chuyển trực tiếp từ tài khoản ngân hàng của người mua tới tài khoản ngân hàng của người bán. </span>
                            <span>Chúng tôi là công ty công nghệ hỗ trợ việc thanh toán chuyển khoản thuận tiện, chính xác; <strong>giúp người bán giảm chi phí nhân sự, tăng trải nghiệm khách hàng, và quay vòng vốn nhanh nhất.</strong></span>
                        </p>
                    </div>
                    <div key="3" className="col-xs-12">
                        <h3 style={{ color: Colors.DARK_BLUE, fontSize: 22, fontWeight: 700 }}>
                            Phí dịch vụ khi sử dụng Chuyenkhoan.com?
                        </h3>
                        <p style={{ color: "#001f3e" }}>
                            <span>Phí xử lý giao dịch của Chuyenkhoan.com không quá 1.000 VNĐ / 1 giao dịch (tuỳ theo gói dịch vụ bạn đăng ký), và không phụ thuộc vào giá trị giao dịch. </span>
                            <span>Nếu bạn cần làm chủ hoàn toàn quy trình thanh toán, thì bạn cần mua thêm thiết bị CKBox do CKPay sản xuất (vui lòng liên hệ với chúng tôi theo hotline ở cuối trang). </span>
                        </p>
                    </div>
                    <div key="4" className="col-xs-12">
                        <h3 style={{ color: Colors.DARK_BLUE, fontSize: 22, fontWeight: 700 }}>
                            Tích hợp dịch vụ Thanh toán chuyển khoản có khó không?
                        </h3>
                        <p style={{ color: "#001f3e" }}>
                            <span>Việc tích hợp dịch vụ Thanh toán của Chuyenkhoan.com khá dễ dàng khi so sánh với các Cổng thanh toán hiện tại ở Việt Nam.  </span>
                            <span>Một số ngân hàng cho phép bạn tự cấu hình biến động số dư tài khoản qua SMS, bạn có thể tích hợp thanh toán cho website của mình trong vòng nửa ngày. </span>
                            <span>Để nhúng Chuyển Khoản VietQR vào website, bạn chỉ cần cấu hình (không cần biết code). </span>
                            <span>Để tích hợp xác nhận thanh toán đơn hàng tự động thì bạn cần có kỹ thuật viên hỗ trợ. </span>
                        </p>
                    </div>
                    <div key="5" className="col-xs-12">
                        <h3 style={{ color: Colors.DARK_BLUE, fontSize: 22, fontWeight: 700 }}>
                            Chuyenkhoan.com có gì hơn các phương thức thanh toán khác?
                        </h3>
                        <p style={{ color: "#001f3e" }}>
                            <span>Chuyển khoản không phải là cách thanh toán mới, mà được thường xuyên sử dụng và ngày càng phổ biến nhờ ngân hàng miễn phí giao dịch. </span>
                            <span>Chuyenkhoan.com giúp hạn chế nhược điểm và nâng tầm chuyển khoản lên thành một phương thức thanh toán tự động với các ưu điểm sau: </span>                            
                            <br/><br/><span> - <strong>Phổ biến nhất</strong>: ai dần cũng có tài khoản ngân hàng</span>
                            <br/><span> - <strong>Linh hoạt nhất</strong>: hỗ trợ nhiều tài khoản ngân hàng, cả tài khoản Công ty và tài khoản cá nhân</span>
                            <br/><span> - <strong>Tiền về nhanh nhất</strong>: tiền không bị giữ lại ở bên trung gian thanh toán</span>
                            <br/><span> - <strong>Dễ sử dụng nhất</strong>: khách hàng không cần tải app khác, không cần liên kết, chỉ quẹt mã VietQR</span>
                            <br/><span> - <strong>Chi phí thấp nhất</strong>: đây là cam kết của Chuyenkhoan.com</span>
                            <br/><br/><span>Nhược điểm của hình thức thanh toán chuyển khoản là khi khách hàng không quét QR, tự nhập nội dung sai dẫn đến không xác nhận được thanh toán tự động. </span>                            
                            <span>Người bán sẽ phải xử lý giao dịch như vẫn thực hiện từ trước tới nay.. </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
