import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Colors from '../../ckcore/core/Colors';
import CKQRBox from '../../checkout/components/CKQRBox';
import { getBankInfo, getBankSvgIcon, getDefaultBanks } from '../../ckcore/core/CkBank';
import CKQRGen from '../../ckcore/core/CKQRGen';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';
import { Hidden, Paper } from '@mui/material';
import Config from '../../ckcore/core/Config';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import { isValidAccountNumber, isValidAmount, isValidNameUppercaseAscii, isValidTransferContent } from '../../ckcore/core/Validator';
import { makeStyles } from '@mui/styles';

const MAX_ACCOUNTS = 3;

export default function WebsiteCKQRDialog(props) {

    const classes = useStyle();

    const defaultAccounts = (props.defaultAccounts) ? props.defaultAccounts : [
        {
            bankName: 'Vietcombank',
            accountNumber: '',
            accountName: '',
            accountBranch: '',
            ckqr: ''
        }
    ];

    const [ckqrInfo, setCkqrInfo] = useState({
        title: 'THÔNG TIN CHUYỂN KHOẢN ',
        subtitle: '',
        accounts: defaultAccounts,
        amount: 0,
        message: '',
        guide: '',
        labels: {
            accountNumber: 'Số tài khoản',
            amount: 'Số tiền (VNĐ)',
            message: 'Nội dung',
            amountError: 'Số tiền không hợp lệ',
            messageError: 'Nội dung chuyển khoản không hợp lệ',
            copyNotify: 'Đã copy',
            messageGuide: 'Nội dung chuyển khoản:'
        }
    });

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [editing, setEditing] = useState(false);
    const [completed, setCompleted] = useState(false);


    const handleAddBank = () => {
        var len = ckqrInfo.accounts.length;
        if (len < MAX_ACCOUNTS) {

            // set default next bank name
            var nextBankName = "";
            if (len === 1) {
                nextBankName = "Vietinbank";
            } else if (len === 2) {
                nextBankName = "BIDV";
            } else if (len === 3) {
                nextBankName = "Agribank";
            } else if (len === 4) {
                nextBankName = "Techcombank";
            }

            var accounts = ckqrInfo.accounts;

            // add more
            accounts.push({
                bankName: nextBankName,
                accountNumber: '',
                accountName: '',
                accountBranch: ''
            });

            setCkqrInfo({ ...ckqrInfo, accounts: accounts });

        } else {
            setErrorMessage("Hệ thống chỉ hỗ trợ tối đa 5 tài khoản ngân hàng.");
        }
    }

    const handleRemoveBank = () => {
        var len = ckqrInfo.accounts.length;
        if (len > 1) {
            var accounts = ckqrInfo.accounts;
            accounts.splice(len - 1, 1);
            setCkqrInfo({ ...ckqrInfo, accounts: accounts });
        }
    }

    const handleSelectBank = (index, name, value) => {

        var accounts = ckqrInfo.accounts;
        var bankObj = accounts[index];
        bankObj = { ...bankObj, [name]: value };
        accounts[index] = bankObj;

        setCkqrInfo({ ...ckqrInfo, accounts: accounts });

        setLoading(false);
        setEditing(true);
        setErrorMessage("");
    };

    const handleChangeBank = (index, name) => event => {

        var accounts = ckqrInfo.accounts;
        var bankObj = accounts[index];
        if (name === 'accountNumber') {
            bankObj = { ...bankObj, [name]: event.target.value.replace(/ /g, "").trim() };
        } else {
            bankObj = { ...bankObj, [name]: event.target.value };
        }
        accounts[index] = bankObj;

        setCkqrInfo({ ...ckqrInfo, accounts: accounts });
        setLoading(false);
        setEditing(true);
        setErrorMessage("");
    };

    const handleCompleted = (value) => {
        setLoading(false);
        setEditing(false);

        if (value) {

            setErrorMessage("");

            var errorMsg = "";
            ckqrInfo.accounts.map((bankObj, index) => {

                // get bank info
                const bankInfo = getBankInfo(Config.getAvailableBanks(), bankObj.bankName);
                if (!(bankInfo)) {
                    errorMsg = "Tên ngân hàng không hợp lệ: " + bankObj.bankName;
                    return false;
                }

                // validate account
                if (!isValidAccountNumber(bankObj.accountNumber, bankInfo.accountRegex)) {
                    errorMsg = "Số tài khoản " + bankObj.bankName + " không hợp lệ: " + bankObj.accountNumber;
                    return false;
                }

                // validate name (allow multiple line)
                if (!isValidNameUppercaseAscii(bankObj.accountName.replace(/\n/g, " "))) {
                    errorMsg = "Tên tài khoản " + bankObj.bankName + " không hợp lệ (cần viết hoa không dấu)";
                    return false;
                }
            });

            console.log("Handle complete: " + errorMsg);
            if (errorMsg) {
                setErrorMessage(errorMsg);
            } else {
                setCompleted(true);
            }

        } else {
            setCompleted(false);
            setEditing(true);
        }
    };


    // User click close
    const handleClose = () => {
        props.closeCallback();
    };

    // Load partner data
    const loadCKQRInfo = async () => {

        // refresh message
        const ckqr = {
            txType: 'load',
            partner_id: 10001,
            timestamp: new Date().getTime()
        };

        // Call server
        try {
            setLoading(true);
            const rawResponse = await fetch(Config.getServerURL() + "qr=" + JSON.stringify(ckqr));
            const response = await rawResponse.json();

            if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
                // update available banks
                Config.setAvailableBanks(response.banks);
                setLoading(false);
            }
        } catch (error) {
            console.log("Error... " + error);
        }
    };    

    useEffect(() => {
        loadCKQRInfo();
    }, []);

    /**
     * Build CkConfig string
     */
    const getCkConfig = () => {

        const accounts = [];
        ckqrInfo.accounts.map((account) => {
            accounts.push({
                bankName: account.bankName,
                accountNumber: account.accountNumber,
                accountName: account.accountName,
            });
        });

        return JSON.stringify(accounts);
    }

    // Add bank dialog
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Dialog open={props.open} fullWidth={true} maxWidth="md" onClose={handleClose} aria-labelledby="CKQR-Website">
                    <DialogContent dividers className={classes.dialogContent} >

                        {
                            (completed === false) &&
                            <Grid container spacing={1} style={{ paddingTop: 20, paddingBottom: 8 }}>
                                <Grid key="title" item xs={12}>
                                    <Box style={{ fontSize: 22, fontWeight: 700, color: Colors.DARK_BLUE, textAlign: "center" }}>THIẾT KẾ CHUYỂN KHOẢN VIETQR CHO WEBSITE</Box>
                                </Grid>
                                <Grid key="guide" item xs={12}>
                                    <Box p={1}>
                                        {getMessageBox(errorMessage, "", "Mời bạn nhập thông tin tài khoản ngân hàng nhận, sau đó bấm Hoàn thành")}
                                    </Box>
                                </Grid>

                                {ckqrInfo.accounts.map((bankObj, index) => (
                                    <>
                                        <Grid key={"bank_name_" + index} item md={3} xs={12}>
                                            <Autocomplete name="select-bank" size="small" disableClearable
                                                options={Config.getAvailableBanks()}
                                                getOptionLabel={(bank) => bank.name}
                                                isOptionEqualToValue={(option, value) => option.name.toUpperCase() === bankObj.bankName.toUpperCase()}
                                                value={{ name: bankObj.bankName }}
                                                onChange={(event, bank) => handleSelectBank(index, 'bankName', (bank) ? bank.name : "")}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" margin="dense" fullWidth style={{ backgroundColor: 'white' }}
                                                        label="Chọn ngân hàng"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <IconButton size="small" >
                                                                        <SvgIcon component={getBankSvgIcon(bankObj.bankName)} viewBox="0 0 48 48" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }} />
                                                )}
                                                disabled={false}
                                            />
                                        </Grid>
                                        <Grid key={"account_number_" + index} item md={3} xs={12}>
                                            <TextField name="account-number" size="small" variant="outlined" fullWidth style={{ marginTop: 8, backgroundColor: 'white' }}
                                                label="Số tài khoản *"
                                                type="tel"
                                                value={bankObj.accountNumber.split(" ").join("")}
                                                inputProps={{ min: 0, maxLength: 18, style: { textAlign: 'left', fontSize: 14, fontWeight: 'bold' } }}
                                                onChange={handleChangeBank(index, 'accountNumber')}
                                            />
                                        </Grid>
                                        <Grid key={"account_name_" + index} item  md={5} xs={9}>
                                            <TextField name="account-name" size="small" variant="outlined" fullWidth style={{ marginTop: 8, backgroundColor: 'white' }}
                                                label="TÊN TÀI KHOẢN *"
                                                type="text"
                                                value={bankObj.accountName}
                                                inputProps={{ min: 0, style: { textAlign: 'left', textTransform: "uppercase", fontSize: 14 } }}
                                                onChange={handleChangeBank(index, 'accountName')}
                                            />
                                        </Grid>
                                        <Grid key={"add_" + index} item  md={1} xs={3}>

                                            {
                                                (index === 0) &&
                                                <Button disabled={loading || completed || ckqrInfo.accounts.length === MAX_ACCOUNTS}
                                                    style={{ marginTop: 8, backgroundColor: 'white' }}
                                                    variant="outlined"
                                                    onClick={() => handleAddBank()}>+ TK</Button>
                                            }
                                            {
                                                (index > 0) &&
                                                <Button disabled={loading || completed}
                                                    style={{ marginTop: 8, backgroundColor: 'white' }}
                                                    variant="outlined"
                                                    color={"secondary"}
                                                    onClick={() => handleRemoveBank()}>- TK</Button>
                                            }
                                        </Grid>

                                    </>
                                ))}

                                <Grid key="note" item xs={12}>
                                    <Box style={{ fontSize: 16, fontWeight: 700, color: Colors.DARK_GREEN, textAlign: "center", padding: 8 }}>Xem trước nội dung hiện lên website:</Box>
                                </Grid>
                            </Grid>
                        }



                        <Paper>
                            <CKQRBox
                                partnerId={10001}
                                ckqrInfo={ckqrInfo}
                            />
                        </Paper>

                        {
                            (completed === true) &&
                            <Grid container spacing={1} style={{ paddingTop: 32}}>
                                <Grid item xs={12}>
                                    <Box style={{ fontSize: 22, fontWeight: 700, color: Colors.DARK_BLUE, textAlign: "center" }}>ĐƯA CHUYỂN KHOẢN VIETQR LÊN WEBSITE</Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box p={1}>
                                        <span>Mời bạn copy đoạn mã sau và nhúng vào thẻ &lt;head&gt; của trang cần hiện Chuyển Khoản VietQR. Xem hướng dẫn chi tiết tại </span>
                                        <Link href="https://github.com/chuyenkhoan/chuyenkhoan-vietqr" target="_blank">https://github.com/chuyenkhoan</Link>.
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <pre style={{color: '#008080', fontSize: 14, fontWeight: 600, backgroundColor: 'white', padding: 8}}><code>
                                        &lt;script async src="https://chuyenkhoan.com/web/chuyenkhoan-vietqr-1.1.js"&gt;&lt;/script&gt;
                                        <br />
                                        &lt;script&gt;
                                            function ckConfig() &#123;
                                                return &#123;
                                                    ckicon: true,
                                                    accounts: {getCkConfig()}
                                                &#125;
                                            &#125;
                                        &lt;/script&gt;
                                    </code></pre>
                                </Grid>                                
                            </Grid>
                        }

                    </DialogContent>
                    <DialogActions>
                        <FormControlLabel
                            style={{ marginTop: 5 }}
                            control={
                                <Checkbox
                                    checked={completed}
                                    onChange={e => handleCompleted(e.target.checked)}
                                    name="Website CKQR"
                                    color="primary"
                                />
                            }
                            label="Hoàn thành"
                        />
                        <Hidden mdDown>
                            <Button disabled={!completed} color="primary">
                                Đưa lên website
                            </Button>
                        </Hidden>
                        <Button color="primary" onClick={() => handleClose()}>
                            Trở lại
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}


const theme = createTheme({
    palette: {
        primary: {
            main: Colors.BG_BLUE,
        },
        secondary: {
            main: Colors.ORANGE,
            contrastText: "#fff" //button text white instead of black
        },
        text: {
            disabled: Colors.DARK_BLUE
        }
    },
});

const useStyle = makeStyles(theme => ({    
    dialogContent: {
        background: '#f6f6f6',
        padding: 20,
        paddingLeft: 40,
        paddingRight: 40,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    }

}));