/*
 Bank functions
  Relative path: '../images/icons/vietcombank_icon.svg'
  Fix path: src/modules/ckcore/images/icons
*/

import { ReactComponent as vietcombank_icon } from '../images/icons/vietcombank_icon.svg';
import { ReactComponent as vietinbank_icon } from '../images/icons/vietinbank_icon.svg';
import { ReactComponent as bidv_icon } from '../images/icons/bidv_icon.svg';
import { ReactComponent as agribank_icon } from '../images/icons/agribank_icon.svg';
import { ReactComponent as mbbank_icon } from '../images/icons/mbbank_icon.svg';
import { ReactComponent as techcombank_icon } from '../images/icons/techcombank_icon.svg';
import { ReactComponent as vpbank_icon } from '../images/icons/vpbank_icon.svg';
import { ReactComponent as sacombank_icon } from '../images/icons/sacombank_icon.svg';
import { ReactComponent as msb_icon } from '../images/icons/msb_icon.svg';
import { ReactComponent as eximbank_icon } from '../images/icons/eximbank_icon.svg';
import { ReactComponent as shb_icon } from '../images/icons/shb_icon.svg';
import { ReactComponent as tpbank_icon } from '../images/icons/tpbank_icon.svg';
import { ReactComponent as acb_icon } from '../images/icons/acb_icon.svg';
import { ReactComponent as vib_icon } from '../images/icons/vib_icon.svg';
import { ReactComponent as hdbank_icon } from '../images/icons/hdbank_icon.svg';
import { ReactComponent as abbank_icon } from '../images/icons/abbank_icon.svg';
import { ReactComponent as bacabank_icon } from '../images/icons/bacabank_icon.svg';
import { ReactComponent as seabank_icon } from '../images/icons/seabank_icon.svg';
import { ReactComponent as pvcombank_icon } from '../images/icons/pvcombank_icon.svg';

import { ReactComponent as notfound_icon } from '../images/icons/notfound_icon.svg';

/**
 * Get default bank list with short name and code
 * @returns Default bank list
 */
function getDefaultBanks() {

  return [
    {
      "name": "Vietcombank",
      "bankCodeNumber": 970436,
    },

    {
      "name": "Vietinbank",
      "bankCodeNumber": 970415,
    },

    {
      "name": "BIDV",
      "bankCodeNumber": 970418,

    },

    {
      "name": "Agribank",
      "bankCodeNumber": 970405,

    },

    {
      "name": "Techcombank",
      "bankCodeNumber": 970407,

    },
    {
      "name": "VPBank",
      "bankCodeNumber": 970432,

    },
    {
      "name": "MBBank",
      "bankCodeNumber": 970422,
    },
    {
      "name": "ACB",
      "bankCodeNumber": 970416,

    },
    {
      "name": "Sacombank",
      "bankCodeNumber": 970403,
    },
    {
      "name": "MSB",
      "bankCodeNumber": 970426,
    },
    {
      "name": "TPBank",
      "bankCodeNumber": 970423,
    },
    {
      "name": "SHB",
      "bankCodeNumber": 970443,
    },
    {
      "name": "Eximbank",
      "bankCodeNumber": 970431,
    },
    {
      "name": "SeaBank",
      "bankCodeNumber": 970440,
    },
    {
      "name": "VIB",
      "bankCodeNumber": 970441,
    },
    {
      "name": "PVComBank",
      "bankCodeNumber": 970412,
    },
    {
      "name": "ABBank",
      "bankCodeNumber": 970425,
    },
    {
      "name": "BacABank",
      "bankCodeNumber": 970409,
    },
    {
      "name": "BaoVietBank",
      "bankCodeNumber": 970438,
    },
    {
      "name": "DongA",
      "bankCodeNumber": 970406,
    },
    {
      "name": "LienVietPostBank",
      "bankCodeNumber": 970449,
    },
    {
      "name": "GPBank",
      "bankCodeNumber": 970408,
    },
    {
      "name": "KienLongBank",
      "bankCodeNumber": 970452,
    },
    {
      "name": "NamABank",
      "bankCodeNumber": 970428,
    },
    {
      "name": "HDBank",
      "bankCodeNumber": 970437,
    },

    {
      "name": "OceanBank",
      "bankCodeNumber": 970414,
    },


    {
      "name": "PGBank",
      "bankCodeNumber": 970430,
    },
    {
      "name": "PublicBank",
      "bankCodeNumber": 970439,
    },
    {
      "name": "SaigonBank",
      "bankCodeNumber": 970400,
    },
    {
      "name": "SCB",
      "bankCodeNumber": 970429,
    },
    {
      "name": "VietABank",
      "bankCodeNumber": 970427,
    },
    {
      "name": "VRB",
      "bankCodeNumber": 970421,
    },
    {
      "name": "UOB",
      "bankCodeNumber": 0,
    },
    {
      "name": "IndoVina",
      "bankCodeNumber": 970434,
    },
    {
      "name": "NCB",
      "bankCodeNumber": 970419,
    }];
}

/**
 * Get Svg Icon for Vietnam's banks
 */
function getBankSvgIcon(bankName) {

  const bankIconName = bankName.toLowerCase() + '_icon';
  switch (bankIconName) {
    case 'vietcombank_icon':
      return vietcombank_icon;
    case 'vietinbank_icon':
      return vietinbank_icon;
    case 'bidv_icon':
      return bidv_icon;
    case 'agribank_icon':
      return agribank_icon;
    case 'mbbank_icon':
      return mbbank_icon;
    case 'techcombank_icon':
      return techcombank_icon;
    case 'vpbank_icon':
      return vpbank_icon;
    case 'sacombank_icon':
      return sacombank_icon;
    case 'msb_icon':
      return msb_icon;
    case 'eximbank_icon':
      return eximbank_icon;
    case 'shb_icon':
      return shb_icon;
    case 'tpbank_icon':
      return tpbank_icon;
    case 'acb_icon':
      return acb_icon;
    case 'vib_icon':
      return vib_icon;
    case 'hdbank_icon':
      return hdbank_icon;
    case 'seabank_icon':
      return seabank_icon;
    case 'bacabank_icon':
      return bacabank_icon;
    case 'abbank_icon':
      return abbank_icon;
    case 'pvcombank_icon':
      return pvcombank_icon;
      
    default:
      return notfound_icon;
  }

}

function getCKBankIcon(bankName) {
  const bankIconName = bankName.toLowerCase() + "_icon@2x.png";
  return "https://chuyenkhoan.com/web/banks/" + bankIconName;
}

function getCKBankLogo(bankName) {
  const bankLogoName = "logo_" + bankName.toLowerCase() + ".png";
  return "https://chuyenkhoan.com/web/banks/" + bankLogoName;
}

function getCKBankGuideImage(bankName) {
  const bankGuideName = bankName.toLowerCase() + "_guide.png";
  return "https://chuyenkhoan.com/web/bank_guides/" + bankGuideName;
}

function getBankInfo(banks, bankName) {
  return banks.find((bank) => bank.name.toLowerCase() === bankName.toLowerCase());
}

function getBankInfoByCode(banks, bankCodeNumber) {
  return banks.find((bank) => bank.bankCodeNumber === bankCodeNumber);
}

function getBankAccount(bankAccounts, accountNumber) {
  return bankAccounts.find(
    (account) => account.accountNumber === accountNumber
  );
}

function getBankAccountByName(bankAccounts, bankName) {
  return bankAccounts.find((account) => account.bankName === bankName);
}

function getMaxContentLength(banks, destBankName, txBankName) {
  var maxContentLength = 111;

  if (!banks) {
    return maxContentLength;
  }

  // set max length to dest
  if (destBankName) {
    const destBank = getBankInfo(banks, destBankName);
    if (destBank && destBank.maxContentLength) {
      maxContentLength = destBank.maxContentLength;
    } else {
      console.log(
        "CKBox - Not found max content length: " +
        destBankName +
        " bankInfo: " +
        destBank
      );
    }
  }

  // set max length to min(dest, src)
  if (txBankName) {
    const txBank = getBankInfo(banks, txBankName);
    if (
      txBank &&
      txBank.maxContentLength &&
      maxContentLength > txBank.maxContentLength
    ) {
      maxContentLength = txBank.maxContentLength;
    }
  }

  return maxContentLength;
}

function getRemainContentLength(remainContentLength) {
  if (remainContentLength < 111) {
    return " (" + remainContentLength + ")";
  } else {
    return "";
  }
}

function isValidBankAccount(banks, bankName, accountNumber) {
  if (!bankName || !accountNumber) {
    return false;
  }

  // check bank name
  const bank = getBankInfo(banks, bankName);
  if (!bank) {
    console.log("Not found bank name: " + bankName);
    return false;
  }

  const value = accountNumber.match(/^[0-9]{7,16}$/);

  if (value) {
    return true;
  }

  return false;
}

// do export functions
export {
  getDefaultBanks,
  getCKBankIcon,
  getBankSvgIcon,
  getCKBankLogo,
  getCKBankGuideImage,
  getBankInfo,
  getBankInfoByCode,
  getBankAccount,
  getBankAccountByName,
  getMaxContentLength,
  getRemainContentLength,
  isValidBankAccount,
};
