import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import IntroPayment from '../../payment/components/IntroPayment';
import OrderData from './OrderData';
import '../../payment/components/ckpayment.css';
import TxStatus from '../../ckcore/core/TxStatuses';
import ValUtils from '../../ckcore/core/ValUtils';
import { useInterval } from '../../ckcore/core/CkUtils';
import Colors from '../../ckcore/core/Colors';
import { Box, Button, Grid, Hidden, StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import CountUp from 'react-countup';

import PrintQRDialog from '../ckdesign/PrintQRDialog';
import WebsiteCKQRDialog from '../ckdesign/WebsiteCKQRDialog';
import IntroCkForWebsite from '../intro/IntroCkForWebsite';
import IntroCkForShop from '../intro/IntroCkForShop';
import IntroCkForCommunity from '../intro/IntroCkForCommunity';
import EasyRegisterDialog from '../register/EasyRegisterDialog';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Config from '../../ckcore/core/Config';

export default function Main(props) {
    const classes = useStyle();
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    const [ckTotals, setCkTotals] = useState({
        currentTxCount: 0,
        currentTxAmount: 0,
        txCountStart: 0,
        txAmountStart: 0
    });

    const [orderData, setOrderData] = useState({
        order: OrderData.getOrderObj(),
        bankInfo: OrderData.getBankInfo(),
        paymentStatus: 10,
        supportBanks: OrderData.getSupportBanks(),
        partnerObj: OrderData.getPartnerObj()
    });

    const [introStates, setIntroStates] = useState({ intro: 'ckpay', index: 0, timestamp: 0 });

   
    const [showWebsiteCKQRDialog, setShowWebsiteCKQRDialog] = useState(false);
    const openWebsiteCKQRDialog = () => {
        setShowWebsiteCKQRDialog(true);
    }
    const closeWebsiteCKQRDialog = () => {
        setShowWebsiteCKQRDialog(false);
    }

    const doNothing = () => {

    }

    const openRegister = () => {
        // window.location.href = "https://dashboard.chuyenkhoan.com/#register";
        props.onRequestRegister('startup');
    }

    /**
     * Load CK statistic
     */
    const loadCkStatistics = async () => {

        // load ck statistics
        const cka = {
            txType: "stats",
            partnerId: 10001,
            orderId: orderData.order.order_id,
            timestamp: new Date().getTime(),
        };

        // Call server
        try {

            const rawResponse = await fetch("https://dashboard.chuyenkhoan.com/dashboard?ck=" + JSON.stringify(cka));
            const response = await rawResponse.json();

            if (response.statusId === TxStatus.HTTP_SUCCESS || response.statusId === TxStatus.SUCCESS) {

                if (response.totalTxAmount) {

                    let txCountStart = ckTotals.currentTxCount;
                    let txAmountStart = ckTotals.currentTxAmount;
                    let currentTxCount = response.totalTxCount;
                    let currentTxAmount = response.totalTxAmount;

                    setCkTotals({ txCountStart: txCountStart, txAmountStart: txAmountStart, currentTxCount: currentTxCount, currentTxAmount: currentTxAmount });
                }

                if (response.orderData) {
                    setOrderData(response.orderData);
                }

            } else {
                console.log("Request preorder error with statuses: " + response.statusId);
            }

        } catch (error) {
            console.log("Error call preoder " + error);
        }
    }

    // Request statistics on load
    useEffect(() => {
        loadCkStatistics();
    }, []);

    // Use interval to refresh order status
    useInterval(() => {

        // Fetch order status
        loadCkStatistics();

        // auto intro
        let timestamp = new Date().getTime();
        if (timestamp - introStates.timestamp > 15000) {
            nextIntro();
        }
    }, 5000);

    let intro_list = ['ckpay', 'website', 'shop', 'community'];
    const setIntro = (name) => {

        var selectedIndex = intro_list.findIndex((intro) => intro === name);
        if (selectedIndex < 0) {
            selectedIndex = 0;
        }

        setIntroStates({ ...introStates, intro: intro_list[selectedIndex], index: selectedIndex, timestamp: new Date().getTime() });
    }

    const nextIntro = () => {
        var currentIndex = introStates.index + 1;
        if (currentIndex < 0 || currentIndex >= intro_list.length) {
            currentIndex = 0;
        }
        setIntroStates({ ...introStates, intro: intro_list[currentIndex], index: currentIndex, timestamp: 0 });
    }

    const getIntroBox = () => {
        switch (introStates.intro) {
            case 'website':
                return <IntroCkForWebsite />;
            case 'shop':
                return <IntroCkForShop />;
            case 'community':
                return <IntroCkForCommunity />;
            default:
                return <IntroPayment
                    order={orderData.order}
                    bankInfo={orderData.bankInfo}
                    paymentStatus={orderData.paymentStatus}
                    supportBanks={orderData.supportBanks}
                    partnerObj={orderData.partnerObj}
                    requestShowWarning={doNothing} bankTransferGuide={doNothing}
                    changeBenBankAccount={doNothing} countDown={""} remainTime={5} />
        }

    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <header id="header">
                    <div className="intro">
                        <div className="overlay">
                            <div className={screenHeight > 786 ? classes.container : classes.containerMd}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3} style={{ paddingTop: 20, paddingBottom: 20 }}>
                                    <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>

                                        <Box className={classes.introCKPay}>
                                            <div style={{ marginBottom: 8 }}>
                                                <h1>
                                                    <span>GIẢI PHÁP THANH TOÁN</span>
                                                </h1>
                                                <h1>
                                                    <span>CHUYỂN KHOẢN</span>
                                                </h1>
                                                <p><i>chuyên nghiệp cho</i></p>
                                            </div>
                                            <Box style={{ marginBottom: 16 }}
                                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button variant="outlined"
                                                    color={introStates.intro === 'website' ? 'secondary' : 'primary'}
                                                    className={introStates.intro === 'website' ? classes.buttonSelected : classes.buttonNormal}
                                                    onClick={() => setIntro('website')}>
                                                    WEB &amp; APP
                                                </Button>
                                                <Button variant="outlined"
                                                    color={introStates.intro === 'shop' ? 'secondary' : 'primary'}
                                                    className={introStates.intro === 'shop' ? classes.buttonSelected : classes.buttonNormal}
                                                    onClick={() => setIntro('shop')}>
                                                    CỬA HÀNG
                                                </Button>
                                                <Button variant="outlined"
                                                    color={introStates.intro === 'community' ? 'secondary' : 'primary'}
                                                    className={introStates.intro === 'community' ? classes.buttonSelected : classes.buttonNormal}
                                                    onClick={() => setIntro('community')}>
                                                    CỘNG ĐỒNG
                                                </Button>
                                            </Box>

                                            <Hidden mdUp>
                                                <div className={classes.introPayment} onClick={() => openRegister()}>
                                                    {getIntroBox()}
                                                </div>
                                            </Hidden>

                                            <div className="paragraph" style={{ marginBottom: 16, marginTop: 16 }}>
                                                <p>Quét mã QR chuyển khoản thuận tiện</p>
                                                <p>Xác nhận giao dịch tự động</p>
                                            </div>

                                            <Box style={{ margin: 8 }}>
                                                <Button variant="contained" color='secondary' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }} onClick={() => openRegister()}>
                                                    ĐĂNG KÝ DÙNG THỬ MIỄN PHÍ
                                                </Button>
                                            </Box>

                                            <div className="paragraph" style={{ marginTop: 24 }}>
                                                <p>Đã xử lý: <strong style={{ color: Colors.CK_VIOLET }}><CountUp start={ckTotals.txCountStart} end={ckTotals.currentTxCount} separator="." /></strong>+ giao dịch</p>
                                                <p>Tổng giá trị: <strong style={{ color: Colors.CK_VIOLET }}><CountUp start={ckTotals.txAmountStart} end={ckTotals.currentTxAmount} separator="." /></strong>+ VNĐ</p>
                                                <p>Giảm chi phí, tăng trải nghiệm khách hàng</p>
                                                <p><a href="/#fqa" style={{cursor: "pointer", color: Colors.CK_VIOLET}}>
                                                    <i>--&gt; tìm hiểu thêm</i>
                                                </a></p>
                                            </div>


                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                                        <Hidden smDown>
                                            <div className={classes.introPayment} onClick={() => openRegister()}>
                                                {getIntroBox()}
                                            </div>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </div>

                            {

                                showWebsiteCKQRDialog &&
                                <WebsiteCKQRDialog open={showWebsiteCKQRDialog} closeCallback={closeWebsiteCKQRDialog} />

                            }

                        </div>
                    </div>

                </header>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

const theme = createTheme({
    palette: {
        primary: {
            main: Colors.BG_BLUE,
        },
        secondary: {
            main: Colors.CK_VIOLET,
            contrastText: "#fff"
        }
    },
});


const useStyle = makeStyles(theme => ({

    container: {
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: 0,
        [theme.breakpoints.down('md')]: {
            paddingTop: 111,
        }
    },

    containerMd: {
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: 111,
    },

    hotFeatures: {
        margin: 'auto',
        color: Colors.CK_VIOLET
    },

    introCKPay: {
        margin: 'auto',
    },

    introPayment: {
        width: 480,
        height: 480,
        padding: 60,
        backgroundColor: '#FFFFFF',
        borderRadius: 240,
        overflow: 'hidden',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 375,
            height: 375,
            padding: 30,
            borderRadius: 187,
        }
    },

    buttonNormal: {
        margin: 6, width: 125, fontSize: 15, fontWeight: 'bold', color: 'white'
    },
    buttonSelected: {
        margin: 6, width: 125, fontSize: 15, fontWeight: 'bold', color: Colors.CK_VIOLET
    },

}));
