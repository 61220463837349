import React from 'react';
import '../../payment/components/ckpayment.css';
import { Container } from '@mui/material';
import Colors from '../../ckcore/core/Colors';


export default function TermsOfUse(props) {

    return (
        <Container >
            <div style={{ textAlign: 'left', marginTop: 64, padding: 20, paddingTop: 40, paddingBottom: 40, fontSize: 11 }}>
                <h3 style={{color: Colors.DARK_BLUE, textAlign: 'center', margin: 'auto'}}>CHÍNH SÁCH BẢO MẬT THÔNG TIN</h3>
                <p style={{color: Colors.DARK, textAlign: 'center', margin: 'auto', padding: 8}}>Áp dụng từ Thứ Bảy, 01/01/2022</p>
                <div style={{color: Colors.DARK_BLUE}}>
                    <p style={{}}><strong>I. Mục đích và phạm vi thu thập thông tin</strong></p>
                    <p style={{}}>1. Nguyên tắc chung</p>
                    <p style={{}}>1.1. Công ty Cổ phần Giải pháp Thanh toán Chuyển Khoản và các công ty liên kết (sau đây được gọi là "CKPay", "Chúng tôi", "của Chúng tôi") hiểu rõ tầm quan trọng của sự bảo mật thông tin cá nhân của Bạn/Người sử dụng dịch vụ Thanh Toán Chuyển Khoản (sau đây gọi là "Khách hàng ") và Chúng tôi cố gắng minh bạch hóa về cách Chúng tôi thu thập, sử dụng, tiết lộ, chuyển và lưu trữ thông tin cá nhân của Khách hàng trong quá trình sử dụng  Dịch vụ Thanh Toán Chuyển Khoản (sau đây gọi là "Dịch vụ"). Chính sách bảo mật thông tin cá nhân (Privacy Policy) cung cấp tổng quan về những dữ liệu nào Chúng tôi thu thập, tại sao Chúng tôi thu thập và những gì Chúng tôi làm với dữ liệu đó.</p>
                    <p style={{}}>1.2. Vui lòng lưu ý rằng Chính sách bảo mật thông tin cá nhân này được áp dụng ngay khi Khách hàng sử dụng Dịch vụ của Chúng tôi. Điều quan trọng là Khách hàng cần đọc cẩn thận Chính sách bảo mật thông tin cá nhân bởi vì bất kỳ lúc nào Khách hàng sử dụng Dịch vụ, tức là Khách hàng đồng ý với các điều kiện và điều khoản mà Chúng tôi quy định trong Chính sách bảo mật thông tin cá nhân và các phần bổ sung của nó. Nếu không đồng ý với các điều kiện và điều khoản đó, thì Khách hàng không được sử dụng Dịch vụ của Chúng tôi.</p>
                    <p style={{}}>1.3. Chúng tôi có thể sửa đổi, cập nhật, bổ sung chính sách này vào mọi thời điểm. Bản sửa đổi có hiệu lực ngay tại thời điểm Chúng tôi cập nhật. Sau khi Chính sách bảo mật thông tin cá nhân được cập nhật, Khách hàng vẫn sử dụng Dịch vụ có nghĩa là Khách hàng đã hiểu và đồng ý với Chính sách bảo mật thông tin cá nhân mới.</p>
                    <p style={{}}>2. Liên kết với các trang website và dịch vụ khác</p>
                    <p style={{}}>Dịch vụ của chúng tôi có thể liên kết đến các trang website và dịch vụ của bên thứ ba và có thể các liên kết này nằm ngoài tầm kiểm soát của chúng tôi. Chúng tôi không chịu trách nhiệm về sự an toàn hoặc bảo mật của bất kỳ thông tin nào được thu thập bởi các trang website hoặc dịch vụ khác. Bạn phải thận trọng và xem xét các quyết định về bảo mật thông tin cá nhân áp dụng cho các trang website và dịch vụ của bên thứ ba mà bạn sử dụng.</p>
                    <p style={{}}>3. Phạm vi thu thập thông tin: Chúng tôi thu thập thông tin gì từ Khách hàng?</p>
                    <p style={{}}>3.1. Thông tin Khách hàng cung cấp trực tiếp:</p>
                    <p style={{}}>3.1.1. Khi Khách hàng khởi tạo tài khoản sử dụng Dịch vụ, Chúng tôi có thể yêu cầu Khách hàng cung cấp một số thông tin nhất định về bản thân Khách hàng. Thông tin Khách hàng cần cung cấp có thể gồm: họ và tên thật, biệt danh (nickname), giấy tờ tùy thân (CMND, hộ chiếu…), hộp thư điện tử (email), điện thoại liên lạc, địa chỉ thường trú, địa chỉ liên lạc…</p>
                    <p style={{}}>3.1.2. Nếu Khách hàng đặt mua sản phẩm hoặc dịch vụ trên Dịch vụ, Chúng tôi có thể yêu cầu Khách hàng cung cấp thông tin sau: số thẻ tín dụng / thẻ ghi nợ hoặc thẻ ATM, thông tin chủ thẻ, ngày hết hạn thẻ, mã kiểm tra của thẻ (CVV), số tài khoản ngân hàng, ngân hàng mà Khách hàng mở tài khoản giao dịch… Trong một số trường hợp, Chúng tôi cũng có thể yêu cầu Khách hàng gửi thông tin bổ sung cho Chúng tôi hoặc trả lời các câu hỏi bổ sung để giúp xác minh thông tin của Khách hàng.</p>
                    <p style={{}}>3.1.3. Một số Dịch vụ của Chúng tôi cho phép Khách hàng liên lạc với người khác. Những lần liên lạc đó sẽ được truyền qua và lưu trữ trên hệ thống của Chúng tôi.</p>
                    <p style={{}}>3.2. Thông tin về Khách hàng trong quá trình sử dụng Dịch vụ: Ngoài thông tin mà Khách hàng cung cấp, Chúng tôi có thể thu thập thông tin từ việc sử dụng Dịch vụ của Khách hàng.</p>
                    <p style={{}}>3.2.1. Thông tin thiết bị: Kiểu phần cứng, số IMEI, và các yếu tố nhận dạng thiết bị, địa chỉ MAC, địa chỉ IP, phiên bản hệ điều hành và cài đặt thiết bị mà Khách hàng sử dụng để truy cập Dịch vụ.</p>
                    <p style={{}}>3.2.2. Thông tin đăng nhập: Như thời gian và thời lượng Khách hàng sử dụng Dịch vụ, và bất kỳ thông tin nào khác được lưu trữ trong cookie mà Chúng tôi đã đặt trên thiết bị của Khách hàng.</p>
                    <p style={{}}>3.2.3. Thông tin giao dịch: Khi Khách hàng sử dụng Dịch vụ để thực hiện giao dịch, Chúng tôi có thể thu thập thông tin về giao dịch đó, bao gồm: ngày, giờ và số tiền giao dịch, thiết bị mà Khách hàng sử dụng giao dịch, mô tả của người Bán, mô tả do người Bán hàng hóa và dịch vụ mà Khách hàng đã mua cung cấp, đường dẫn mà Khách hàng chọn để liên kết với giao dịch, loại phương thức thanh toán đã sử dụng, mô tả lý do giao dịch của Khách hàng và phiếu mua hàng liên kết với giao dịch (nếu có).</p>
                    <p style={{}}>3.2.4. Thông tin khác: Chẳng hạn như các ứng dụng Khách hàng sử dụng, trang website Khách hàng truy cập và cách Khách hàng tương tác với nội dung được cung cấp thông qua Dịch vụ.</p>
                    <p style={{}}>3.3. Thông tin từ các nguồn của bên thứ ba: Chúng tôi có thể nhận thông tin về Khách hàng từ các bên thứ ba, bao gồm cả dịch vụ xác minh của khi kết nối với đối tác khác theo yêu cầu của Khách hàng hoặc nhằm mục đích gia tăng tiện ích cho Khách hàng. Chúng tôi cũng có thể nhận thông tin về Khách hàng từ các dịch vụ mạng xã hội của bên thứ ba khi Khách hàng chọn kết nối với các dịch vụ đó.</p>
                    <p style={{}}>3.4. Thông tin khác mà Chúng tôi thu thập: Chúng tôi cũng có thể thu thập các thông tin khác về Khách hàng, thiết bị của Khách hàng hoặc việc sử dụng các Dịch vụ theo các cách mà Chúng tôi mô tả cho Khách hàng vào thời điểm thu thập hoặc có sự đồng ý của Khách hàng. Bạn có thể chọn không cung cấp cho chúng tôi các loại thông tin nhất định (ví dụ: thông tin mà chúng tôi yêu cầu trong khi đăng ký tạo mới tài khoản cho Dịch vụ), khi đó, khả năng sử dụng một số Dịch vụ của Bạn có thể bị ảnh hưởng.</p>
                    <p style={{}}>4. Sử dụng cookie và các công nghệ lưu trữ cục bộ khác.</p>
                    <p style={{}}>4.1. Chúng tôi và các đối tác của mình sử dụng nhiều loại công nghệ để thu thập và lưu trữ thông tin khi Khách hàng sử dụng Dịch vụ. Quá trình này có thể bao gồm việc sử dụng cookie hoặc các công nghệ lưu trữ cục bộ tương đương để:</p>
                    <p style={{}}>- Có thể nhớ ký tự đăng nhập của Khách hàng để Khách hàng không phải nhập các ký tự đó mỗi khi Khách hàng đăng nhập vào dịch vụ;</p>
                    <p style={{}}>- Giúp “Chúng tôi” và đối tác hiểu những phần nào của Dịch vụ phổ biến nhất bởi vì chúng giúp Chúng tôi thấy Khách hàng truy cập vào những trang và tính năng nào cũng như thời gian mà họ sử dụng trên các trang đó. Bằng cách nghiên cứu loại thông tin này, Chúng tôi có thể điều chỉnh Dịch vụ cho phù hợp và cung cấp cho Khách hàng trải nghiệm tốt hơn;</p>
                    <p style={{}}>- Giúp “Chúng tôi” và đối tác hiểu những quảng cáo nào Khách hàng đã xem để có thể Khách hàng không phải nhận cùng quảng cáo đó mỗi khi Khách hàng truy cập Dịch vụ;</p>
                    <p style={{}}>- Giúp “Chúng tôi” và đối tác có thể cung cấp cho Khách hàng nội dung và quảng cáo có liên quan bằng cách thu thập thông tin về việc Khách hàng sử dụng Dịch vụ của Chúng tôi cũng như các trang website và ứng dụng khác.</p>
                    <p style={{}}>4.2. Bằng cách truy cập và sử dụng Dịch vụ của, Khách hàng đồng ý lưu trữ cookie, các công nghệ lưu trữ cục bộ khác và thông tin khác trên thiết bị của Khách hàng. Khách hàng cũng đồng ý cho phép Chúng tôi và đối tác truy cập cookie, công nghệ lưu trữ cục bộ khác, và thông tin như vậy theo cách được đề cập ở trên.</p>
                    <p style={{}}><strong>II. Phạm vi sử dụng dữ liệu:</strong></p>
                    <p style={{}}>1. Chúng tôi sử dụng thông tin của Khách hàng như thế nào?</p>
                    <p style={{}}>Chúng tôi có thể sử dụng thông tin mà Chúng tôi thu thập cho các mục đích sau:</p>
                    <p style={{}}>- Để cung cấp Dịch vụ hoặc tính năng mà Khách hàng yêu cầu;</p>
                    <p style={{}}>- Để cung cấp nội dung theo đối tượng khách hàng và khuyến nghị dựa vào các hoạt động trong quá khứ của Khách hàng trên Dịch vụ của Chúng tôi;</p>
                    <p style={{}}>- Để quảng cáo, chẳng hạn như cung cấp quảng cáo theo đối tượng khách hàng và nội dung được tài trợ và gửi cho Bạn các thông tin khuyến mại;</p>
                    <p style={{}}>- Để đánh giá và phân tích thị trường, khách hàng, sản phẩm và dịch vụ của Chúng tôi (kể cả hỏi ý kiến của Khách hàng về các sản phẩm và dịch vụ của Chúng tôi cũng như thực hiện khảo sát về khách hàng);</p>
                    <p style={{}}>- Để hiểu cách mọi người sử dụng Dịch vụ của Chúng tôi để Chúng tôi có thể cải tiến và phát triển các sản phẩm và dịch vụ mới;</p>
                    <p style={{}}>- Để thực hiện chương trình rút thăm trúng thưởng, cuộc thi giành giải thưởng hoặc khuyến mãi, khi được pháp luật cho phép; và cho mục đích khác theo sự đồng ý của Khách hàng.</p>
                    <p style={{}}>Khách hàng có quyền yêu cầu truy cập vào dữ liệu cá nhân của mình, có quyền yêu cầu Chúng tôi sửa lại những sai sót trong dữ liệu của Khách hàng mà không mất phí. Bất cứ lúc nào Khách hàng cũng có quyền yêu cầu Chúng tôi ngưng sử dụng dữ liệu cá nhân của Khách hàng cho mục đích quảng cáo, tiếp thị.</p>
                    <p style={{}}>2. Chúng tôi tiết lộ thông tin của Khách hàng cho ai?</p>
                    <p style={{}}>Chúng tôi sẽ không tiết lộ thông tin của Khách hàng cho các bên thứ ba vì mục đích kinh doanh hoặc tiếp thị độc lập của riêng họ mà không có sự đồng ý của Khách hàng. Tuy nhiên, Chúng tôi có thể tiết lộ thông tin của Khách hàng cho các pháp nhân sau:</p>
                    <p style={{}}>2.1. Đối tác kinh doanh: Chúng tôi cũng có thể chia sẻ thông tin của Khách hàng với đối tác kinh doanh tin cậy. Những pháp nhân này có thể sử dụng thông tin của Khách hàng để cung cấp các dịch vụ mà Khách hàng yêu cầu. Tuy nhiên, các tổ chức này không được sử dụng thông tin cá nhân của Khách hàng để quảng cáo tiếp thị dưới bất kỳ hình thức nào nếu chưa được sự đồng ý của Khách hàng.</p>
                    <p style={{}}>2.2. Nhà cung cấp dịch vụ: chúng tôi cũng có thể tiết lộ thông tin của Bạn cho các công ty cung cấp dịch vụ cho hoặc thay mặt chúng tôi, chẳng hạn như các công ty giúp chúng tôi thanh toán hoặc thay mặt chúng tôi gửi email / SMS. Những pháp nhân này bị hạn chế khả năng sử dụng thông tin của Bạn cho các mục đích ngoài việc cung cấp dịch vụ cho chúng tôi.</p>
                    <p style={{}}>2.3. Trong một số trường hợp, vì lý do pháp lý: Chúng tôi sẽ chia sẻ thông tin cá nhân với các công ty, tổ chức hoặc cá nhân bên ngoài nếu Chúng tôi tin rằng việc truy cập, sử dụng, bảo toàn hoặc tiết lộ thông tin đó là cần thiết một cách hợp lý để:</p>
                    <p style={{}}>- Đáp ứng bất kỳ điều luật, quy định, thủ tục pháp lý hiện hành hoặc yêu cầu có thể cưỡng chế thi hành của chính quyền;
                    </p><p style={{}}>- Thực thi điều kiện và điều khoản sử dụng dịch vụ được áp dụng, kể cả việc điều tra những vi phạm tiềm tang;</p>
                    <p style={{}}>- Phát hiện, ngăn ngừa hoặc theo cách khác xử lý các vấn đề về gian lận, an ninh hoặc kỹ thuật;</p>
                    <p style={{}}>- Bảo vệ các quyền, tài sản hoặc sự an toàn của Chúng tôi, đối tác hoặc người dùng của Chúng tôi, hoặc công chúng không bị tổn hại khi cần thiết hoặc được cho phép bởi luật pháp.</p>
                    <p style={{}}>2.4. Nếu Chúng tôi tham gia vào việc sáp nhập, mua lại hoặc bán tài sản, Chúng tôi sẽ tiếp tục đảm bảo việc bảo mật bất kỳ thông tin cá nhân nào và thông báo cho Khách hàng trước khi thông tin cá nhân được chuyển giao và chịu sự chi phối của chính sách khác về sự riêng tư.</p>
                    <p style={{}}>2.5. Các bên khác khi có sự đồng ý của Khách hàng hoặc theo hướng dẫn của Khách hàng. Ngoài các tiết lộ như được mô tả trong Chính sách bảo mật thông tin cá nhân này, chúng tôi có thể chia sẻ thông tin về Bạn với các bên thứ ba khi Bạn đồng ý hoặc yêu cầu việc chia sẻ như vậy.
                    </p><p style={{}}><strong>III. Thời gian lưu trữ thông tin:</strong></p>
                    <p style={{}}>Chúng tôi sẽ lưu trữ các thông tin cá nhân do khách hàng cung cấp trên các hệ thống nội bộ của Công ty chúng tôi. Đồng thời thực hiện các bước hợp lý để đảm bảo rằng chúng tôi chỉ lưu giữ thông tin về Bạn trong thời gian cần thiết cho mục đích mà thông tin được thu thập hoặc theo yêu cầu của Khách hàng hoặc theo luật pháp hiện hành.</p>
                    <p style={{}}><strong>IV. Địa chỉ của đơn vị thu thập và quản lý thông tin, bao gồm cách thức liên lạc để người tiêu dùng có thể hỏi về hoạt động thu thập, xử lý thông tin liên quan đến cá nhân mình.</strong></p>
                    <p style={{}}>CÔNG TY CỔ PHẦN GIẢI PHÁP THANH TOÁN CHUYỂN KHOẢN (CKPay)</p>
                    <p style={{}}>Địa Chỉ: 178 Mai Dịch, Phường Mai Dịch, Quận Cầu Giấy, TP. Hà Nội</p>
                    <p style={{}}>Văn phòng đại diện: Số 9, Ngách 84, Ngõ 1, Phạm Tuấn Tài, Cầu Giấy, Hà Nội</p>
                    <p style={{}}>Mã số doanh nghiệp: 0104103433 do Sở Kế hoạch &amp; Đầu tư TP Hà Nội cấp lần đầu ngày 13/08/2009</p>
                    <p style={{}}>Email: cskh@chuyenkhoan.com</p>
                    <p style={{}}><strong>V. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân của mình.</strong></p>
                    <p style={{}}>1. Khách hàng có quyền tự kiểm tra, cập nhật, điều chỉnh thông tin cá nhân của mình khi đăng ký, đăng nhập trực tiếp vào hệ thống ở trên APP hoặc website: https://chuyenkhoan.com.</p>
                    <p style={{}}>2. Khách hàng hủy bỏ thông tin cá nhân của mình bằng cách liên hệ với ban quản trị website công ty chúng tôi thực hiện việc này.</p>
                    <p style={{}}>3. Khách hàng có quyền gửi khiếu nại về nội dung bảo mật thông tin đề nghị liên hệ Ban quản trị của website công ty chúng tôi. Khi tiếp nhận những phản hồi này, công ty chúng tôi sẽ xác nhận lại thông tin, trường hợp đúng như phản ánh của thành viên tùy theo mức độ, công ty chúng tôi sẽ có những biện pháp xử lý kịp thời.</p>
                    <p style={{}}><strong>VI. Cam kết bảo mật thông tin của Khách hàng</strong></p>
                    <p style={{}}>1.1. Bảo mật là ưu tiên hàng đầu của Chúng tôi. Chúng tôi luôn cố gắng đảm bảo dữ liệu cá nhân của Khách hàng được bảo vệ để không bị truy cập trái phép hoặc tình cờ, bị xử lý hoặc xóa bỏ. Chúng tôi duy trì cam kết bảo mật dữ liệu này bằng cách áp dụng những biện pháp vật lý, điện tử và quản lý để bảo vệ dữ liệu cá nhân của Khách hàng.</p>
                    <p style={{}}>1.2. Khu vực bảo mật của trang web hỗ trợ phương thức Secure SocketLayer (SSL) và công nghệ mã hoá 1024 bits - chế độ mã hóa tiêu chuẩn trên Internet để bảo vệ dữ liệu. Khi Khách hàng cung cấp những thông tin nhạy cảm như chi tiết về thẻ tín dụng, tài khoản ngân hàng… các dữ liệu này sẽ được mã hoá trước khi được gửi đi một cách an toàn trên Internet.</p>
                    <p style={{}}>1.3. Các máy chủ trang web của Chúng tôi được bảo vệ bởi “bức tường lửa” và hệ thống của Chúng tôi được luôn được kiểm tra để phòng tránh sự truy cập không được ủy quyền. Chúng tôi sẽ không gửi những thông tin cá nhân đến Khách hàng bằng hình thức thư điện tử thông thường. Vì tính an toàn của hình thức thư điện tử thông thường không được đảm bảo, Khách hàng chỉ nên gửi thư cho Chúng tôi thông qua tiện ích kênh thư điện tử bảo mật trên trang web của Chúng tôi.</p>
                    <p style={{}}>1.4. Chúng tôi chỉ cho phép nhân viên, nhà thầu và người đại diện của Chúng tôi truy cập thông tin cá nhân vì họ là những người cần biết thông tin này để xử lý các thông tin đó cho Chúng tôi và họ phải chịu sự chi phối của nghĩa vụ bảo mật nghiêm ngặt trong hợp đồng và có thể bị kỷ luật hoặc chấm dứt hợp đồng nếu họ không tuân theo các nghĩa vụ đó.</p>
                    <p style={{}}>Cám ơn Khách hàng đã đồng ý, tin tưởng và sử dụng Dịch vụ của Chúng tôi. Chúc Qúy khách hàng có những trải nghiệm tuyệt vời khi sử dụng Thanh Toán Chuyển Khoản!</p>
                </div>
            </div>
        </Container>
    );
}
