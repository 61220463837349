import React from "react";
import { ReactComponent as ZaloIcon } from '../icons/zalo.svg';
import { ReactComponent as FacebookIcon } from '../icons/facebook.svg';


export default function ContactBox(props) {

  // set contact phone by hours
  const currentHour = (new Date()).getHours();
  const contactPhone = (currentHour >= 4 && currentHour <= 18) ? "0906246196" : "0969781318";

  return (
    <div className="contact-box-bottom">

      <a className="contact-box-wrapper nut-chat-zalo" href="https://zalo.me/3556932760718090293" rel="nofollow"
        target="_blank">
        <div className="contact-icon-box" style={{border: 0}}>
          <ZaloIcon />
        </div>
        <div className="contact-info">
          <b>Chat Zalo</b>
          <span>(6h-22h)</span>
        </div>
      </a>
      <a className="contact-box-wrapper nut-chat-facebook" href="https://m.me/chuyenkhoan.com.vn" rel="nofollow"
        target="_blank">
        <div className="contact-icon-box" style={{border: 0}}>
          <FacebookIcon />
        </div>
        <div className="contact-info">
          <b>Chat Facebook</b>
          <span>(6h-22h)</span>
        </div>
      </a>
    </div>
  );
}
