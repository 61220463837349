import makeStyles from '@mui/styles/makeStyles';
import { createTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Autocomplete from '@mui/material/Autocomplete';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';

import { QRCode } from 'react-qrcode-logo';
import { getCKBankLogo, getDefaultBanks } from '../../ckcore/core/CkBank'
import ValUtils from '../../ckcore/core/ValUtils';

import { getBankSvgIcon } from '../../ckcore/core/CkBank'
import { copyTextToClipboard } from '../../ckcore/core/CkUtils';
import { getBankInfo } from '../../ckcore/core/CkBank';
import { isValidTransferContent, isValidAmount } from '../../ckcore/core/Validator';
import { Container, Link, ScopedCssBaseline, Tooltip, Typography } from '@mui/material';
import Colors from '../../ckcore/core/Colors';
import PaymentGuideDialog from '../../payment/components/PaymentGuide';
import CKQRGen from '../../ckcore/core/CKQRGen';

export default function CKQRBox(props) {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editing, setEditing] = useState(false);

  const partnerId = props.partnerId;
  const [partnerObj, setPartnerObj] = useState(props.ckqrInfo);
  const [accountObj, setAccountObj] = useState(props.ckqrInfo.accounts[0]);
  const dynamicAmount = (props.amount) ? props.amount : 0;
  const dynamicMessage = (props.message) ? props.message : '';

  const dynamicColor = (props.ckqrInfo.color) ? props.ckqrInfo.color : (dynamicAmount > 0 ? Colors.RED : Colors.DARK_BLUE);
  const dynamicAlign = (props.ckqrInfo.align) ? props.ckqrInfo.align : (dynamicAmount > 0 ? 'center' : 'left');

  const sizeObj = {
    fontSize: 18,
    bankLogoHeight: 96,
    qrcodeSize: 180,
    qrcodeSizeLarge: 180
  }

  const timestampRef = useRef(0);
  const timeoutRef = useRef();
  const handleKeyUp = () => event => {

    if ((event) && event.key === 'Enter') {
      console.log('CKQR - Enter key pressed');
      handleCompleted(true);
      timestampRef.current = 0;

      return;
    }

    // clear previous
    clearTimeout(timeoutRef.current);

    // set timestamp to check timeout
    timestampRef.current = (new Date()).getTime();
    timeoutRef.current = setTimeout(function () {

      console.log('CKQR - Auto update content.');

      // no more keypress
      if (timestampRef.current > 222 && (new Date()).getTime() - timestampRef.current > 333) {
        handleCompleted(true);
        timestampRef.current = 0;
      }
    }, 333);
  }

  const handleChange = (name) => event => {
    // console.log("Change value of: " + name + " value: " + event.target.value);

    if (name === 'amount') {
      setPartnerObj({ ...partnerObj, [name]: ValUtils.getNumber(event.target.value) });
    } else {
      setPartnerObj({ ...partnerObj, [name]: event.target.value });
    }

    setEditing(true);
  };

  const handleSelectAmount = (name, value) => {

    console.log("Select: " + name + " - " + value);
    partnerObj.amount = value;

    setPartnerObj({ ...partnerObj, [name]: value });
    setEditing(true);

    setTimeout(() => {
      console.log('CKQR - Auto update content on change amount to: ' + partnerObj.amount);
      handleCompleted(true);
    }, 333);
  };


  const handleCompleted = (value) => {

    if (value) {

      setErrorMessage("");

      var errorMsg = "";
      // validate content
      if ((partnerObj.message) && !isValidTransferContent(partnerObj.message)) {
        errorMsg = partnerObj.labels.messageError;
      }

      // validate amount
      if ((partnerObj.amount) && !isValidAmount(partnerObj.amount)) {
        errorMsg = partnerObj.labels.amountError;
      }

      // do request CKQR
      if (!(errorMsg)) {
        updateCQKR();
      } else {
        setErrorMessage(errorMsg);
        console.log("Error: " + errorMsg + " " + partnerObj.amount + " " + editing);
      }
    } else {
    }
  };

  /**
   * Check if CKQR Box allow user input amount and content
   */
  const isDynamicAmount = () => {
    return (dynamicAmount !== 'undefined') && (dynamicAmount > 0);
  }

  const getDisplayAmount = () => {
    var amount = (dynamicAmount > 0) ? dynamicAmount : partnerObj.amount;
    return ValUtils.formatMoney(amount, ',');
  }

  const getDisplayMessage = () => {
    var message = (dynamicMessage) ? dynamicMessage : partnerObj.message;
    return message;
  }

  /**
   * Update CKQR for partner accounts
   */
  const updateCQKR = () => {

    var bankAccounts = partnerObj.accounts;
    var amount = (dynamicAmount > 0) ? dynamicAmount : partnerObj.amount;
    var message = (dynamicMessage) ? dynamicMessage : partnerObj.message;

    for (var i = 0; i < bankAccounts.length; i++) {
      var bankAccountObj = bankAccounts[i];
      var bankObj = getBankInfo(getDefaultBanks(), bankAccountObj.bankName);
      var seftGenCKQR = CKQRGen.generateCKQR(bankObj.bankCodeNumber.toString(), bankAccountObj.accountNumber, amount, message);
      bankAccounts[i].ckqr = seftGenCKQR;
      // console.log("Gen CKQR: " + seftGenCKQR);
    }

    setPartnerObj({ ...partnerObj, accounts: bankAccounts });
    setSuccessMessage('success');
  }

  const [selectedGuideBank, setSelectedGuideBank] = useState({});
  const [showPaymentGuide, setShowPaymentGuide] = useState(false);
  const bankTransferGuide = (bankObj) => {
    setSelectedGuideBank(bankObj);
    setShowPaymentGuide(true);
  }

  const closeBankTransferGuide = () => {
    setShowPaymentGuide(false);
  }

  // for copying
  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipboard = (text, message) => {

    if (!(text)) {
      // setShowError(true);
    } else {
      copyTextToClipboard(text);
      // show copy success message
      setCopySuccess(message);
    }


    // clear on timeout
    setTimeout(function () {
      setCopySuccess('');
    }, 5000);
  };

  useEffect(() => {

    setPartnerObj(props.ckqrInfo);
    setAccountObj(props.ckqrInfo.accounts[0]);
    updateCQKR();
  }, [props.ckqrInfo, props.amount, props.message]);

  // Add bank dialog
  return (
    <React.Fragment>
      <ScopedCssBaseline />
      <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 20, paddingBottom: 8, resize: { fontSize: 16 } }}>
        <Grid item xs={12}>
          <Box p={1} sx={{
            margin: 'auto',
            alignItems: 'center',
            fontSize: 16
          }}>
            <Grid container spacing={1}>

              {
                (partnerObj.title) &&
                <Grid item xs={12}>
                  <Box><Typography variant="h1" style={{ textAlign: 'center', fontSize: 22, fontWeight: "bold" }}>{partnerObj.title}</Typography></Box>
                </Grid>
              }
              {
                (partnerObj.subtitle) &&
                <Grid item xs={12}>
                  <Box><Typography style={{ textAlign: 'center', paddingBottom: 20, fontSize: 16, whiteSpace: 'pre-line' }}>{partnerObj.subtitle}</Typography></Box>
                </Grid>
              }


              <Grid key={"bank-account"} item xs={12} md={6}>

                <Box style={{ textAlign: 'center', fontSize: 20, paddingBottom: 6 }}>
                  {(partnerObj.contentLang === 'en') ? accountObj.bankName + " Account" : "Tài khoản " + accountObj.bankName}
                </Box>
                <Box>
                  <Typography style={{ textAlign: 'center', marginLeft: -8, marginRight: -8, fontSize: 18, fontWeight: 'bold', whiteSpace: 'pre-line' }}>{accountObj.accountName.toUpperCase()}</Typography>
                </Box>
                <Box style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20, marginTop: 8 }}>

                  <TextField id="ck-bank-account" variant="outlined" margin="normal" size="small"
                    label={partnerObj.labels.accountNumber}
                    onClick={() => copyToClipboard(accountObj.accountNumber, partnerObj.labels.copyNotify)}
                    value={ValUtils.formatBankAccountNumber(accountObj.accountNumber)}
                    className={classes.textField}
                    inputProps={{ min: 0, style: { textAlign: 'center', fontSize: sizeObj.fontSize, fontWeight: 'bold', color: Colors.DARK_BLUE, height: 25, maxWidth: 375, width: '100%', background: 'white', border: 0 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    InputProps={{
                      readOnly: true,
                      className: classes.inputAccountNumber,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton color="primary" className={classes.iconCheck} size="large">
                            <FileCopyOutlinedIcon style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.55)' }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small" >
                            <SvgIcon style={{ fontSize: 24 }} component={getBankSvgIcon(accountObj.bankName)} viewBox="0 0 48 48" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <Box style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20, marginTop: 8 }}>

                  {
                    (partnerObj.values) && (partnerObj.values.length > 0) ? (
                      <Autocomplete
                        id="ck-select-amount"
                        size="small"
                        disableClearable
                        readOnly={isDynamicAmount()}
                        freeSolo={true}
                        options={partnerObj.values}
                        renderOption={(props, option) => <li {...props} style={{ fontSize: 16, color: Colors.DARK_BLUE }}>{option.name}</li>}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, option) => handleSelectAmount('amount', option.value)}
                        onInputChange={handleChange('amount')}

                        renderInput={(params) =>
                          <TextField {...params} type="tel" label={partnerObj.labels.amount} variant="outlined" fullWidth
                            autoComplete='new-password'
                            onKeyUp={handleKeyUp()}
                            value={getDisplayAmount()}
                            inputProps={{
                              ...params.inputProps,
                              style: { textAlign: dynamicAlign, fontSize: 16, fontWeight: 'bold', color: dynamicColor, height: 23, maxWidth: 375, width: '100%', background: 'white', border: 0 },
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            InputProps={{
                              ...params.InputProps,
                              readOnly: isDynamicAmount(),
                              autoComplete: 'new-password',
                              className: classes.inputTxValue
                            }}
                            margin="dense" className={classes.textInput}
                          />}
                      />
                    ) :
                      (
                        <TextField id="ck-order-amount" variant="outlined" fullWidth margin="normal" size="small"
                          label={partnerObj.labels.amount}
                          type="tel"
                          value={getDisplayAmount()}
                          className={classes.textField}
                          inputProps={{ min: 0, style: { 
                            textAlign: dynamicAlign, fontSize: 16, fontWeight: 'bold', color: dynamicColor, 
                            height: 23, maxWidth: 375, width: '100%', background: 'white', border: 0} }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          InputProps={{
                            readOnly: isDynamicAmount(),
                            className: classes.inputTxValue
                          }}
                          onChange={handleChange('amount')}
                          onKeyUp={handleKeyUp()}

                          helperText={!isValidAmount(partnerObj.amount) && (errorMessage.length > 0) ? partnerObj.labels.amountError : ""}
                          error={(errorMessage.length > 0) && !isValidAmount(partnerObj.amount)}
                        />

                      )
                  }
                </Box>
                <Box style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20, marginTop: 8 }}>
                  <TextField id="ck-order-info" variant="outlined" fullWidth margin="normal" size="small"
                    label={partnerObj.labels.message}
                    placeholder={partnerObj.guide}
                    value={getDisplayMessage()}
                    className={classes.textField}
                    inputProps={{ min: 0, style: { 
                      textAlign: dynamicAlign, fontSize: 16, fontWeight: 'bold', color: dynamicColor, 
                      height: 23, maxWidth: 375, width: '100%', background: 'white', 
                      padding: 0, paddingLeft: 12 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    InputProps={{
                      readOnly: isDynamicAmount(),
                      className: classes.inputTxContent
                    }}
                    onChange={handleChange('message')}
                    onKeyUp={handleKeyUp()}
                    helperText={!isValidTransferContent(partnerObj.message) && (errorMessage.length > 0) ? partnerObj.labels.messageError : ""}
                    error={(errorMessage.length > 0) && !isValidTransferContent(partnerObj.message)}
                  />
                </Box>
                {
                  (partnerObj.accounts.length > 1) &&
                  <Box style={{ noWrap: true, overflow: 'hidden' }}>
                    <span style={{ paddingLeft: 20, paddingRight: 12 }}>Các TK nhận:</span>
                    {
                      partnerObj.accounts.map((bankAccountObj, index) => (
                        <span key={index}>
                          <Tooltip title={"Chuyển khoản tới " + bankAccountObj.bankName} aria-label="Bank"><span>
                            <IconButton size="small"
                              className={bankAccountObj.accountNumber === accountObj.accountNumber ? classes.bankIconSelected : classes.bankIconButton}
                              onClick={() => setAccountObj(bankAccountObj)}>
                              <SvgIcon style={{ fontSize: 24 }} component={getBankSvgIcon(bankAccountObj.bankName)} className={classes.svgIcon} viewBox="0 0 48 48" />
                            </IconButton></span>
                          </Tooltip>
                        </span>
                      ))
                    }
                  </Box>
                }
              </Grid>
              <Grid key={"qr"} item xs={12} md={6}>
                {(partnerObj.contentLang === 'en') ?
                  (
                    <Box style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
                      <span style={{ color: Colors.RED, fontWeight: 'bold' }}>Viet</span><span style={{ color: Colors.DARK_BLUE, fontWeight: 'bold' }}>QR</span> Bank Transfer
                    </Box>

                  ) :
                  (
                    <Box style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
                      Chuyển Khoản <span style={{ color: Colors.RED, fontWeight: 'bold' }}>Viet</span><span style={{ color: Colors.DARK_BLUE, fontWeight: 'bold' }}>QR</span>
                    </Box>
                  )
                }

                <Box style={{ margin: 'auto', marginTop: 8, width: sizeObj.qrcodeSize + 20 }}>
                  <QRCode size={sizeObj.qrcodeSize} eyeRadius={3} value={accountObj.ckqr} />
                </Box>

                <Box style={{ textAlign: 'center', marginTop: -8 }}>
                  <img style={{ height: 56 }} src={getCKBankLogo("napas247")} alt="Napas 247" />
                  <img style={{ height: 56 }} src={getCKBankLogo(accountObj.bankName)} alt={accountObj.bankName} />
                </Box>

              </Grid>

            </Grid>

          </Box>

        </Grid>

        <Grid item xs={12}>
          {
            (getDisplayAmount().length < 1 && partnerObj.guide !== '') ? (
              <Box style={{ padding: 8, marginTop: 16 }}>
                <Typography style={{ textAlign: 'center', fontSize: 16, whiteSpace: 'pre-line' }}>{partnerObj.labels.messageGuide}</Typography>
                <Typography style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', whiteSpace: 'pre-line' }}>{partnerObj.guide}</Typography>
              </Box>
            ) :
              (
                <Box key="support-bank" style={{ textAlign: 'center', marginTop: 16, margin: 'auto' }}>
                  <Typography style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', whiteSpace: 'pre-line' }}>Mã Chuyển khoản QR đã được tạo</Typography>
                  <Typography style={{ textAlign: 'center', fontSize: 16, whiteSpace: 'pre-line' }}>Mời bạn dùng app ngân hàng để quét VietQR, hoặc click xem hướng dẫn:</Typography>
                  <Typography noWrap={true} style={{ textAlign: 'center' }}>
                    {
                      getDefaultBanks().filter((bank, index) => index <= 12).map((bank, index) => (
                        <span key={index}>
                          <Tooltip title={"Hướng dẫn chuyển khoản từ " + bank.name} aria-label="Bank"><span>
                            <IconButton size="small" className={classes.bankIconButton} onClick={() => bankTransferGuide(bank)}>
                              <SvgIcon component={getBankSvgIcon(bank.name)} className={classes.svgIcon} viewBox="0 0 48 48" />
                            </IconButton></span>
                          </Tooltip>
                        </span>
                      ))
                    }
                  </Typography>
                </Box>
              )
          }
        </Grid>

        <Grid item xs={12}>
          <Box style={{ textAlign: 'center', fontSize: 15, marginTop: 8 }}>
            Powered by <Link href="https://chuyenkhoan.com" target="_blank">Chuyenkhoan.com</Link>
          </Box>
        </Grid>

        {
          showPaymentGuide &&
          <PaymentGuideDialog open={showPaymentGuide} closeCallback={closeBankTransferGuide}
            bankObj={selectedGuideBank}

            order={{
              bank_name: partnerObj.accounts[0].bankName,
              account_name: partnerObj.accounts[0].accountName,
              account_number: partnerObj.accounts[0].accountNumber,
              order_total: getDisplayAmount(),
            }}

            bankInfo={{
              ckQRContent: getDisplayMessage()
            }}

            supportBanks={getDefaultBanks()}
          />
        }


      </Container>
    </React.Fragment>
  );
}

const theme = createTheme();

const useStyles = makeStyles(theme => ({

  textField: {
    marginTop: 8,
    marginLeft: 0,
    marginRight: 0,
    background: 'white',
    color: '#36507D',
    width: '100%',
    padingRight: 3,
    cursor: 'pointer',
  },

  inputAccountNumber: {
    color: Colors.DARK_GREEN,
    width: '100%',
    maxWidth: 375,
    textAlign: 'center',
    fontSize: 18,
    minHeight: 44,
    fontWeight: 'bold',
    paddingTop: 3,
    background: 'white',
    cursor: 'pointer'
  },

  inputTxValue: {
    color: Colors.DARK_BLUE,
    width: '100%',
    maxWidth: 375,
    textAlign: 'center',
    fontSize: 16,
    minHeight: 40,
    paddingTop: 3,
    fontWeight: 'bold',
    background: 'white',
    cursor: 'pointer'
  },

  inputTxContent: {
    color: Colors.DARK_BLUE,
    width: '100%',
    maxWidth: 375,
    fontSize: 16,
    minHeight: 40,
    paddingTop: 3,
    fontWeight: 'bold',
    background: 'white',
    cursor: 'pointer'
  },

  inputNormalContent: {
    color: Colors.DARK_BLUE,
    width: '100%',
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer'
  },

  iconCheck: {
    margin: -10,
    width: 24,
    height: 24,
    padding: 0,
    marginRight: -12,
  },

  benSelectText: {
    margin: 0,
    padding: 0,
  },

  bankIconButton: {
  },

  svgIcon: {
    fontSize: 24,
    padding: 0,
  },

  bankIconSelected: {
    borderColor: Colors.DARK_GREEN,
    borderStyle: 'dashed',
    borderWidth: 1,
  },

}));

