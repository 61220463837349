const TxStatus = {
  // Transaction status

  // Tx Statuses
  SUCCESS: 0,
  CANCEL: 1,
  TIMEOUT: 2,
  ERROR: 3, // and others
  NOT_FOUND: 4, // and others
  INVALID: 5, // and others
  CHECKSUM_ERROR: 6,
  DUPLICATED: 7,
  WAITING: 8,
  PROCESSING: 9,

  REGISTER_SUCCESS: 10,
  REGISTER_ERROR_PHONE_INVALID: 11,
  REGISTER_ERROR_PHONE_EXISTS: 12,
  REGISTER_ERROR_NAME_INVALID: 13,
  REGISTER_ERROR_PASSWORD_INVALID: 14,

  UPDATE_USER_ERROR_EMAIL_INVALID: 16,
  UPDATE_USER_ERROR_PROVINCE_INVALID: 17,
  UPDATE_USER_ERROR_ADDRESS_INVALID: 18,

  REGISTER_ERROR_UNKNOWN: 19,

  LOGIN_SUCCESS: 20,
  LOGIN_ERROR: 21,
  LOGIN_ERROR_NOT_REGISTER: 22,
  LOGIN_ERROR_INVALID_NODE: 23,
  LOGIN_ERROR_ACCOUNT_LOCK: 24,
  LOGIN_ERROR_ADMIN_LOCK: 25,
  LOGIN_CHANGE_PASSWORD: 29,

  AUTHORISE_SUCCESS: 30,
  AUTHORISE_ERROR_AUTHCODE_INVALID: 31,
  AUTHORISE_ERROR_TOKEN_TIMEOUT: 32,
  AUTHORISE_ERROR_CAPTCHA_INVALID: 33,
  AUTHORISE_SMS_SENDING: 35,
  AUTHORISE_SMS_DELIVERED: 36,
  AUTHORISE_SMS_ERROR: 37,

  TRANSFER_DEST_NOT_REGISTER: 60,
  TRANSFER_DEFAULT_CONTENT: 66,
  TRANSFER_DEPOSITE_CONTENT: 68,
  TRANSFER_CASHOUT_CONTENT: 69,

  SYSTEM_TOPUP_ERROR: 74,
  SYSTEM_TOPUP_ERROR_TELCO: 75,
  SYSTEM_NOT_ENOUGH_CARD_TO_CHANGE: 77,
  SYSTEM_NOT_ENOUGH_CARD_TO_BUY: 78,
  USER_EXCEED_BUY_CARD_LIMIT: 79,
  USER_EXCEED_ORDER_VALUE_LIMIT: 80,
  USER_EXCEED_DAILY_VALUE_LIMIT: 81,

  // card response message
  CARD_EXTEND_SERVICE_SUCCESS: 82,
  CARD_CHARGING_INVALID_PIN: 83,
  CARD_CHARGING_BLOCK_USER: 84,
  CARD_CHARGING_DISABLE_CARDTYPE: 85,
  CARD_CHARGING_ERROR: 86,
  CARD_CHARGING_INVALID_SERIAL: 87,
  CARD_CHARGING_INPROCESSING: 88,

  // HTTP STATUSES
  HTTP_SUCCESS: 200,
  HTTP_BAD_REQUEST: 400,
  HTTP_UNAUTHORIZED: 401,
  HTTP_PAYMENT_REQUIRED: 402,
  HTTP_NOT_FOUND: 404,
  HTTP_REQUEST_TIMEOUT: 408,
  HTTP_SERVICE_UNAVAILABLE: 503,
};

export default TxStatus;
