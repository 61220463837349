import { Box, Hidden } from "@mui/material";
import Colors from "../../ckcore/core/Colors";
import { animated, useSpring } from '@react-spring/web'
import ck_logo_round from '../../ckcore/images/ck_lgrb_256.png'

export default function IntroCkForCommunity(props) {

  const styles = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
  });

  let wh = 72;
  return (
    <animated.div style={styles}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 320 }}>

        <Box style={{ fontSize: 20, fontWeight: '600', color: Colors.DARK_BLUE, marginBottom: 16 }}>HỖ TRỢ CỘNG ĐỒNG</Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }} style={{ marginBottom: 16 }}>
          <img width={wh} height={wh} style={{ borderColor: Colors.DARK_BLUE, borderWidth: 1, borderRadius: wh / 2 }}
            src="https://tnvc.vn/templates/ja_charity/favicon.ico" alt="Quỹ Trò nghèo vùng cao" />
          <Box sx={{ border: 1, borderStyle: "dashed", borderColor: "green", borderRadius: 3 }} style={{ padding: 8, marginLeft: 8, textAlign: 'left' }}>
            <span><strong>Bác Trần Đăng Tuấn: </strong><i>Giao dịch vào quỹ TNVC được cập nhật từng giây.</i></span>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }} style={{ marginBottom: 16 }}>
          <Box sx={{ border: 1, borderStyle: "dashed", borderColor: "green", borderRadius: 3 }} style={{ padding: 8, marginRight: 8, minHeight: 72, textAlign: 'left' }}>
            <span><strong>Chị Tú Anh: </strong><i>Chuyenkhoan.com là MVP cho chương trình CHỈ TÌNH THƯƠNG Ở LẠI!</i></span>
          </Box>
          <img width={wh} height={wh} style={{ backgroundColor: Colors.DARK_BLUE, padding: 12, borderRadius: wh / 2 }}
            src="https://chitinhthuongolai.org/wp-content/uploads/2021/06/favicon.ico" alt="Chỉ tình thương ở lại" />
        </Box>

        <Hidden smDown>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <img width={wh} height={wh}
              src={ck_logo_round} alt="Chuyenkhoan.com" />
            <Box sx={{ border: 1, borderStyle: "dashed", borderColor: "green", borderRadius: 3 }} style={{ padding: 8, marginLeft: 8, textAlign: 'left' }}>
              <span><strong>Chuyenkhoan.com: </strong><i>Giúp cập nhật quỹ từ thiện, quỹ lớp, quỹ chung cư..</i></span>
            </Box>
          </Box>
          <Box style={{ fontSize: 20, fontWeight: '600', color: Colors.DARK_BLUE, marginTop: 20 }}>QUẢN LÝ QUỸ MINH BẠCH</Box>
        </Hidden>
        <Hidden mdUp>
          <Box style={{ fontSize: 20, fontWeight: '600', color: Colors.DARK_BLUE, marginTop: 0 }}>MINH BẠCH QUỸ</Box>
        </Hidden>
      </Box>
    </animated.div>
  );
}