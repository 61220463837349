import makeStyles from '@mui/styles/makeStyles';
import {createTheme} from '@mui/material/styles';
import Colors from '../core/Colors';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material";

/**
 * Export default theme
 */
export const ckTheme = createTheme(adaptV4Theme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "2px 6px",
      },
      sizeSmall: {
        padding: "2px 3px 2px 3px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      },
    },
  },

  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.CK_VIOLET,
      contrastText: '#fff',
    },
    text: {
      disabled: Colors.DARK_BLUE,
    },
  },
}));

/**
 * Export table style
 */
export const ckTableStyles = makeStyles(theme => ({

  title: {
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
    paddingLeft: 2,
    cursor: 'pointer'
  },

  transHead: {
    minHeight: 22,
    padding: '3px 3px',
  },

  searchBox: {
    padding: '1px 3px',
    display: 'flex',
    alignItems: 'center',
    width: '200',
  },

  searchInput: {
    marginLeft: ckTheme.spacing(1),
    flex: 1,
  },

  searchIcon: {
    padding: 5,
  },

  tableContainer: {
    marginTop: 8,
  },

  table: {
    marginTop: 4,
    marginBottom: 0,
  },

  row: {
    overflow: 'hidden',
    height: 40,
    maxHeight: 48,
    minHeight: 40,
    fontSize: 11,
  },

  colHeader: {
    padding: '2px 6px',
    whiteSpace: 'nowrap'
  },

  colHeaderStrong: {
    padding: '2px 6px',    
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },

  colTxId: {
    minWidth: 68,
    textAlign: 'center',
    padding: '2px 0px',
    cursor: 'pointer',
  },
  colTxIdSelected: {
    minWidth: 68,
    textAlign: 'center',
    padding: '2px 0px',
    cursor: 'pointer',
    backgroundColor: Colors.ORANGE,
  },

  colIconStatus: {
    minWidth: 80,
    textAlign: 'center',
    padding: '2px 3px',
  },

  colTextStatus: {
    minWidth: 80,
    textAlign: 'center',
    padding: '2px 3px',
    fontSize: 12,
  },

  colTime: {
    padding: '2px 3px',
    fontSize: 12,
  },

  colOrderId: {
    padding: '2px 3px',
    fontSize: 12,
  },

  colCustomer: {
    padding: '2px 3px',
    fontSize: 12,
  },

  colValue: {
    minWidth: 80,
    padding: '2px 12px',
    textAlign: 'right',
    fontWeight: 'bold',
  },

  colBenBank: {
    padding: '2px 3px',
    fontSize: 12,
    whiteSpace: 'nowrap'
  },

  colDestPhone: {
    padding: '2px 3px',
    fontSize: 12,
  },

  colContent: {
    minWidth: 111,
    maxWidth: 389,
    padding: '2px 6px 2px 6px',
    overflow: 'hidden',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    fontSize: 12,
  },

  colNo: {
    minWidth: 50,
    maxWidth: 64,
    textAlign: 'center',
    padding: '2px 0px',
  },

  colCode: {
    minWidth: 68,
    textAlign: 'center',
    padding: '2px 0px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },

  colName: {
    padding: '2px 6px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: 80,
    maxWidth: 256,
  },

  colQuantity: {
    minWidth: 68,
    textAlign: 'center',
  },

  colActions: {
    padding: '2px 5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  valueSmall: {
  },

  actionButton: {
    padding: '0px 8px',
  },  

  paging: {
    minHeight: 40
  }
}));
