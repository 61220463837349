import Roles from './Roles';
import TxStatus from './TxStatuses';
import ValUtils from './ValUtils';

var User = (function () {
  var userId = 0;
  var userKey = "";
  var phone = "";
  var email = "";
  var status = 8; // WAITING
  var sessionId = "";

  const device = ValUtils.getBrowser() + " (" + ValUtils.getOS() + ")";

  const host = window.location.hostname;

  // User ID
  var getUserId = function () {
    return userId;
  };

  var setUserId = function (id) {
    userId = id;
  };

  // Phone Number
  var getPhone = function () {
    return phone;
  };

  var getDisplayPhone = function () {
    if (phone.startsWith("84")) {
      return "0" + phone.substring(2);
    }

    return phone;
  };

  var getFormatedPhone = function () {
    if (phone.startsWith("0")) {
      return "84" + phone.substring(1);
    }

    return phone;
  };

  var setPhone = function (number) {
    phone = number;
  };

  var getEmail = function () {
    return email;
  }

  var setEmail = function (emailAddress) {
    email = emailAddress;
  }
  // Status: success, waiting, processing, error
  var getStatus = function () {
    return status;
  };

  var setStatus = function (userStatus) {
    status = parseInt(userStatus);
  };

  // User key
  var getUserKey = function () {
    return userKey;
  };

  var setUserKey = function (key) {
    userKey = key;
  };

  // Session ID
  var getSessionId = function () {
    return sessionId;
  };

  var setSessionId = function (ssId) {
    sessionId = ssId;
  };

  var isReady = function () {
    return status === TxStatus.SUCCESS;
  };


  // Get IDENTITY
  var getIdentity = function () {
    return {
      src: "web",
      node: host,
      version: "1.0",
      device: device,
      userId: userId,
      phone: phone,
      statusId: status,
      sessionId: sessionId,
    };
  };

  var clearIdentity = function () {
    userId = 0;
    userKey = "";
    // phone = ""; // keep phone for remember password
    status = 8;
    sessionId = "";
  };


  const hasActivePartner = (userObj) => {
    return (getActiveRole(userObj) !== Roles.NONE);
  }

  const getActiveRole = (userObj) => {

    if(!(userObj)) {
      return Roles.NONE;
    }

    if(userObj.partnerId === 0 || !(userObj.roles)) {
      return Roles.NONE;
    }
    
    let activeRole = userObj.roles.find((role) => role.partnerId === userObj.partnerId);
    if((activeRole) && (activeRole.statusId === TxStatus.SUCCESS)) {
      return activeRole;
    } else {
      return Roles.NONE;
    }
  }

  // Return declare functions
  return {
    getUserId: getUserId,
    setUserId: setUserId,
    getPhone: getPhone,
    getDisplayPhone: getDisplayPhone,
    getFormatedPhone: getFormatedPhone,
    setPhone: setPhone,
    getEmail: getEmail,
    setEmail: setEmail,
    getStatus: getStatus,
    setStatus: setStatus,
    getUserKey: getUserKey,
    setUserKey: setUserKey,
    getSessionId: getSessionId,
    setSessionId: setSessionId,
    isReady: isReady,
    hasActivePartner: hasActivePartner,
    getActiveRole: getActiveRole,
    getIdentity: getIdentity,
    clearIdentity: clearIdentity,
  };
})();

export default User;
