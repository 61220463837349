import { Hidden } from "@mui/material";
import { Box } from "@mui/system";
import Colors from "../../ckcore/core/Colors";
import { animated, useSpring } from '@react-spring/web'
import ck_logo_round from '../../ckcore/images/ck_lgrb_256.png'
import { ckTheme } from "../../ckcore/ui/CkStyles";

export default function IntroCkForShop(props) {

  const styles = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
  });

  // console.log("Breakpoint: " + ckTheme.breakpoints.width('md'));

  let wh = window.innerWidth > 640 ? 96 : 72;
  let spacing =  window.innerWidth > 640 ? 16 : 8;

  return (
    <animated.div style={styles}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 320 }}>

        <Box style={{ fontSize: 20, fontWeight: '600', color: Colors.DARK_BLUE, marginBottom: spacing }}>CHIA SẺ THANH TOÁN</Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }} style={{ marginBottom: spacing }}>

          <Box sx={{ width: '50%', p: 1 }}>
            <Box sx={{ border: 1, borderStyle: "dashed", borderColor: "green", borderRadius: 3 }} style={{ padding: 8, marginBottom: 8, minHeight: 150, textAlign: 'left' }}>
              <span><strong>GoogleSheets: </strong><i>Nhân viên không còn phải chụp điện thoại khách hàng mà vẫn biết tiền về.</i></span>
            </Box>
            <Box width={wh} height={wh} style={{ margin: 'auto', backgroundColor: Colors.DARK_BLUE, padding: 12, borderRadius: wh / 2 }}>
              <img width={wh - 24} height={wh - 24} src="https://lh3.ggpht.com/e3oZddUHSC6EcnxC80rl_6HbY94sM63dn6KrEXJ-C4GIUN-t1XM0uYA_WUwyhbIHmVMH=w300" alt="Google Sheets" />
            </Box>
          </Box>

          <Box sx={{ width: '50%', p: 1 }}>
            <Box sx={{ border: 1, borderStyle: "dashed", borderColor: "green", borderRadius: 3 }} style={{ padding: 8, marginBottom: 8, minHeight: 150, textAlign: 'left' }}>
              <span><strong>CK Dashboard: </strong><i>Báo cáo giao dịch tổng hợp từ mọi tài khoản ngân hàng.</i></span>
            </Box>
            <img width={wh} height={wh} src={ck_logo_round} alt="Chuyển Khoản Dashboard" />
          </Box>
        </Box>
        <Hidden smDown>
          <Box style={{ fontSize: 20, fontWeight: '600', color: Colors.DARK_BLUE }}>CHÍNH XÁC - BẢO MẬT</Box>
        </Hidden>
      </Box>
    </animated.div>
  );

}