const ServiceTypes = {
  
  TEST_CKQR           : 8, 
  EXTEND_SUBSCRIPTION : 9,
  TOPUP_CKCOIN        : 9,

  SALE_ORDER          : 11,
  PURCHASE_ORDER      : 12,
  PAYMENT_REQUEST     : 13,
  PURCHASE_REQUEST    : 14,

  CHECKOUT_DONATE     : 15
};

export default ServiceTypes;
