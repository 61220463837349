import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Slide from '@mui/material/Slide';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';

import { getBankSvgIcon, getDefaultBanks } from '../../ckcore/core/CkBank'
import { getBankInfo } from '../../ckcore/core/CkBank';
import { isValidAccountNumber, isValidNameUppercaseAscii, isValidTransferContent, isValidAmount } from '../../ckcore/core/Validator';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import TxStatus from '../../ckcore/core/TxStatuses';
import Config from '../../ckcore/core/Config';
import PrintA4 from './PrintA4';
import CKQRGen from '../../ckcore/core/CKQRGen';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function doPrint(partnerObj) {
  var accountNumber = partnerObj.accounts[0].accountNumber;
  var currentTitle = document.title;
  document.title = accountNumber + ".chuyenkhoan.com.pdf";
  window.print();
  document.title = currentTitle
  return false;
}

const MAX_ACCOUNTS = 3;

Config.setServerURL("https://dashboard.chuyenkhoan.com/dashboard?");

export default function PrintQRDialog(props) {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [editing, setEditing] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [partnerObj, setPartnerObj] = useState({
    partnerName: 'THÔNG TIN CHUYỂN KHOẢN',
    partnerContact: '',
    accounts: [
      {
        bankName: 'Vietcombank',
        accountNumber: '',
        accountName: '',
        accountBranch: ''
      }
    ],
    amount: '',
    message: '',
    showWifi: false,
    wifi: '',
    pass: '',
    updated: new Date().getTime(),
  });

  const handleAddBank = () => {
    var len = partnerObj.accounts.length;
    if (len < MAX_ACCOUNTS) {

      // set default next bank name
      var nextBankName = "";
      if (len === 1) {
        nextBankName = "Vietinbank";
      } else if (len === 2) {
        nextBankName = "BIDV";
      } else if (len === 3) {
        nextBankName = "Agribank";
      } else if (len === 4) {
        nextBankName = "Techcombank";
      }

      var accounts = partnerObj.accounts;

      // add more
      accounts.push({
        bankName: nextBankName,
        accountNumber: '',
        accountName: '',
        accountBranch: ''
      });

      setPartnerObj({ ...partnerObj, accounts: accounts });

    } else {
      setErrorMessage("Hệ thống chỉ hỗ trợ tối đa 5 tài khoản ngân hàng.");
    }
  }

  const handleRemoveBank = () => {
    var len = partnerObj.accounts.length;
    if (len > 1) {
      var accounts = partnerObj.accounts;
      accounts.splice(len - 1, 1);
      setPartnerObj({ ...partnerObj, accounts: accounts });
    }
  }

  const handleSelectBank = (index, name, value) => {

    var accounts = partnerObj.accounts;
    var bankObj = accounts[index];
    bankObj = { ...bankObj, [name]: value };
    accounts[index] = bankObj;

    setPartnerObj({ ...partnerObj, accounts: accounts });

    setLoading(false);
    setEditing(true);
  };

  const handleChangeBank = (index, name) => event => {

    var accounts = partnerObj.accounts;
    var bankObj = accounts[index];
    if (name === 'accountNumber') {
      bankObj = { ...bankObj, [name]: event.target.value.replace(/ /g, "").trim() };
    } else {
      bankObj = { ...bankObj, [name]: event.target.value };
    }
    accounts[index] = bankObj;

    setPartnerObj({ ...partnerObj, accounts: accounts });
    setLoading(false);
    setEditing(true);
  };

  const handleChange = (name) => event => {
    if (name === 'showWifi') {
      setPartnerObj({ ...partnerObj, [name]: event.target.checked });
    } else {
      setPartnerObj({ ...partnerObj, [name]: event.target.value });
    }
    setLoading(false);
    setEditing(true);
  };

  const handleCompleted = (value) => {
    setLoading(false);
    setEditing(false);

    if (value) {

      setErrorMessage("");

      var errorMsg = "";
      partnerObj.accounts.map((bankObj, index) => {
        console.log(JSON.stringify(bankObj));

        // get bank info
        const bankInfo = getBankInfo(Config.getAvailableBanks(), bankObj.bankName);
        if (!(bankInfo)) {
          errorMsg = "Tên ngân hàng không hợp lệ: " + bankObj.bankName;
          return false;
        }

        // validate account
        if (!isValidAccountNumber(bankObj.accountNumber, bankInfo.accountRegex)) {
          errorMsg = "Số tài khoản " + bankObj.bankName + " không hợp lệ: " + bankObj.accountNumber;
          return false;
        }

        // validate name (allow multiple line)
        if (!isValidNameUppercaseAscii(bankObj.accountName.replace(/\n/g, " "))) {
          errorMsg = "Tên tài khoản " + bankObj.bankName + " không hợp lệ (cần viết hoa không dấu)";
          return false;
        }
      });

      // validate content
      if ((partnerObj.message) && !isValidTransferContent(partnerObj.message)) {
        errorMsg = "Nội dung chuyển: " + partnerObj.message + " không hợp lệ (cần viết không dấu)";
      }

      // validate amount
      if ((partnerObj.amount) && !isValidAmount(partnerObj.amount)) {
        errorMsg = "Số tiền không hợp lệ: " + partnerObj.amount;
      }

      // do request CKQR
      if (editing && !(errorMsg)) {
        updateCQKR();
      } else {
        setErrorMessage(errorMsg);
      }
    } else {
      setCompleted(false);
      setEditing(true);
    }

    // set as required
    // setCompleted(value);
  };

  /**
   * Update CKQR for partner accounts
   */
   const updateCQKR = () => {

    var bankAccounts = partnerObj.accounts;
    for (var i = 0; i < bankAccounts.length; i++) {
      var bankAccountObj = bankAccounts[i];
      var bankObj = getBankInfo(getDefaultBanks(), bankAccountObj.bankName);
      var seftGenCKQR = CKQRGen.generateCKQR(bankObj.bankCodeNumber.toString(), bankAccountObj.accountNumber, partnerObj.amount, partnerObj.message);
      bankAccounts[i].ckqr = seftGenCKQR;
      // console.log("Gen CKQR: " + seftGenCKQR);
    }

    setPartnerObj({ ...partnerObj, accounts: bankAccounts });
    setCompleted(true);
  }


  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Load partner data
  const loadCKQRInfo = async () => {

    // refresh message
    const ckqr = {
      txType: 'load',
      partner_id: 10001,
      timestamp: new Date().getTime()
    };

    // Call server
    try {
      setLoading(true);
      const rawResponse = await fetch(Config.getServerURL() + "qr=" + JSON.stringify(ckqr));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
        // update available banks
        Config.setAvailableBanks(response.banks);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error... " + error);
    }
  };

  useEffect(() => {
    loadCKQRInfo();
  }, []);

  // Add bank dialog
  return (
    <Dialog fullWidth={false} maxWidth="xl" open={props.open} onClose={handleClose} TransitionComponent={Transition} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" >THIẾT KẾ CKQR 24/7 CHO CỬA HÀNG</DialogTitle>
      <DialogContent dividers style={{ background: '#FAFAFA' }}>
        {!completed &&
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Box id="DialogPrint" p={1} sx={{
                minHeight: '101mm',
                width: '196mm',
                padding: '12mm',
                margin: 'auto',
                marginTop: '6mm',
                marginBottom: '8px',
                border: '1px #D3D3D3 solid',
                borderRadius: '5px',
                background: 'white',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                alignItems: 'center'
              }}>
                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <Box p={1}>
                      {getMessageBox(errorMessage, "", "Mời bạn nhập thông tin tài khoản ngân hàng sau đó bấm Hoàn thành")}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField id="partner-name" size="large" variant="outlined" fullWidth
                      label="Thông tin chuyển khoản"
                      inputProps={{ min: 0, style: { textAlign: 'center', fontSize: 18 } }}
                      value={partnerObj.partnerName}
                      onChange={handleChange('partnerName')}
                    />

                  </Grid>
                  <Grid item xs={12}>
                    <TextField id="partner-address" size="small" variant="outlined" fullWidth
                      label="Địa chỉ, số điện thoại.."
                      inputProps={{ min: 0, style: { textAlign: 'center', fontSize: 14 } }}
                      value={partnerObj.partnerContact}
                      onChange={handleChange('partnerContact')}
                    />

                  </Grid>

                  {partnerObj.accounts.map((bankObj, index) => (
                    <>
                      <Grid key={"bank_name_" + index} item xs={4}>
                        <Autocomplete name="select-bank" size="small" disableClearable
                          options={Config.getAvailableBanks()}
                          getOptionLabel={(bank) => bank.name}
                          isOptionEqualToValue={(option, value) => option.name.toUpperCase() === bankObj.bankName.toUpperCase()}
                          value={{ name: bankObj.bankName }}
                          onChange={(event, bank) => handleSelectBank(index, 'bankName', (bank) ? bank.name : "")}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" margin="dense" fullWidth
                              label="Chọn ngân hàng"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton size="small" >
                                      <SvgIcon component={getBankSvgIcon(bankObj.bankName)} viewBox="0 0 48 48" />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }} />
                          )}
                          disabled={false}
                        />
                      </Grid>
                      <Grid key={"account_number_" + index} item xs={4}>
                        <TextField name="account-number" size="small" variant="outlined" fullWidth style={{ marginTop: 8 }}
                          label="Số tài khoản *"
                          type="tel"
                          value={bankObj.accountNumber.split(" ").join("")}
                          inputProps={{ min: 0, maxLength: 18, style: { textAlign: 'left', fontSize: 14, fontWeight: 'bold' } }}
                          onChange={handleChangeBank(index, 'accountNumber')}
                        />
                      </Grid>
                      <Grid key={"account_name_" + index} item xs={4}>
                        <TextField name="account-name" size="small" variant="outlined" fullWidth style={{ marginTop: 8 }}
                          label="TÊN TÀI KHOẢN *"
                          type="text"
                          value={bankObj.accountName}
                          multiline
                          maxRows={2}
                          inputProps={{ min: 0, style: { textAlign: 'left', textTransform: "uppercase", fontSize: 14 } }}
                          onChange={handleChangeBank(index, 'accountName')}
                        />
                      </Grid>

                    </>
                  ))}


                  <Grid item xs={4}>
                    <TextField id="order-info" size="small" variant="outlined" fullWidth
                      label="Nội dung (nếu có)"
                      type="text"
                      value={partnerObj.message}
                      inputProps={{ min: 0, style: { textAlign: 'left', fontSize: 14, } }}
                      onChange={handleChange('message')}
                    />
                  </Grid>
                  <Grid item xs={4}>

                    <TextField id="order-amount" size="small" variant="outlined" fullWidth
                      label="Số tiền (nếu có)"
                      type="text"
                      value={partnerObj.amount}
                      inputProps={{ min: 0, style: { textAlign: 'left', fontSize: 14, } }}
                      onChange={handleChange('amount')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {
                      partnerObj.accounts.length === 1 &&
                      <Button disabled={loading || completed} variant="text" onClick={() => handleAddBank()}>+ Thêm ngân hàng</Button>
                    }
                    {
                      partnerObj.accounts.length > 1 && partnerObj.accounts.length < MAX_ACCOUNTS + 1 &&
                      <Button disabled={loading || completed || partnerObj.accounts.length === MAX_ACCOUNTS} variant="text" onClick={() => handleAddBank()}>+ Thêm TK</Button>
                    }
                    {
                      partnerObj.accounts.length > 1 && partnerObj.accounts.length < MAX_ACCOUNTS + 1 &&
                      <Button disabled={loading || completed} variant="text" color="primary" onClick={() => handleRemoveBank()}>- Bớt TK</Button>
                    }
                  </Grid>
                  <Grid item xs={4}>

                    <FormControlLabel style={{ marginTop: 20 }}
                      control={
                        <Checkbox
                          checked={partnerObj.showWifi}
                          onChange={handleChange('showWifi')}
                          name="wifi"
                          color="primary"
                        />
                      }
                      label="Thêm thông tin WIFI"
                    />
                  </Grid>

                  <Grid item xs={4}>

                    <TextField id="order-info" size="small" variant="outlined" fullWidth style={{ marginTop: 20 }}
                      label="TÊN WIFI"
                      type="text"
                      inputProps={{ min: 0, style: { textAlign: 'left', fontSize: 14, } }}
                      value={partnerObj.wifi}
                      onChange={handleChange('wifi')}
                      disabled={!partnerObj.showWifi}
                    />
                  </Grid>

                  <Grid item xs={4}>

                    <TextField id="order-info" size="small" variant="outlined" fullWidth style={{ marginTop: 20 }}
                      label="MẬT KHẨU"
                      type="text"
                      value={partnerObj.pass}
                      inputProps={{ min: 0, style: { textAlign: 'left', fontSize: 14, } }}
                      onChange={handleChange('pass')}
                      disabled={!partnerObj.showWifi}
                    />
                  </Grid>

                </Grid>

              </Box>

            </Grid>

          </Grid>
        }
        {
          completed &&
          <PrintA4 partnerObj={partnerObj} />
        }

      </DialogContent>
      <DialogActions>
        <FormControlLabel
          style={{paddingTop: 8, paddingRight: 20}}
          control={
            <Checkbox
              checked={completed}
              onChange={e => handleCompleted(e.target.checked)}
              name="completed"
              color="primary"
            />
          }
          label="Hoàn thành"
        />
        <Button disabled={loading || !completed} color="primary" onClick={() => doPrint(partnerObj)}>
          IN ra Giấy/PDF
        </Button>
        <Button color="primary" onClick={() => props.closeCallback()}>
          Trở lại
        </Button>
      </DialogActions>
    </Dialog>
  );
}
