// import React, { useState } from 'react';
import { useEffect, useRef } from "react";

//***************************** Interval UTIL *******************************/
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// Get time offset string from log date
// second | minute | hour | day ago
function timeOffsetString(dateInSeconds, currentSeconds) {
  var dayOffset = Math.floor((currentSeconds - dateInSeconds) / 86400);
  if (dayOffset > 1111) {
    // error date
    return "";
  } else if (currentSeconds < dateInSeconds) {
    // error clock
    return "n giây trước";
  } else if (dayOffset > 30) {
    return Math.floor(dayOffset / 30) + " tháng trước";
  } else if (dayOffset >= 1) {
    return dayOffset + " ngày trước";
  } else {
    var hourOffset = Math.floor((currentSeconds - dateInSeconds) / 3600);
    if (hourOffset >= 1) {
      return hourOffset + " giờ trước";
    }

    var minuteOffset = Math.floor((currentSeconds - dateInSeconds) / 60);
    if (minuteOffset >= 1) {
      return minuteOffset + " phút trước";
    }

    var secondOffset = Math.floor(currentSeconds - dateInSeconds);
    return secondOffset + " giây trước";
  }
}

function timeOffsetStringEn(dateInSeconds, currentSeconds) {
  var dayOffset = Math.floor((currentSeconds - dateInSeconds) / 86400);
  if (dayOffset > 1111) {
    // error date
    return "";
  } else if (currentSeconds < dateInSeconds) {
    // error clock
    return "seconds ago";
  } else if (dayOffset > 30) {
    return Math.floor(dayOffset / 30) + (dayOffset < 60 ? " month" : " months") + " ago";
  } else if (dayOffset >= 1) {
    return dayOffset + (dayOffset == 1 ? " day" : " days") + " ago";
  } else {
    var hourOffset = Math.floor((currentSeconds - dateInSeconds) / 3600);
    if (hourOffset >= 1) {
      return hourOffset + (hourOffset == 1 ? " hour" : " hours") + " ago";
    }

    var minuteOffset = Math.floor((currentSeconds - dateInSeconds) / 60);
    if (minuteOffset >= 1) {
      return minuteOffset + (minuteOffset == 1 ? " minute" : " minutes") + " ago";
    }

    var secondOffset = Math.floor(currentSeconds - dateInSeconds);
    return secondOffset + " seconds ago";
  }
}


//******************************************************************************//
//*************************** VALIDATE DATA FUNCTIONS **************************//
//******************************************************************************//

// TODO: check peformance
function toVnAscii(text, textOnly) {
  if (!text) {
    return "";
  }

  var arr = text.split("");
  var chars = [];
  var len = arr.length;

  for (var i = 0; i < len; i++) {
    var c = arr[i];
    switch (c) {
      case "ý":
      case "ỹ":
      case "ỷ":
      case "ỵ":
      case "ỳ":
        chars.push("y");
        break;
      case "Ỳ":
      case "Ỷ":
      case "Ỹ":
      case "Ý":
      case "Ỵ":
        chars.push("Y");
        break;

      case "ợ":
      case "ỡ":
      case "ở":
      case "ờ":
      case "ớ":
      case "ộ":
      case "ỗ":
      case "ổ":
      case "ồ":
      case "ố":
      case "ỏ":
      case "ọ":
      case "ô":
      case "ơ":
      case "õ":
      case "ó":
      case "ò":
        chars.push("o");
        break;
      case "Ơ":
      case "Ô":
      case "Ò":
      case "Ỏ":
      case "Õ":
      case "Ó":
      case "Ọ":
      case "Ồ":
      case "Ổ":
      case "Ỗ":
      case "Ố":
      case "Ộ":
      case "Ờ":
      case "Ở":
      case "Ỡ":
      case "Ớ":
      case "Ợ":
        chars.push("O");
        break;

      case "ị":
      case "ỉ":
      case "ĩ":
      case "í":
      case "ì":
        chars.push("i");
        break;
      case "Ì":
      case "Ỉ":
      case "Ĩ":
      case "Í":
      case "Ị":
        chars.push("I");
        break;

      case "ặ":
      case "ẵ":
      case "ẳ":
      case "ắ":
      case "ậ":
      case "ẫ":
      case "ẩ":
      case "ầ":
      case "ấ":
      case "ả":
      case "ạ":
      case "ă":
      case "ằ":
      case "ã":
      case "â":
      case "á":
      case "à":
        chars.push("a");
        break;
      case "Ă":
      case "Â":
      case "À":
      case "Ả":
      case "Ã":
      case "Á":
      case "Ạ":
      case "Ằ":
      case "Ẳ":
      case "Ẵ":
      case "Ắ":
      case "Ặ":
      case "Ầ":
      case "Ẩ":
      case "Ẫ":
      case "Ấ":
      case "Ậ":
        chars.push("A");
        break;

      case "ự":
      case "ữ":
      case "ử":
      case "ừ":
      case "ứ":
      case "ủ":
      case "ụ":
      case "ư":
      case "ũ":
      case "ú":
      case "ù":
        chars.push("u");
        break;
      case "Ư":
      case "Ừ":
      case "Ử":
      case "Ữ":
      case "Ứ":
      case "Ự":
      case "Ù":
      case "Ủ":
      case "Ũ":
      case "Ú":
      case "Ụ":
        chars.push("U");
        break;

      case "ệ":
      case "ễ":
      case "ể":
      case "ề":
      case "ế":
      case "ẽ":
      case "ẻ":
      case "ẹ":
      case "ê":
      case "é":
      case "è":
        chars.push("e");
        break;
      case "Ê":
      case "È":
      case "Ẻ":
      case "Ẽ":
      case "É":
      case "Ẹ":
      case "Ề":
      case "Ể":
      case "Ễ":
      case "Ế":
      case "Ệ":
        chars.push("E");
        break;

      case "đ":
        chars.push("d");
        break;
      case "Đ":
      case "Ð":
        chars.push("D");
        // D02 = 272 D03 = 208
        break;
      case " ":
        chars.push(" ");
        break;

      default:
        if (textOnly) {
          if (
            (c >= "A" && c <= "Z") ||
            (c >= "a" && c <= "z") ||
            (c >= "0" && c <= "9")
          ) {
            chars.push(c);
          }
        } else if (c.charCodeAt(0) <= 127) {
          chars.push(c);
        }

        break;
    }
  }

  return chars.join("");
}

function getHostName(href) {
  var l = document.createElement("a");
  l.href = href;
  return l.hostname;
}

function copyTextToClipboard(text) {
  console.log("Start copy text: " + text);
  var textField = document.createElement("textarea");
  textField.innerText = text.toString();
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
}

//************** Cookie Functions *****************/
// create cookie
function createCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else expires = "";
  const encodedValue = encodeURI(value);
  document.cookie = name + "=" + encodedValue + expires + "; path=/; SameSite=Strict";
}

// read cookie
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) {
      return decodeURI(c.substring(nameEQ.length, c.length));
    }
  }
  return "";
}

// erase cookie
function eraseCookie(name) {
  createCookie(name, "", -1);
}

// export functions
export {
  useInterval,
  timeOffsetString,
  timeOffsetStringEn,
  toVnAscii,
  getHostName,
  copyTextToClipboard,
  createCookie,
  readCookie,
  eraseCookie,
};
