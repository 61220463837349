import vnLocations from '../assets/vietnam_locations.min.json';

/**
 * Get all Vietnam provinces
 */
export function getProvinces(){
  let provinces = [];

  provinces.push({pi: 0, pn: '---'});

  vnLocations.provinces.map((provinceObj) => {
    provinces.push({pi: provinceObj.pi, pn: provinceObj.pn});
  });

  return provinces;
};

export function getProvinceObj(provinceId){

  var pObj = vnLocations.provinces.find((provinceObj) => provinceId === provinceObj.pi);

  if(pObj) {
    return pObj;
  } else {
    return {};
  }
};

export function getProvinceName(provinceId){

  let pObj = vnLocations.provinces.find((provinceObj) => provinceId === provinceObj.pi);
  
  if(pObj) {
    return pObj.pn;
  }

  return '';
};

export function getDistricts(provinceId) {

  let districts = [];
  districts.push({di: 0, dn: '---'});

  let provinceObj = getProvinceObj(provinceId);
  if(!provinceObj.pi) {
    return districts;
  }

  provinceObj.districts.map((districtObj) => {
    districts.push({di: districtObj.di, dn: districtObj.dn});
  })

  return districts;
}

export function getDistrictObj(provinceId, districtId) {
  var dObj = {};
  let provinceObj = getProvinceObj(provinceId);
  if(!provinceObj.pi) {
    return dObj;
  }

  provinceObj.districts.map((districtObj) => {
    if(districtId === districtObj.di) {
      // dObj = {di: districtObj.di, dn: districtObj.dn};
      dObj = districtObj;
    }
  });

  return dObj;
}

export function getCommunes(provinceId, districtId) {
  let communes = [];
  communes.push({ci: 0, cn: '---'});

  let districtObj = getDistrictObj(provinceId, districtId);
  if(!districtObj.di) {
    return communes;
  }

  districtObj.communes.map((communeObj) => {
    communes.push({ci: communeObj.ci, cn: communeObj.cn});
  })
  return communes;
}

export function getCommnueObj(provinceId, districtId, commnueId) {
  
  let communes = getCommunes(provinceId, districtId);
  var cObj = {};

  communes.map((communeObj) => {
    if(commnueId === communeObj.ci) {
      cObj = communeObj;
    }
  });

  return cObj;
}
