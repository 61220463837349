//******************************************************************************//
//*************************** VALIDATE DATA FUNCTIONS **************************//
//******************************************************************************//

// Vietnamese chaset
const VN_CS = "AÀẢÃÁẠĂẰẲẴẮẶẢÂẦẨẪẤẬBCDĐEÈÉẺẼẸÊỀẾỂỄỆFGHIÌÍỈĨỊJKLMNOÒÓỎÕỌƠỜỚỞỠỢÔỒỐỔỖỘPQRSTUÙÚỦŨỤƯỪỨỬỮỰVWXYỲÝỶỸỴZaàảãáạăằẳẵắặảâầẩẫấậbcdđeèéẻẽẹêềếểễệfghiìíỉĩịjklmnoòóỏõọơờớởỡợôồốổỗộpqrstuùúủũụưừứửữựvwxyỳýỷỹỵz0123456789";

// Validate Vietnam phone
function isValidPhone(phone) {
  if (!phone) {
    return false;
  }

  // const value = phone.match(/^[\+]?(84|0)[0-9]{9}$/);
  const value = phone.match(/^[+]?(84|0)[0-9]{9}$/);

  if (value) {
    return true;
  }
  return false;
}

// Validate password
function isValidPassword(password) {

  if(!password) {
    return false;
  }

  // const value = password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/);
  const value = password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*.]{6,16}$/);

  if (value) {
    return true;
  }
  return false;
}

// Validate authorization code
function isValidAuthcode(authcode) {
  
  if ((authcode) && authcode.match(/^[0-9]{4,8}$/)) {
    return true;
  }

  return false;
}

// Validate id number
function isValidIdNumber(idNumber) {
  if (!idNumber) {
    return false;
  }

  const value = idNumber.match(/^([0-9]{9}|[0-9]{12})$/);

  if (value) {
    return true;
  }
  return false;
}

// Validate bank account
function isValidAccountNumber(accountNumber, accountRegex) {
  if(!accountNumber) {
    return false;
  }
  if(accountNumber.length < 4) {
    return false;
  }

  const regex = new RegExp('^' + accountRegex + '$');
  const value = accountNumber.match(regex);
  if(value) {
    return true;
  }
  return false;
}

/**
 * Validate Vietnamse TIN
 */
function isValidTin(tin) {
  if (!tin) {
    return false;
  }

  const value = tin.match(/^([0-9]{10})$/);

  if (value) {
    return true;
  }
  return false;
}

// Validate email
function isValidEmail(email) {
  if (!email) {
    return false;
  }

  // const value = email.match(/^[\+]?(84|0)[0-9]{9}$/);
  const value = email.match(/^[a-zA-Z0-9](.)+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/);

  if (value) {
    return true;
  }
  return false;
}

// validate name
function isValidName(name) {
  if (!name) {
    return false;
  }
  // const value = email.match(/^[\+]?(84|0)[0-9]{9}$/);
  const name_value = name.match(/^[a-zA-Z0-9\s]*$/);
  // return (name.length > 1);
  if (name_value) {
    return true;
  }
  return false;
}

// Validate name UTF-8
function isValidNameUTF8(name) {

  // check null
  if (!name) {
    return false;
  }

  // check length
  let length = name.length;
  if(length < 3 || length > 145) {
    return false;
  }

  // allow vietnamse chars and some special chars
  // Titles must not contain characters for decoration, such as ~ ! * $ ? _ ~ { } # < > | * ; ^ ¬ ¦
  let allowChars = VN_CS + " !@#$%&*()-_+[]|/:;,.?";

  // check chars
  for(var i=0; i<length; i++) {
    if(allowChars.indexOf(name.charAt(i)) < 0) {
      return false;
    }
  }

  return true;
}


// validate name
function isValidNameUppercaseAscii(nameUpAscii) {
  if (!nameUpAscii) {
    return false;
  }
  // const value = email.match(/^[\+]?(84|0)[0-9]{9}$/);
  const nameE_value = nameUpAscii.match(/^[A-Z0-9\s]*$/);
  // return (name.length > 1);
  if (nameE_value && nameUpAscii.length >= 2) {
    return true;
  }
  return false;
}

// Validate domain
function isValidDomain(domain) {
  if (!domain) {
    return false;
  }

  const value = domain.match(/^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/);

  if (value) {
    return true;
  }
  return false;
}

// validate address
function isValidAddress(address) {
  if (!address) {
    return false;
  }

  // check length
  let length = address.length;
  if(length < 3 || length > 80) {
    return false;
  }

  // allow vietnamse chars and some special chars
  let allowChars = VN_CS + " .-_,/";

  // check chars
  for(var i=0; i<length; i++) {
    if(allowChars.indexOf(address.charAt(i)) < 0) {
      return false;
    }
  }

  return true;
}

// validate date of birth
function isValidDateOfBirth(dateOfBirth) {
  if (!dateOfBirth) {
    return false;
  }

  return dateOfBirth.length > 6;
}

// validate customer code
function isValidCustomerCode(customerCode) {
  if (!customerCode) {
    return false;
  }

  return customerCode.length > 2;
}

// validate message
function isValidMessage(msg) {
  if (!msg) {
    return false;
  }

  return msg.length > 2;
}

// validate product code
function isProductCode(productCode) {
  if (!productCode) {
    return false;
  }

  return productCode.length > 3;
}

// validate product code
function isValidOrderId(orderId) {
  if (!orderId) {
    return false;
  }

  const value = orderId.match(/^([0-9a-zA-Z_.-]{3,19})$/);

  if (value) {
    return true;
  }
  return false;
}


function isValidUrl(urlInput) {

  if(!(urlInput)) {
    return false;
  }

  var expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  var regex = new RegExp(expression);

  if (urlInput.match(regex)) {
    return true;
  } else {
    return false;
  }
}

function isValidIpAddress(ipInput) {

  var expression = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  var regex = new RegExp(expression);

  if (ipInput.match(regex)) {
    return true;
  } else {
    return false;
  }
}

function isValidTransferContent(content) {
  if (!content) {
    return false;
  }
  const nameE_value = content.match(/^[A-Za-z0-9_+,.@\s]*$/);
  if (nameE_value && content.length >= 2) {
    return true;
  }
  return false;
}

function isValidAmount(amount) {
  const amountStr = amount.toString();
  if (!amountStr) {
    return false;
  }
  const nameE_value = amountStr.match(/^[0-9]*$/);
  if (nameE_value && amountStr.length >= 1) {
    return true;
  }
  return false;
}

function isValidRegex(regex) {
  
  var isValid = true;
  try {
      new RegExp(regex);
  } catch(e) {
      isValid = false;
  }

  return isValid;
}

export {
  isValidPhone,
  isValidPassword,
  isValidAuthcode,
  isValidTin,
  isValidIdNumber,
  isValidAccountNumber,
  isValidEmail,
  isValidName,
  isValidNameUTF8,
  isValidNameUppercaseAscii,
  isValidAddress,
  isValidDateOfBirth,
  isValidCustomerCode,
  isValidMessage,
  isProductCode,
  isValidOrderId,
  isValidUrl,
  isValidIpAddress,
  isValidDomain,
  isValidTransferContent,
  isValidAmount,
  isValidRegex,
};
