import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ckTheme } from '../../ckcore/ui/CkStyles';
import EasyRegisterTable from './EasyRegisterTable';
import Colors from '../../ckcore/core/Colors';
import { getSubscriptionPackageName } from '../../dashboard/subscription/PricingTable';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import { isValidDomain, isValidEmail, isValidNameUTF8, isValidPhone, isValidUrl } from '../../ckcore/core/Validator';
import TxTypes from '../../ckcore/core/TxTypes';
import TxStatus from '../../ckcore/core/TxStatuses';
import Config from '../../ckcore/core/Config';
import User from '../../ckcore/core/User';

export default function EasyRegisterDialog(props) {

  const [messages, setMessages] = useState({ success: '', error: '', info: 'Mời bạn nhập thông tin đăng ký', registered: false, editing: false });
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  
  /**
   * Register object
   */
  const [regObj, setRegObj] = useState({ name: '', phone: '', email: '', partner: '', provinceId: '', website: '', subscription: 'small' });
  const updateCallback = (updateObj) => {
    let subscription = regObj.subscription
    setRegObj({...updateObj, subscription: subscription});
  }

  /**
   * User select subscription package
   */
  const onChangeSubscription = (subscription) => {
    setRegObj({...regObj, subscription: subscription});
  }

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  /**
   * Check register phone
   */
  const validateRegister = async () => {

    // validate name
    if (!isValidNameUTF8(regObj.name)) {
      setMessages({ ...messages, error: "Họ tên không hợp lệ: " + regObj.name });
      return;
    }

    // validate phone
    if (!isValidPhone(regObj.phone)) {
      setMessages({ ...messages, error: "Số điện thoại không hợp lệ: " + regObj.phone });
      return;
    }

    // validate email
    if (!isValidEmail(regObj.email)) {
      setMessages({ ...messages, error: "Địa chỉ email không hợp lệ: " + regObj.email });
      return;
    }

    // validate partner name
    if (!isValidNameUTF8(regObj.partner)) {
      setMessages({ ...messages, error: "Tên tổ chức hợp lệ: " + regObj.partner });
      return;
    }

    // validate province ID
    if (regObj.provinceId < 0 || regObj.provinceId > 99) {
      setMessages({ ...messages, error: "Chọn tỉnh thành không đúng" });
      return;
    }

    // validate website
    if ((regObj.website) && !(isValidUrl(regObj.website) || isValidDomain(regObj.website))) {
      setMessages({ ...messages, error: "Website không hợp lệ: " + regObj.website });
      return;
    }

     // need verify recaptcha first
     if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setMessages({ ...messages, error: "Chưa kiểm tra được mã bảo vệ. Bạn vui lòng thử lại."});
      return;
    }

    setMessages({ ...messages, error: '', info: 'Đang kiểm tra số điện thoại..', registered: false });

    try {

      // set loading
      if (loading) { return; } else { setLoading(true); }

      // load check phone number
      var ckmsg = {
        txTypeId: TxTypes.TX_USER_CHECK_PHONE,
        phone: regObj.phone,
        timestamp: new Date().getTime(),
        identity: User.getIdentity()
      };

      // fetch response
      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.NOT_FOUND) {

        // ok, do register
        doRegister();

      } else {
        setMessages({ ...messages, error: 'Số điện thoại đã đăng ký!', registered: true, success: '' });
      }
    } catch (error) {
      console.log("Error create order .. " + error);
      setMessages({ ...messages, error: 'Lỗi kiểm tra số điện thoại, vui lòng thử lại sau', success: '' });
    }

    setLoading(false);

  };

  /**
   * Register user
   */
  const doRegister = async () => {
    try {

      setLoading(true);
      setMessages({ ...messages, error: '', success: '' });

      // get captcha
      const captcha = await executeRecaptcha('easyRegister');

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.TX_USER_REGISTER_EASY,
        registerObj: regObj,
        captcha: captcha,
        timestamp: new Date().getTime(),
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ckmsg)
      };

      // fetch response
      const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/dashboard/register?'), requestOptions);
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
        setMessages({ ...messages, error: '', success: 'Đăng ký tài khoản thành công!' });

        // auto goto dashboard
        setTimeout(() => {
          gotoDashboard();
        }, 11000);

      } else {
        console.log("ERROR register, msg: " + response.msg);
        setMessages({ ...messages, error: 'Đăng ký tài khoản bị lỗi: ' + response.msg, success: '' });
      }
    } catch (error) {
      console.log("Error easy register .. " + error);
      setMessages({ ...messages, error: 'Lỗi đăng ký tài khoản, vui lòng thử lại sau', success: '' });
    }

    setLoading(false);
  }

  /**
   * Open CK dashboard
   */
  const gotoDashboard = () => {
    if (Config.getServerURL().includes("dev.")) {
      window.location = 'https://dev.chuyenkhoan.com/#login/' + regObj.phone;
    } else {
      window.location = 'https://dashboard.chuyenkhoan.com/#login/' + regObj.phone;
    }
  }


  // pricing dialog
  return (
    <ThemeProvider theme={ckTheme}>
      <Dialog open={props.open} fullWidth={true} maxWidth="md" onClose={handleClose} aria-labelledby="pricing-dialog-title">

        <DialogTitle id="pricing-dialog-title">
          <Grid justifyContent="space-between" alignItems="flex-end" container>
            <Grid item>
              <span>Đăng ký dịch vụ Chuyenkhoan.com</span>
            </Grid>
            <Grid item>
              <span>
              </span>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>

          {getMessageBox(messages.error, messages.success, messages.info)}

          <EasyRegisterTable regObj={regObj} updateCallback={updateCallback} />

          {
            (messages.success !== '') &&
            <Box sx={{ p: 3, margin: 'auto', textAlign: 'center' }}>
              {getMessageBox('', '', 'Mật khẩu tạm thời đã được gửi về số điện thoại của bạn. Mời bạn đăng nhập nhập vào trang quản trị.')}
              <Button variant="outlined" color="success" style={{marginTop: 8}}
                onClick={() => gotoDashboard()}>ĐĂNG NHẬP VÀO CK DASHBOARD</Button>
            </Box>
          }

          {
            (messages.error !== '' && messages.registered) &&
            <Box sx={{ p: 3, margin: 'auto', textAlign: 'center' }}>
              <Button variant="outlined" color="secondary" style={{marginTop: 8}}
                onClick={() => gotoDashboard()}>ĐĂNG NHẬP VÀO CK DASHBOARD</Button>
            </Box>
          }




          <Typography component="div" variant="body1" style={{ marginTop: 12, padding: 3}}>
            Gói dịch vụ đăng ký: <strong style={{color: Colors.CK_VIOLET}}>{getSubscriptionPackageName(props.subscriptionPackage)}</strong> (dùng thử miễn phí trong 30 ngày, tạm dừng bất cứ lúc nào)
          </Typography>
          
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary">Trở lại</Button>
          <Button variant="outlined" color="secondary"
            disabled={(messages.success !== '')}
            onClick={() => validateRegister()}>Đăng ký ngay</Button>

        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );

}
