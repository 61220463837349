import React from 'react';

export default function SupportBanks(props) {
    // const classes = useStyle();
    return (
        <div id="support_banks" className="text-center">
      <div className="container" style={{padding: 20, paddingTop: 0}}>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h3 style={{fontSize: 26, color: "#37517e"}}>NGÂN HÀNG HỖ TRỢ</h3>
        </div>
        <div className="row">
          
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/vietcombank.png" alt="Vietcombank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/vietinbank.png" alt="VietinBank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/bidv.png" alt="BIDV" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/mbbank.png" alt="MB Bank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/vpbank.png" alt="VPBank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/sacombank.png" alt="Sacombank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
        </div>

        <div className="row">
          
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/msb.png" alt="MSB" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/vib.png" alt="VIB" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/tpbank.png" alt="TPBank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/namabank.png" alt="Nam A Bank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/seabank.png" alt="SeA Bank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/lienvietpostbank.png" alt="Lien Viet Post Bank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
        </div>

        <div className="row">
          
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/techcombank.png" alt="Techcombank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/banviet.png" alt="Ban Viet" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/acb.png" alt="ACB" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/pvcombank.png" alt="PVCom Bank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/baovietbank.png" alt="BaoViet Bank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/hdbank.png" alt="HDBank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
        </div>

        <div className="row">
          
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/nbc.png" alt="NBC" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/scb.png" alt="SCB" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/ocb.png" alt="OCB" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/kienlongbank.png" alt="KienLongBank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/shinhanbank.png" alt="ShinHanBank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <div className="border"><img src="/logos/pgbank.png" alt="PGBank" className="bankImage" style={{maxWidth: 160, maxHeight: 64}}/></div>
          </div>
        </div>
      </div>
    </div>
    );
}
